import Loading from "shared/components/Spin";
import { TEMPLATES } from "utils/constants";
import CollectionNeeds from "./CollectionNeeds";
import Comparison from "./Comparison";
import Contact from "./Contact";
import Contract from "./Contract";
import Default from "./Default";
import Fast from "./Fast";
import Quote from "./Quote";
import SelectProducts from "./SelectProducts";
import Upsells from "./Upsells";

const Templates = {
  Loading: Loading,
  [TEMPLATES.B2B.THEMES.B2B_SOLYON.CONTACT]: Contact,
  [TEMPLATES.B2B.THEMES.B2B_SOLYON.CONTRACT]: Contract,
  [TEMPLATES.B2B.THEMES.B2B_SOLYON.QUOTE]: Quote,
  [TEMPLATES.B2B.THEMES.B2B_SOLYON.FAST]: Fast,
  [TEMPLATES.B2B.THEMES.B2B_SOLYON.COLLECTION_NEEDS]: CollectionNeeds,
  [TEMPLATES.B2B.THEMES.B2B_SOLYON.SELECT_PRODUCTS]: SelectProducts,
  [TEMPLATES.B2B.THEMES.B2B_SOLYON.COMPARISON]: Comparison,
  [TEMPLATES.B2B.THEMES.B2B_SOLYON.UPSELLS]: Upsells,
  default: Default,
};
export default Templates;
