import PropTypes from "prop-types";
import { useMemo } from "react";

import Count from "./Count";
import Guarantee from "./Guarantee";
import Main from "./Main";
import Simple from "./Simple";
import Custom from "./Custom";
import Toggle from "./Toggle";

const MODES = {
  main: Main,
  simple: Simple,
  guarantee: Guarantee,
  count: Count,
  custom: Custom,
  toggle: Toggle,
};
const Card = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Card.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

Card.defaultProps = {
  type: "main",
};

export default Card;
