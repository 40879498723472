import { Col, notification, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { get } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { LOGS_STATUSES_KEYS, PROCESS_TYPES, SCREENS, THEMES_PALETTE, TRACER_TYPES } from "utils/constants";
import Status from "shared/components/Status";
import { COLORS } from "shared/style/colors";
import { formatTime, groupLogsByDate } from "utils/helpers/date";
import { TRACERS } from "utils/api/graphql/queries/tracers";
import omitDeep from "omit-deep-lodash";
import { useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import { MdAccessTime, MdFlag, MdFlashOn, MdOpenInNew, MdOutlineAssignmentTurnedIn, MdOutlineFolderSpecial } from "react-icons/md";
import { useSubscription } from "@apollo/client";

const Dashboard = () => {
  const { t } = useTranslation(NAME_SPACES.HOME);
  const navigate = useNavigate();
  const FILTER = t("FILTER", { returnObjects: true });
  const TRANSLATION = t("ACTIVITIES", { returnObjects: true });
  const activitiesRef = useRef([]);
  const [tracers, setTracers] = useState([]);
  const [userActivities, setUserActivities] = useState([]);
  const take = 10;

  const savedFilters = JSON.parse(localStorage.getItem("dashboardFilters")) || {
    process: undefined,
    insurancePlan: undefined,
    author: undefined,
  };
  const [fetchQuotes, { data: quoteCountData, loading: quoteCountLoading }] =
    useLazyQuery(graphql.queries.COUNT_QUOTES);

  const [fetchSubscriptions, { data: subscriptionsData, loading: subscriptionsCountLoading }] =
    useLazyQuery(graphql.queries.SUBSCRIPTIONS);

  const [fetchProjects, { data: projectsData, loading: projectsLoading }] =
    useLazyQuery(graphql.queries.PROJECTS);

  useEffect(() => {
    fetchData(savedFilters);
  }, []);

  const fetchData = (filters) => {
    const [startDate, endDate] = get(filters, "dateRange", []) || {};

    fetchQuotes({
      variables: {
        where: {
          AND: [
            ...(filters.process
              ? [{ project: { statuses: { status: { process: { id: filters.process } } } } }]
              : []),
            ...(filters.insurancePlan
              ? [{ project: { subscriptions: { insurancePlan: { id: filters.insurancePlan } } } }]
              : []),
            ...(filters.author ? [{ project: { author: { id: filters.author } } }] : []),
          ],
        },
        gt: { createdDate: startDate },
        lt: { createdDate: endDate },
      },
    });

    fetchSubscriptions({
      variables: {
        where: {
          AND: [
            { status: "SIGNED" },
            ...(filters.process
              ? [{ project: { statuses: { status: { process: { id: filters.process } } } } }]
              : []),
            ...(filters.insurancePlan
              ? [{ project: { subscriptions: { insurancePlan: { id: filters.insurancePlan } } } }]
              : []),
            ...(filters.author ? [{ project: { author: { id: filters.author } } }] : []),
          ],
        },
        gt: { signedDate: startDate },
        lt: { signedDate: endDate },
      },
    });

    fetchProjects({
      variables: {
        take: 10,
        skip: 0,
        where: { visible: true },
      },
    });
  };
  const variables = {
    take,
    raw: [
      `
      "action" = 'updateSubscription' AND "data" -> 'input' -> 'data' ->> 'status' = 'SIGNED' 
      OR  
      "action" = 'updateSubscription' AND "data" -> 'input' -> 'data' ->> 'status' = 'PENDING'
      OR  
      "action" = 'addQuote'
      OR  
      "action" = 'addQualification'
       OR
      "action" = 'signDocuments'
      `,
    ],
    isIn: {
      action: ['addQuote', 'addQualification', 'signDocuments', 'documentsSigned'],
    },
  };

  const { data: userActivitiesData, loading: userActivitiesLoading, fetchMore, refetch } = useQuery(
    graphql.queries.USER_ACTIVITY,
    {
      variables,
      onCompleted: (data) => {
        setUserActivities(data?.userActivities?.data || []);
      },
    }
  );

  useSubscription(graphql.subscriptions.USER_ACTIVITY_ADDED, {
    variables,
    onData: ({ data }) => {
      const newActivity = get(data, "data.userActivityAdded", null);
      if (newActivity) {
        refetch();
      }
    },
    onError: (error) => {
      console.error("Subscription error:", error);
    },
  });

  const loadMoreActivities = () => {
    const newSkip = userActivities.length;
    fetchMore({
      variables: {
        ...variables,
        skip: newSkip,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.userActivities?.data?.length) {
          notification.info({ message: t("NO_MORE_ACTIVITIES") });
          return previousResult;
        }
        const existingIds = new Set(previousResult.userActivities.data.map(item => item.id));
        const newActivities = fetchMoreResult.userActivities.data.filter(item => !existingIds.has(item.id));
        return {
          ...previousResult,
          userActivities: {
            ...previousResult.userActivities,
            data: [
              ...previousResult.userActivities.data,
              ...newActivities,
            ],
          },
        };
      },
    });
    setTimeout(() => {
      const lastItemRef = activitiesRef.current[activitiesRef.current.length - 1];
      lastItemRef?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 300);
  };


  const quoteCount = useMemo(() => quoteCountData?.countQuotes || 0, [quoteCountData]);
  const subscriptionCount = useMemo(
    () => get(subscriptionsData, "subscriptions.count") || 0,
    [subscriptionsData]
  );

  const groupedLogs = useMemo(() => {
    return userActivitiesData
      ? groupLogsByDate(userActivitiesData.userActivities.data)
      : {};
  }, [userActivitiesData]);


  useQuery(TRACERS, {
    variables: { where: { type: TRACER_TYPES.MANUAL } },
    onCompleted: ({ tracers: { data } }) => {
      setTracers(omitDeep(data, "__typename"));
    },
  });

  const onDetail = (project) => {

    if (!project || !project.id) {
      notification.warning({
        message: "Notification",
        description: t("NOTIFICATION_ACTIVITY"),
      });
      return;
    }

    const projectDetails = projectsData?.projects?.data.find(
      ({ id }) => id === project.id
    );

    if (!projectDetails) {
      notification.warning({
        message: "Notification",
        description: t("NOTIFICATION_ACTIVITY"),
      });
      return;
    }
    const status = projectDetails.statuses.find(
      ({ status }) => status.process.type === PROCESS_TYPES.B2B
    );

    const tracer = tracers.find(
      ({ flow }) => flow.process.id === get(status, "status.process.id")
    );

    navigate(
      `${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${project.id}`,
      {
        state: {
          process: get(tracer, "flow.process"),
          tracer,
        },
      }
    );
  };

  const findUserName = (log, type) => {
    const paths = {
      firstname: [
        'data.result.contact.user.firstname',
        'data.result.project.contact.user.firstname',
      ],
      lastname: [
        'data.result.contact.user.lastname',
        'data.result.project.contact.user.lastname',
      ],
    };

    for (const path of paths[type]) {
      const value = get(log, path, null);
      if (value) return value;
    }
    return '-';
  };

  return (
    <>
      <Header title={t("DASHBOARD")} />
      <Row gutter={[16, 16]} align={"bottom"}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Card
            type={"count"}
            icon={<MdOutlineFolderSpecial size={28} />}
            label={FILTER.NUMBER_OF_QUOTES}
            value={quoteCountLoading ? <Spin size="small" /> : quoteCount}
            onClick={() => navigate(SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path)}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Card
            type="count"
            icon={<MdOutlineAssignmentTurnedIn size={28} />}
            label={FILTER.NUMBER_OF_CONTRACTS}
            onClick={() => navigate(SCREENS.PRIVATE.BROKER.CONTRACTS.LIST.path)}
            value={
              subscriptionsCountLoading ? <Spin size="small" /> : subscriptionCount
            }
          />
        </Col>
      </Row>

      <div className="p-top-20" />

      <Row gutter={[16, 16]} align={"top"}>
        <Col xs={24} sm={24} md={24} lg={16}>
          <Card type={"simple"} head title={t("ACTIVITIES_NAME")}>
            <div className="activities__area"  >
              {userActivitiesLoading && !userActivities.length ? (
                <Spin size="small" />
              ) : (
                Object.entries(groupedLogs).map(([date, logs]) => (
                  <div key={date}>
                    <h4 className="grouped--date">{date}</h4>
                    {logs.map((log, index) => {
                      const clientFirstname = findUserName(log, 'firstname');
                      const clientLastname = findUserName(log, 'lastname');

                      const authorFirstname = get(log, 'user.firstname', clientFirstname);
                      const authorLastname = get(log, 'user.lastname', clientLastname);

                      const actionLabel = get(log, LOGS_STATUSES_KEYS[log.action]) || log.action;
                      const translatedActionLabel =
                        TRANSLATION?.[actionLabel] || actionLabel;

                      return (
                        <div className="log--item" key={`${log.id}-${index}`} ref={(el) => (activitiesRef.current[index] = el)}>
                          <div className="log--time w-8">
                            <MdAccessTime size={16} />
                            <span>{formatTime(log.createdDate)}</span>
                          </div>
                          <div className="w-25">
                            <Status variant="black" type="simple" text={translatedActionLabel} />
                          </div>
                          <div className="log--user w-25">
                            <span>
                              {clientFirstname} {clientLastname}
                            </span>
                          </div>
                          <span className={`log--type w-25`}>
                            <span className={`icon  ${log.critical ? "true" : "false"}`}>
                              {log.critical ? (
                                <MdFlashOn size={12} color={COLORS.C_RED} />
                              ) : (
                                <MdFlag size={12} color={COLORS.C_PRIMARY} />
                              )}
                            </span>
                            {log.critical ? (
                              <MdFlashOn size={12} color={COLORS.C_RED} />
                            ) : (
                              <span> {authorFirstname} {authorLastname}</span>
                            )}

                          </span>
                          <span onClick={() => onDetail(get(log, 'data.result.project'))}>
                            <MdOpenInNew size={20} color={COLORS.C_PRIMARY} />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                ))
              )}


            </div>

            {!userActivitiesLoading &&
              userActivitiesData?.userActivities?.data?.length >= take && (
                <div className="d-flex align--center justify--center">
                  <Button
                    onClick={loadMoreActivities}
                    disabled={userActivitiesLoading}
                    type="primary--underline"
                  >
                    {userActivitiesLoading ? <Spin size="small" /> : t("SHOW_MORE")}
                  </Button>
                </div>
              )}

          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Card type={"simple"} head title={t("LATEST_PROJECTS")}>
            <div className="latset__projects">
              {projectsLoading ? (
                <Spin size="small" />
              ) : (
                projectsData?.projects.data.map((project) => (
                  <div className="latset__projects--item" key={project.id}>
                    <div className="info">
                      {/* <Avatar mode={"text"} size={"medium"} value={`${get(project.contact, "user.firstname", "")?.substring(
                        0,
                        1
                      )}${get(project.contact, "user.lastname", "")?.substring(0, 1)}`} /> */}
                      <div className="content">
                        <div className="name">
                          <span>{project.contact?.user?.firstname} {project.contact?.user?.lastname}</span>
                          <Status
                            status="primary"
                            text={get(
                              get(THEMES_PALETTE, project?.fields.processTemplate, "DEFAULT"),
                              "NAME"
                            )}
                          />
                        </div>
                        <small>{project.statuses?.[0]?.status?.name}</small>
                      </div>
                    </div>

                    <span onClick={() => onDetail(project)}>
                      <MdOpenInNew size={20} color={COLORS.C_PRIMARY} />
                    </span>
                  </div>
                ))
              )}
            </div>
            <div className="d-flex align--center justify--center">
              <Button
                onClick={() => navigate(SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path)}
                type={"primary--underline"}
              >
                {t("SHOW_ALL")}  <MdOpenInNew />
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
