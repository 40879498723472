import cuid from "cuid";
import { Formik } from "formik";
import { get, sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import { BsPatchCheck } from "react-icons/bs";
import { MdOutlineWidgets } from "react-icons/md";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({
  onSubmit,
  devices,
  setDevices,
  brands,
  cancel,
  loading,
  data,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BRAND);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Formik
      initialValues={{
        ...data,
        models: get(data, "models", []).map(({ id, name, image }) => ({
          value: name,
          image,
          id,
        })),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, setFieldValue, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          actions={[
            {
              text: CREATE.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: CREATE.HEADER.SAVE,
              onClick: handleSubmit,
              disabled: !formProps.dirty,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--1">
            <Select
              loading={loading}
              showSearch
              options={sortBy(
                brands.map(({ brand_name }) => ({
                  value: brand_name,
                  label: brand_name,
                })),
                "value"
              )}
              label={FORM.BRAND}
              placeholder={FORM.SELECT_BRAND}
              icon={<BsPatchCheck size={16} />}
              required
              {...bindInputProps({
                name: "name",
                ...formProps,
              })}
              onChange={(value) => {
                setFieldValue("name", value);
                setFieldValue("models", []);

                const devices = get(
                  brands?.find(({ brand_name }) => brand_name === value),
                  "device_list",
                  []
                ).map(({ device_name, device_image }) => ({
                  label: (
                    <div className="d-flex ">
                      <img
                        width={16}
                        height={16}
                        src={device_image}
                        alt={device_name}
                        style={{ marginRight: 8 }}
                      />
                      {device_name}
                    </div>
                  ),
                  value: device_name,
                  image: device_image,
                }));
                setDevices(devices);
              }}
            />
            <Select
              showSearch
              mode="multiple"
              optionFilterProp="value"
              options={devices}
              label={FORM.MODELS}
              placeholder={FORM.SELECT_MODELS}
              icon={<MdOutlineWidgets size={16} />}
              required
              {...bindInputProps({
                name: "models",
                ...formProps,
              })}
              onChange={(selectedValues) => {
                const selectedItems = selectedValues.map((value) => {
                  const selectedDevice = devices.find(
                    (device) => device.value === value
                  );
                  return {
                    value: selectedDevice?.value,
                    image: selectedDevice?.image,
                    id: selectedDevice?.id || cuid(),
                  };
                });

                setFieldValue("models", selectedItems);
              }}
            />
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Create;
