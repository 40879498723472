import { Image } from "antd";
import PropTypes from "prop-types";
import { MdAdd, MdRemove } from "react-icons/md";
import Select from "shared/components/Select";
import { COLORS } from "shared/style/colors";
import { COMMISSION_TYPES } from "utils/constants";
import { costToFrench } from "utils/helpers/values";

const STATE = {
  default: "default",
  disabled: "disabled",
  added: "added",
  compare: "compare",
};

const Component = ({
  image,
  name,
  description,
  costPerMonth,
  costAll,
  state,
  onClick,
  translation,
  commissions,
  commission,
  onChangeCommission,
  showCommission = true,
  hideActions,
}) => {
  const Actions = {
    [STATE.default]: (
      <div className="right--wrapper">
        {showCommission && (
          <Select
            value={commission}
            options={commissions?.map(({ type, value, withholding, id }) => ({
              label:
                type === COMMISSION_TYPES.LINEAR
                  ? `${value}/${value}`
                  : `${value}/${withholding}`,
              value: id,
            }))}
            onChange={(value) => onChangeCommission(value)}
          />
        )}
        <div className="action--wrapper">
          <button className="action" onClick={onClick}>
            <MdAdd className="add" size={24} color={COLORS.C_WHITE} />
          </button>
          {/* <div className="warranty">{translation.WARRANTY}</div> */}
        </div>
      </div>
    ),
    [STATE.disabled]: (
      <div className="right--wrapper">
        {showCommission && (
          <Select
            value={commission}
            options={commissions?.map(({ type, value, withholding, id }) => ({
              label:
                type === COMMISSION_TYPES.LINEAR
                  ? `${value}/${value}`
                  : `${value}/${withholding}`,
              value: id,
            }))}
            disabled
          />
        )}
        <div className="action--wrapper">
          <button className="action" onClick={onClick}>
            <MdAdd className="add" size={24} color={COLORS.C_WHITE} />
          </button>
          {/* <div className="warranty">{translation.WARRANTY}</div> */}
        </div>
      </div>
    ),
    [STATE.added]: (
      <div className="action--wrapper">
        <button className="action" onClick={onClick}>
          <MdRemove className="remove" size={24} color={COLORS.C_WHITE} />
        </button>
      </div>
    ),
    [STATE.compare]: <></>,
  };

  const CostAll = {
    [STATE.default]: (
      <span className="text-sm">
        <span className="cost-all">{costToFrench(costAll)} </span>
        {translation.PER_YEAR}
      </span>
    ),
    [STATE.disabled]: (
      <span className="text-sm">
        <span className="cost-all">{costToFrench(costAll)} </span>
        {translation.PER_YEAR}
      </span>
    ),
    [STATE.added]: (
      <span className="text-sm">
        <span className="cost-all">{costToFrench(costAll)} </span>
        {translation.PER_YEAR}
      </span>
    ),
    [STATE.compare]: <></>,
  };

  return (
    <div className={`card-product--container ${state}`}>
      <div className="card-product--wrapper">
        <div className="product-info">
          <div className="product-info__img">
            <Image src={image} preview={{ visible: false }} />
          </div>
          <div className="description--wrapper">
            <div className="text--wrapper">
              <h3 className="text-md">{name}</h3>
              <span className="text-sm">{description}</span>
            </div>
            <div className="price--wrapper">
              {costPerMonth && (
                <span className="text-sm">
                  <span className="cost-per-month">{costPerMonth}</span>
                  {translation.PER_MONTH}
                </span>
              )}
              {costAll && CostAll[state]}
            </div>
          </div>
        </div>
        {!hideActions && Actions[state]}
      </div>
    </div>
  );
};

Component.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  costPerMonth: PropTypes.node,
  costAll: PropTypes.string,
  state: PropTypes.oneOf(Object.keys(STATE)),
  onClick: PropTypes.func,
};

Component.defaultProps = {
  image: "",
  name: "",
  description: "",
  costPerMonth: "",
  costAll: "",
  state: "default",
  onClick: () => {},
};

export default Component;
