import { useLazyQuery } from "@apollo/client";
import cuid from "cuid";
import { Formik } from "formik";
import { defaultsDeep, filter, find, get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowForwardIos } from "react-icons/md";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import Loading from "shared/components/Spin";
import { useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { FRANCE_ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import {
  CONTACT_POINT_SYSTEM,
  RELATIONSHIP,
  SOCIO_PROFESSIONAL_CATEGORIES,
} from "utils/constants";
import { getMaxDeltaEnd, getMinDeltaStart } from "utils/helpers/array";
import { convertToTouched } from "utils/helpers/input";
import ExpirationModal from "./Widgets/ExpirationModal";
import Steps from "./Widgets/Steps";
import validation from "./validation";

const STEPS_KEYS = {
  CONTRACT: "CONTRACT",
  ADHERENT: "ADHERENT",
  SPOUSE: "SPOUSE",
  CHILDREN: "CHILDREN",
};

const Contact = ({ onNext, project }) => {
  const [deltaRia, setDeltaRia] = useState(30);
  const [deltaStart, setDeltaStart] = useState(1);
  const [deltaEndRia, setDeltaEndRia] = useState();
  const [deltaEnd, setDeltaEnd] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialTouched, setInitialTouched] = useState(true);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [city, setCity] = useState({
    id: cuid(),
    system: CONTACT_POINT_SYSTEM.CITY,
    value: null,
  });
  const [addressZipCodeCity] = useLazyQuery(FRANCE_ADDRESS_ZIP_CODE_CITY);
  useQuery(INSURANCE_PLANS, {
    variables: { where: {} },
    onCompleted: ({ insurancePlans: { data: insurancePlans } }) => {
      const deltaRia = getMinDeltaStart({ insurancePlans, minValue: 30 });
      const deltaStart = getMinDeltaStart({
        insurancePlans,
        minValue: 1,
        key: "DELTA_START",
      });
      const deltaEndRia = getMaxDeltaEnd({ insurancePlans }) || 0;
      const deltaEnd =
        getMaxDeltaEnd({
          insurancePlans,
          key: "DELTA_END",
        }) || 0;
      setDeltaRia(deltaRia);
      setDeltaStart(deltaStart);
      setDeltaEndRia(deltaEndRia);
      setDeltaEnd(deltaEnd);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (get(project, "expired")) setOpen(true);
  }, []);

  const spouse = find(get(project, "contact.relatedPersons", []), {
    relationship: RELATIONSHIP.SPOUSE,
  });
  const initialValues = useMemo(() => {
    const initials = defaultsDeep(
      {
        contract: { ...project.contract },
        contact: { ...project.contact },
        children: filter(get(project, "contact.relatedPersons", []), {
          relationship: RELATIONSHIP.CHILD,
        }),
        spouse,
        zipCode: find(get(project, "contact.telecoms", []), {
          system: CONTACT_POINT_SYSTEM.ZIP_CODE,
        }),
        phoneNumber: find(get(project, "contact.telecoms", []), {
          system: CONTACT_POINT_SYSTEM.PHONE,
        }),
        ria: project.ria,
        locked: project.locked,
        expired: project.locked,
      },
      {
        projectType: null,
        ria: false,
        locked: false,
        hasSpouse: !!spouse,
        children: [],
        spouse: {
          id: cuid(),
          relationship: RELATIONSHIP.SPOUSE,
          identity: {
            id: cuid(),
            socialRegime: null,
            user: { id: cuid(), birthDate: null },
          },
        },
        zipCode: {
          id: cuid(),
          system: CONTACT_POINT_SYSTEM.ZIP_CODE,
          value: null,
        },
        phoneNumber: {
          id: cuid(),
          system: CONTACT_POINT_SYSTEM.PHONE,
          value: null,
        },
        contract: { id: cuid(), issuanceDate: null },
        contact: {
          id: cuid(),
          socialRegime: null,
          politicallyExposedPerson: false,
          exerciseFrame: null,
          profession: null,
          socioProfessionalCategory: SOCIO_PROFESSIONAL_CATEGORIES.OTHER,
          user: {
            id: cuid(),
            gender: null,
            firstname: null,
            lastname: null,
            birthDate: null,
          },
        },
      }
    );
    setInitialTouched(initials);
    return initials;
  }, []);
  const onSubmit = (record) => {
    const {
      hasSpouse,
      spouse,
      children,
      zipCode,
      phoneNumber,
      contact,
      locked,
      ...rest
    } = record;
    const deltaS = rest.ria ? deltaRia : deltaStart;
    const deltaE = rest.ria ? deltaEndRia : deltaEnd;
    onNext({
      payload: {
        ...rest,
        contact: {
          ...contact,
          user: contact.user,
          relatedPersons: [...(hasSpouse ? [spouse] : []), ...children],
          telecoms: [
            ...get(contact, "telecoms", []).filter(
              ({ system }) => system !== CONTACT_POINT_SYSTEM.ZIP_CODE
            ),
            zipCode,
            phoneNumber,
            city,
          ],
        },
        expired: false,
        visible: true,
        fields: {
          ...project.fields,
          deltaStart: deltaS,
          deltaEnd: deltaE,
          info: { fonctionnaryOrAgent: true },
        },
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <>
      <Header key={"header"} title={DETAIL.TEMPLATES.CONTACT.TITLE} />
      <Formik
        validateOnMount={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
          {
            addressZipCodeCity,
            setCity,
            deltaRia,
            deltaStart,
            deltaEnd,
            deltaEndRia,
          }
        )}
      >
        {({ isValid, handleSubmit, values, ...formProps }) => [
          <ExpirationModal
            open={open}
            setOpen={setOpen}
            values={values}
            deltaRia={deltaRia}
            deltaStart={deltaStart}
            formProps={formProps}
          />,
          <div className="contact-form" key="form_area">
            <div className="contact-form__content">
              <Form
                title={DETAIL.TEMPLATES.CONTACT.TITLE}
                description={DETAIL.TEMPLATES.CONTACT.DESCRIPTION}
              >
                {Object.keys(STEPS_KEYS).map((step, index) => {
                  return Steps({
                    index: index + 1,
                    values,
                    translation: DETAIL,
                    deltaRia,
                    deltaStart,
                    deltaEnd,
                    deltaEndRia,
                    ...formProps,
                  })[step];
                })}
              </Form>
            </div>
            <div className="contact-form__action">
              <button
                className="contact-form__action--submit"
                onClick={async () => {
                  const touchedFields = convertToTouched(formProps.errors);
                  await formProps.setTouched(touchedFields, true);
                  if (isValid) handleSubmit();
                }}
                type="submit"
              >
                {DETAIL.TEMPLATES.CONTACT.SUBMIT}
                <MdArrowForwardIos size={20} />
              </button>
            </div>
          </div>,
        ]}
      </Formik>
    </>
  );
};

export default Contact;
