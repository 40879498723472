import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { GENERATE_SUBSCRIPTION_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import {
  DOCUMENTS_TYPES,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
} from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import Loading from "../Components/Spin";
import View from "./View";

const Contract = ({ project, onBack, onNext, updateUrl }) => {
  const [loading, setLoading] = useState(true);
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const [generateSubscriptionDocuments] = useMutation(
    GENERATE_SUBSCRIPTION_DOCUMENTS
  );
  const subscriptionId = get(project, "fields.currentSubscription");

  const [
    getAttachments,
    { data: attachmentsList, loading: loadingAttachments },
  ] = useLazyQuery(graphql.queries.ATTACHMENTS);
  const { data } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
    onCompleted: ({ subscription }) => {
      getAttachments({
        fetchPolicy: "no-cache",
        variables: {
          isIn: {
            type: [
              ...Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES),
              DOCUMENTS_TYPES.QUOTE,
              DOCUMENTS_TYPES.DUTY_OF_ADVICE_QUOTE,
            ],
          },
          where: {
            OR: [
              {
                AND: [
                  {
                    project: {
                      id: project.id,
                    },
                  },
                  {
                    insurancePlans: {
                      id: get(subscription, "insurancePlan.id", ""),
                    },
                  },
                ],
              },
              {
                AND: [
                  {
                    project: null,
                  },
                  {
                    insurancePlans: {
                      id: get(subscription, "insurancePlan.id", ""),
                    },
                  },
                ],
              },
            ],
          },
        },
      });
      setLoading(false);
    },
  });
  const subscription = omitDeep(data, "__typename");

  const source = localStorage.getItem("source");
  const next = (values) => {
    if (!get(subscription, "subscription.locked"))
      generateSubscriptionDocuments({
        variables: {
          data: { id: subscriptionId },
        },
      });
    updateUrl({
      subscriptionId,
      url: get(
        subscription,
        "subscription.insurancePlan.config.generatedUrl",
        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
      ),
    });
    onNext({
      payload: values,
    });
  };

  const download = async (id) => {
    setLoading(true);
    await downloadSingleDocument({
      variables: { where: { id } },
      onCompleted: (_) => {
        const { document } = omitDeep(_.downloadSingleDocument, "__typename");
        downloadDocument(document);
        setLoading(false);
      },
    });
  };

  const { data: generalCost } = useQuery(graphql.queries.GENERAL_COST, {
    variables: {
      where: {
        project: { id: get(project, "id") },
        insurancePlan: {
          id: get(subscription, "subscription.insurancePlan.id"),
        },
      },
    },
    onCompleted: () => setLoading(false),
  });

  if (loading || loadingAttachments) return <Loading />;

  return (
    <View
      download={download}
      onSubmit={next}
      subscription={get(subscription, "subscription")}
      onBack={onBack}
      attachments={get(attachmentsList, "attachments.data", [])}
      generalCost={get(generalCost, "generalCost")}
      initialValues={{ fields: project.fields }}
    />
  );
};

export default Contract;
