import { Table } from "antd";
import {
  chain,
  find,
  flatten,
  flattenDeep,
  get,
  isEmpty,
  sortBy,
  uniqBy,
} from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoDuplicateOutline } from "react-icons/io5";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
} from "react-icons/md";
import AlternativeMedicine from "shared/assets/images/ayurveda.png";
import Dental from "shared/assets/images/dental-checkup.png";
import Equipment from "shared/assets/images/hearing-aid.png";
import Hospitalization from "shared/assets/images/hospital-bed.png";
import Maternity from "shared/assets/images/maternity.png";
import MedicalCare from "shared/assets/images/medical-team.png";
import Others from "shared/assets/images/others.png";
import Prevention from "shared/assets/images/prevention.png";
import Optical from "shared/assets/images/vision.png";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Loading from "shared/components/Spin";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_CATEGORIES } from "utils/constants";
import CoverageBenefitCard from "./Widgets/Coverages/Card";
import CoverageCategoryHeader from "./Widgets/Coverages/Header";
import CoverageLabel from "./Widgets/Coverages/Label";
import DownloadOrSend from "./Widgets/DownloadOrSend";
import InsurancePlanCard from "./Widgets/InsurancePlans";

export const COVERAGE_CATEGORIES_ICONS = {
  [COVERAGE_CATEGORIES.HOSPITALIZATION]: Hospitalization,
  [COVERAGE_CATEGORIES.MEDICAL_CARE]: MedicalCare,
  [COVERAGE_CATEGORIES.OPTICAL]: Optical,
  [COVERAGE_CATEGORIES.DENTAL]: Dental,
  [COVERAGE_CATEGORIES.EQUIPMENT]: Equipment,
  [COVERAGE_CATEGORIES.ALTERNATIVE_MEDICINE]: AlternativeMedicine,
  [COVERAGE_CATEGORIES.PREVENTION]: Prevention,
  [COVERAGE_CATEGORIES.MATERNITY]: Maternity,
  [COVERAGE_CATEGORIES.OTHER]: Others,
};

const getCoverageBenefitById = (insurancePlanCoverages, coverageBenefitId) => {
  const coverageBenefits = flattenDeep(
    insurancePlanCoverages.map(({ coverageMappings, coverageCategory }) => {
      return [
        coverageCategory,
        ...coverageMappings.map(
          ({ coverage, limits, supportingInformation }) => ({
            ...coverage,
            limits,
            supportingInformation,
          })
        ),
      ];
    })
  );
  const coverageBenefit = find(coverageBenefits, { id: coverageBenefitId });
  return coverageBenefit;
};

const View = ({
  project,
  onSubscribe,
  onDownload,
  onSendQuote,
  data,
  onBack,
  loading,
  openModal,
  setOpenModal,
  duplicateProject,
  hideDetail,
  setHideDetail,
}) => {
  const { locked, fields, subscriptions, contract } = project;
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const [selectedModules, setSelectedModules] = useState(
    project?.fields?.upsells || []
  );
  const DETAIL = t("DETAIL", { returnObjects: true });
  const toggleCoverage = (name, hide) => {
    setHideDetail((prev) => ({
      ...prev,
      [name]: hide,
    }));
  };

  const HIDE_DETAIL = {
    [false]: (
      <>
        <span>{DETAIL.TEMPLATES.COMPARISON.SEE_MORE}</span>
        <MdOutlineExpandMore />
      </>
    ),
    [true]: (
      <>
        <span>{DETAIL.TEMPLATES.COMPARISON.SEE_LESS}</span>
        <MdOutlineExpandLess />
      </>
    ),
  };

  const dataSource = useMemo(() => {
    if (isEmpty(data)) return;
    const groupedCoverageBenefits = chain(data)
      .flatMap((cost) => get(cost, "insurancePlan.insurancePlanCoverages"))
      .value();

    const mergedCoverages = uniqBy(
      groupedCoverageBenefits,
      (coverage) => coverage.coverageCategory.value
    );

    const coveragesByCategory = mergedCoverages.map(
      ({ coverageMappings, ...coverage }) => {
        const matchingCoverages = groupedCoverageBenefits.filter(
          (item) =>
            item.coverageCategory.value === coverage.coverageCategory.value
        );

        return {
          ...coverage,
          coverages: uniqBy(
            flatten(matchingCoverages.map((c) => c.coverageMappings)),
            "coverage.id"
          ),
        };
      }
    );
    const res = sortBy(coveragesByCategory, "coverageCategory.order").flatMap(
      ({ coverageCategory, coverages }) => {
        const categoryBenefits = [
          coverageCategory,
          ...uniqBy(
            sortBy(
              coverages.flatMap(({ coverage }) => ({
                ...coverage,
                rowId: coverageCategory.value + coverage.id,
              })),
              "order"
            ),
            "id"
          ),
        ];
        const uniqueBenefits = categoryBenefits.reduce((acc, benefit) => {
          if (!benefit.type) acc.push(benefit);
          const existing = acc.find((item) => item.type === benefit.type);
          if (!existing) {
            acc.push(benefit);
          }
          return acc;
        }, []);
        return uniqueBenefits;
      }
    );

    return res;
  }, [loading]);
  const columns = [
    {
      width: 300,
      dataIndex: "__typename",
      render: (record, row) => {
        const isCoverageHeader = record === "CoverageCategory";
        return isCoverageHeader ? (
          <CoverageCategoryHeader
            className="coverage-header"
            src={get(row, "icon")}
            type={row.label}
            label={row.label}
          />
        ) : (
          <CoverageLabel
            row={row}
            label={row.type}
            subLabel={get(row, "subtype", "")}
            info={get(row, "additionalInfo", "")}
            expanded={hideDetail[row.id]}
            link={HIDE_DETAIL[get(hideDetail, row.id, false)]}
            onToggle={() => toggleCoverage(row.id, !hideDetail[row.id])}
          />
        );
      },
      // fixed: true,
    },
    ...(data?.map(({ insurancePlan, cost, currency }) => ({
      dataIndex: "type",
      title: () => (
        <InsurancePlanCard
          insurancePlan={insurancePlan}
          subscriptions={subscriptions}
          issuanceDate={get(contract, "issuanceDate")}
          isRIA={get(project, "ria", false)}
          cost={cost}
          currency={currency}
          translation={DETAIL.TEMPLATES.COMPARISON}
          onSubscribe={onSubscribe}
          expired={project.expired}
          locked={project.locked}
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
        />
      ),
      render: (record, row) => {
        return record ? (
          <CoverageBenefitCard
            translation={DETAIL.TEMPLATES.COMPARISON}
            expanded={hideDetail[row.type]}
            coverageBenefit={getCoverageBenefitById(
              get(insurancePlan, "insurancePlanCoverages", []),
              row.id
            )}
          />
        ) : null;
      },
    })) || []),
  ];

  return (
    <div>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.COMPARISON.TITLE}
        actions={[]}
      />
      <div className="comparison-container">
        <div className="d-flex justify--end">
          {locked && project?.expired ? (
            <>
              <Button
                onClick={() => duplicateProject()}
                type="primary--outlined"
              >
                <IoDuplicateOutline size={16} />
                {DETAIL.TEMPLATES.COMPARISON.DUPLICATE_PROJECT}
              </Button>
            </>
          ) : (
            <Button
              onClick={() => setOpenModal(true)}
              type="primary--outlined"
              disabled={project?.expired}
            >
              {DETAIL.TEMPLATES.COMPARISON.NEXT}
              <MdArrowForwardIos size={16} />
            </Button>
          )}
        </div>
        <Card type="simple">
          <DownloadOrSend
            data={data}
            open={openModal}
            onClose={() => setOpenModal(false)}
            onDownload={onDownload}
            onSendQuote={onSendQuote}
            fields={fields}
            insurancePlans={get(project, "subscriptions", []).map(
              ({ insurancePlan }) => insurancePlan?.id
            )}
            selectedModules={selectedModules}
          />
          {loading ? (
            <Loading />
          ) : (
            <Table
              dataSource={dataSource}
              columns={columns}
              className="comparison-table__wrapper"
              rowKey={"id"}
              pagination={false}
              scroll={{ x: true, y: 520 }}
              rowClassName={(record) =>
                record.__typename === "CoverageCategory"
                  ? "coverage-header-row"
                  : ""
              }
            />
          )}
        </Card>

        <Button
          onClick={onBack}
          type="secondary--link"
          disabled={project.locked}
        >
          <MdArrowBackIos size={16} />
          {DETAIL.TEMPLATES.COMPARISON.PREVIOUS}
        </Button>
      </div>
    </div>
  );
};

export default View;
