import templates from "./templates";

const Layout = ({children}) => {
  const [template] = templates;
  const { header, sider } = template;

  return (
    <main className="main__template">
      {sider.widget}
      <div className="main__template--body">
        {header.widget}
        {children}
      </div>


    </main>
  );
};

export default Layout;
