import { gql } from "@apollo/client";

export const COVERAGES = gql`
  query Coverages(
    $where: CoverageWhereInput
    $take: Int
    $skip: Int
    $like: CoverageWhereInput
    $orderBy: [CoverageWhereInput]
  ) {
    coverages(
      where: $where
      take: $take
      skip: $skip
      like: $like
      orderBy: $orderBy
    ) {
      data {
        id
        type
        identifier
        coverageBenefits {
          id
          order
          type
          subtype
          additionalInfo
          limits {
            id
            value
          }
          supportingInformation {
            id
            category
            value
            weight
          }
        }
        waitingDays
      }
      count
    }
  }
`;

export const COVERAGE = gql`
  query Coverage($where: CoverageWhereInput) {
    coverage(where: $where) {
      id
      type
      identifier
    }
  }
`;

const coverages = { COVERAGES, COVERAGE };

export default coverages;
