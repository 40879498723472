import classNames from "classnames";
import format from "string-template";
import Card from "./Card";

const EmailTemplate = ({
  civility,
  lastName,
  firstName,
  currentDate,
  endOfValidityDate,
  adminPhoneNumber,
  adminFirstName,
  adminLastName,
  adminEmail,
  adminAddress,
  translation,
  costs,
  addLinks,
}) => {
  return (
    <div className="quote-template__main-container">
      <div className="quote-template__header">
        <img
          src="https://www.solyon-mutuelle.fr/wp-content/uploads/2022/06/Logo_quadri.svg"
          alt="SO'LYON Logo"
        />
      </div>
      <div className="quote-template__container">
        <div className="quote-template__content">
          <p>
            {translation.HELLO} {translation[civility] || civility} {firstName}{" "}
            {lastName},
          </p>
          <p>{translation.P1}</p>
          <p>
            {translation.P2} {currentDate}.
          </p>
          <p>{translation.P3}</p>
          <p>
            {translation.P4} {endOfValidityDate}.
          </p>
        </div>
        <div className="quote-template__content">
          <div className="quote-template__submit--link">
            <div className="quote-template__submit">{translation.BUTTON}</div>
          </div>
        </div>
        <div className={classNames({ hidden: !addLinks })}>
          <div className="quote-template__content">
            <p>
              <strong>{translation.P5}</strong>
            </p>
          </div>
          {costs?.map(({ id, insurancePlan, cost, currency }) => (
            <Card
              key={id}
              icon={insurancePlan?.icon}
              name={insurancePlan?.name}
              cost={cost}
              currency={currency}
              period={translation.PER_MONTH}
              perYear={translation.PER_YEAR}
              allIncluded={translation.ALL_INCLUDED}
              link={translation.LINK}
            />
          ))}
        </div>
        <div className="quote-template__content">
          <p>{format(translation.P6, { adminPhoneNumber })}</p>
        </div>
        <div className="quote-template__info">
          <p className="quote-template__info--text">
            {translation.YOUR_ADVISOR}
          </p>
          <p className="quote-template__info--name">
            {adminFirstName} {adminLastName}
          </p>
          <p>{translation.RADIANCE}</p>
          <p>{adminEmail}</p>
          <p>{adminPhoneNumber}</p>
          <p>{adminAddress}</p>
        </div>
      </div>
      <div className="quote-template__footer">
        {/* <p className="quote-template__footer--title">{translation.RADIANCE}</p> */}
        {/* <p>95 rue Vendôme 69006 Lyon</p> */}
      </div>
    </div>
  );
};

export default EmailTemplate;
