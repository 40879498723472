import { get, isEmpty, take } from "lodash";
import { MdOpenInNew } from "react-icons/md";
import Input from "shared/components/Input";
import { COLORS } from "shared/style/colors";

const Card = ({
  products,
  onCheck,
  selectedModules,
  onOpenDetails,
  onShowMore,
  mainProduct,
  translation,
  disabled,
}) => {
  if (isEmpty(products))
    return (
      <div className="upsells">{get(translation, "UPSELLS.NO_MODULE", "")}</div>
    );
  return (
    <div className="upsells">
      {take(
        products?.map((product, i) => (
          <div className="upsells__checkbox" key={`product-${i}`}>
            <Input
              type="checkbox"
              label={get(product, "insurancePlan.name", "")}
              onChange={() => onCheck(product)}
              checked={
                get(selectedModules, mainProduct, []).find(
                  ({ insurancePlan }) =>
                    insurancePlan?.id === get(product, "insurancePlan.id", "")
                ) || false
              }
              disabled={disabled}
            />
            <div
              className="upsells__checkbox--button"
              onClick={() => onOpenDetails(get(product, "insurancePlan.id"))}
            >
              <MdOpenInNew size={16} color={COLORS.C_PRIMARY} />
            </div>
          </div>
        )),
        2
      )}
      <div className="upsells__link m-top-8" onClick={() => onShowMore(true)}>
        {get(translation, "UPSELLS.SHOW_MORE", "")}
      </div>
    </div>
  );
};

export default Card;
