import gql from "graphql-tag";

export const CREATE_TEMPLATE = gql`
  mutation AddTemplate($data: TemplateDataInput) {
    addTemplate(data: $data) {
      id
    }
  }
`;
export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($where: TemplateWhereInput) {
    deleteTemplate(where: $where) {
      id
    }
  }
`;
export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($where: TemplateWhereInput, $data: TemplateDataInput) {
    updateTemplate(where: $where, data: $data) {
      id
    }
  }
`;

const templates = { CREATE_TEMPLATE, DELETE_TEMPLATE, UPDATE_TEMPLATE };

export default templates;
