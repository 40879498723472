import { get } from "lodash";
import { RELATIONSHIP, SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import Activity from "./Activity";
import Adherent from "./Adherent";
import BrokerageMandate from "./BrokerageMandate";
import Children from "./Children";
import Contact from "./Contact";
import Contract from "./Contract";
import Information from "./Information";
import Partner from "./Partner";
import Payment from "./Payment";
import Refund from "./Refund";
import SponsorshipMembership from "./SponsorshipMembership";
import TerminationRequest from "./TerminationRequest";

export const generateFormMap = (subscription) => {
  const relatedPersons = get(
    subscription,
    "project.contact.relatedPersons",
    []
  );
  const hasRia = get(subscription, "project.ria", false);

  return {
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTRACT]: Contract,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.SPONSORSHIP_MEMBERSHIP]:
      SponsorshipMembership,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINATION_REQUEST]:
      hasRia && TerminationRequest,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE]:
      hasRia && BrokerageMandate,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ADHERENT]: Adherent,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ACTIVITY]: Activity,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT]: Contact,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PARTNER]:
      relatedPersons.find(
        (person) => person.relationship === RELATIONSHIP.SPOUSE
      ) && Partner,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CHILDREN]:
      relatedPersons.find(
        (person) => person.relationship === RELATIONSHIP.CHILD
      ) && Children,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.INFORMATION]: Information,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PAYMENT]: Payment,
    [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.REFUND]: Refund,
  };
};
