import { gql } from "@apollo/client";

export const TEMPLATES = gql`
  query Templates(
    $where: TemplateWhereInput
    $like: JSONObject
    $take: Int
    $skip: Int
  ) {
    templates(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        name
        active
        custom
        theme
        form
        header
      }
      count
    }
  }
`;

export const TEMPLATE = gql`
  query Template($where: TemplateWhereInput) {
    template(where: $where) {
      id
      name
      active
      custom
      theme
      form
      header
    }
  }
`;

const templates = { TEMPLATES, TEMPLATE };

export default templates;
