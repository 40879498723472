import { DEFAULT_STATUS, STATUS } from "utils/constants";

import PropTypes from "prop-types";

const Status = ({ status, text, icon, size }) => {
  return (
    <div className={`status__wrap ${status}--variant ${size}--variant`}>
      {icon && icon}
      <span> {text}</span>
    </div>
  );
};

Status.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.oneOf(STATUS),
};

Status.defaultProps = {
  status: DEFAULT_STATUS,
};

export default Status;
