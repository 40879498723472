import gql from "graphql-tag";

export const CREATE_BRAND = gql`
  mutation AddBrand($data: BrandDataInput) {
    addBrand(data: $data) {
      id
    }
  }
`;

export const UPDATE_BRAND = gql`
  mutation UpdateBrand($data: BrandDataInput, $where: BrandWhereInput) {
    updateBrand(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_BRAND = gql`
  mutation DeleteBrand($where: BrandWhereInput) {
    deleteBrand(where: $where) {
      id
    }
  }
`;

const brands = { CREATE_BRAND, UPDATE_BRAND, DELETE_BRAND };

export default brands;
