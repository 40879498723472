import { Table } from "antd";
import cuid from "cuid";
import Projects from "entities/Projects";
import {
  find,
  first,
  get,
  intersection,
  isEmpty,
  last,
  filter as lodashFilter,
  sortBy,
} from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPlus } from "react-icons/bi";
import { BsViewList } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { IoDuplicateOutline } from "react-icons/io5";
import {
  MdDeleteOutline,
  MdOpenInNew,
  MdOutlineEventBusy,
  MdOutlineLock,
  MdOutlineLockOpen,
} from "react-icons/md";
import { TbFileExport } from "react-icons/tb";
import Avatar from "shared/components/Avatar";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Filter from "shared/components/Filter";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Pagination from "shared/components/Pagination";
import Popover from "shared/components/Popover";
import Select from "shared/components/Select";

import Status from "shared/components/Status";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { USERS } from "utils/api/graphql/queries/users";
import {
  ACTIONS,
  DATE_TIME_FORMAT,
  INITIAL_VALUES,
  PROCESS_TAGS as PROCESS_TAGS_STATUSES,
  PROCESS_TYPES,
  SUBSCRIPTION_STATUSES,
  THEMES_PALETTE,
  USER_TYPES,
} from "utils/constants";
import { setPaginationHistory } from "utils/helpers/pagination";
import ContactCard from "../../Clients/Card";
import OfferDrawer from "./Offers";
import SignProjectDrawer from "./SignProjectDrawer";
import Subscriptions from "./Subscriptions";

const LOCK_ICON = {
  [true]: <MdOutlineLock size={24} color={COLORS.C_GRAY} />,
  [false]: <MdOutlineLockOpen size={24} color={COLORS.C_GRAY} />,
};

const List = ({
  filter,
  onSearch,
  applyFilter,
  pagination,
  setPagination,
  duplicate,
  createProject,
  onDelete,
  onDetail,
  actions,
  exportSubscriptions,
  exportLoading,
  onAssign,
  isAssigning,
  onChangeStatus,
  tracers,
  tracer,
  setTracer,
  user = {},
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const FILTER = t("FILTER", { returnObjects: true });
  const LIST = t("LIST", { returnObjects: true });
  const PROCESS_TAGS = t("PROCESS_TAGS", { returnObjects: true });
  const [openSignDrawer, setOpenSignDrawer] = useState(null);
  const [createOfferDrawer, setCreateOfferDrawer] = useState(null);
  const [openSubscriptionDrawer, setOpenSubscriptionDrawer] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [project, setProject] = useState(null);
  const [search, setSearch] = useState({});
  const [open, setOpen] = useState(false);
  const [offers, setOffers] = useState([]);
  const [status, setStatus] = useState({});

  const showDrawerSubscription = (id) => {
    setOpenSubscriptionDrawer(id);
  };

  const onCloseSubscription = () => {
    setOpenSubscriptionDrawer(null);
  };

  const onCloseSign = () => {
    setOpenSignDrawer(null);
  };

  const showDrawerSign = ({ id, statuses }) => {
    setOpenSignDrawer({ id, statuses });
  };

  const viewContactCard = (contactId, project) => {
    const status = project.statuses.find(
      ({ status }) => get(status, "process.type") === PROCESS_TYPES.B2B
    );
    const tracer = tracers.find(
      ({ flow }) => flow.process.id === get(status, "status.process.id")
    );
    setTracer(tracer);
    setContactId(contactId);
    setProject(project);
    setOpen(true);
  };

  const PAGINATION_OPTIONS = [
    { label: `10 ${LIST.FILTERS.PROJECTS}`, value: 10 },
    { label: `20 ${LIST.FILTERS.PROJECTS}`, value: 20 },
    { label: `50 ${LIST.FILTERS.PROJECTS}`, value: 50 },
    { label: `100 ${LIST.FILTERS.PROJECTS}`, value: 100 },
  ];

  const columns = [
    {
      width: 48,
      // responsive: ["sm"],
      dataIndex: "contact",
      render: (contact) => {
        return (
          <>
            <Avatar
              mode={"text"}
              size={"medium"}
              value={`${get(contact, "user.firstname", "")?.substring(
                0,
                1
              )}${get(contact, "user.lastname", "")?.substring(0, 1)}`}
            />
          </>
        );
      },
      fixed: true,
    },
    {
      title: LIST.CLIENT,
      dataIndex: "contact",
      render: (contact, project) => (
        <div className="d-flex">
          <div
            className="name--client"
            onClick={() => viewContactCard(get(contact, "id"), project)}
          >
            {`${get(contact, "user.firstname", "")} ${get(
              contact,
              "user.lastname",
              ""
            )}`}
          </div>
        </div>
      ),
      fixed: true,
    },
    {
      title: LIST.OFFER,
      dataIndex: "fields",
      render: (fields) => (
        <Status
          status="gray"
          style={{
            color: get(
              get(THEMES_PALETTE, fields.processTemplate, "DEFAULT"),
              "COLOR"
            ),
            background: get(
              get(THEMES_PALETTE, fields.processTemplate, "DEFAULT"),
              "BACKGROUND"
            ),
          }}
          text={get(
            get(THEMES_PALETTE, fields.processTemplate, "DEFAULT"),
            "NAME"
          )}
        />
      ),
    },
    {
      title: LIST.STATUS,
      dataIndex: "statuses",
      render: (record, project, i) => {
        const status = project.statuses.find(
          ({ status }) => get(status, "process.type") === PROCESS_TYPES.B2B
        );
        const tracer = tracers.find(
          ({ flow }) => flow.process.id === get(status, "status.process.id")
        );

        const currentStatus = get(
          last(
            sortBy(
              lodashFilter(
                record,
                ({ status }) => status?.process?.type === PROCESS_TYPES.B2B
              ),
              "createdDate"
            )
          ),
          "status"
        );

        const statusText =
          project.expired && currentStatus?.tag !== PROCESS_TAGS_STATUSES.CLOSE
            ? PROCESS_TAGS.EXPIRED
            : get(currentStatus, "name", PROCESS_TAGS.IN_PROGRESS);

        const statusesOptions = get(tracer, "flow.process.processStatuses", [])
          .filter(({ tag }) =>
            [
              PROCESS_TAGS_STATUSES.SIGNED,
              PROCESS_TAGS_STATUSES.CLOSE,
            ].includes(tag)
          )
          .map((status) => {
            return {
              label: get(status, "name"),
              value: get(status, "id"),
              onClick:
                status.tag === PROCESS_TAGS_STATUSES.SIGNED
                  ? () =>
                      showDrawerSign({
                        id: project.id,
                        statuses: project.statuses,
                      })
                  : () =>
                      onChangeStatus({
                        project,
                        status,
                      }),
              disabled:
                status.tag === PROCESS_TAGS_STATUSES.SIGNED && !project.locked,
              popover:
                status.tag === PROCESS_TAGS_STATUSES.SIGNED && !project.locked
                  ? LIST.STATUS_POPOVER
                  : "",
            };
          });

        return ![PROCESS_TAGS_STATUSES.SIGNED].includes(
          get(currentStatus, "tag")
        ) ? (
          <div className="select_200">
            <Select
              popupMatchSelectWidth={false}
              type="tag"
              value={statusText}
              options={statusesOptions}
            />
          </div>
        ) : (
          <Status
            type="custom"
            style={{
              color: get(currentStatus, "color"),
              background: get(currentStatus, "background"),
            }}
            text={statusText}
          />
        );
      },
    },
    {
      title: LIST.CREATION_DATE,
      dataIndex: "createdDate",
      render: (record) => moment(record).format(DATE_TIME_FORMAT),
    },
    {
      title: LIST.ADVISOR,
      dataIndex: "author",
      render: (record, project) => {
        const status = project.statuses.find(
          ({ status }) => get(status, "process.type") === PROCESS_TYPES.B2B
        );
        const tracer = tracers.find(
          ({ flow }) => flow.process.id === get(status, "status.process.id")
        );
        const currentStatus = get(
          find(
            project.statuses,
            ({ status }) =>
              status?.process?.id === get(tracer, "flow.process.id")
          ),
          "status"
        );
        return (
          <div className="select_200">
            <Select
              type="async"
              value={record?.id}
              node={{
                query: USERS,
                variables: {
                  where: {
                    AND: [
                      { isActive: true },
                      {
                        OR: [
                          { meta: { type: USER_TYPES.INTERNAL_ADVISOR.type } },
                          {
                            meta: { type: USER_TYPES.INTERNAL_SUPERVISOR.type },
                          },
                          { meta: { type: USER_TYPES.BROKER_EMPLOYEE.type } },
                          { meta: { type: USER_TYPES.BROKER_ADMIN.type } },
                          {
                            meta: {
                              type: USER_TYPES.BROKER_REPRESENTATIVE.type,
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                accessors: {
                  root: "users.data",
                  label: {
                    separator: " ",
                    keys: ["firstname", "lastname"],
                  },
                },
              }}
              placeholder={FILTER.BROKER}
              onChange={(value) =>
                onAssign({ projectId: project.id, authorId: value })
              }
              disabled={
                isAssigning[project?.id] ||
                currentStatus?.tag === PROCESS_TAGS_STATUSES.SIGNED
              }
              loading={isAssigning[project?.id]}
            />
          </div>
        );
      },
    },
    {
      width: 62,
      dataIndex: "locked",
      align: "right",
      render: (record, row) => (
        <div className="d-flex justify--end">
          {row.expired && (
            <div className="popover-wrapper">
              <Popover text={LIST.EXPIRED_POPOVER} />
              <MdOutlineEventBusy size={24} color={COLORS.C_DANGER} />
            </div>
          )}
          <div className="popover-wrapper">
            {record && (
              <Popover
                text={row.expired ? LIST.EXPIRED_LOCKED : LIST.LOCKED_POPOVER}
              />
            )}
            {LOCK_ICON[record]}
          </div>
        </div>
      ),
    },
    ...(actions[ACTIONS.UPDATE_SUBSCRIPTION] ||
    actions[ACTIONS.DELETE_PROJECT] ||
    actions[ACTIONS.DUPLICATE_PROJECT] ||
    actions[ACTIONS.UPDATE_PROJECT]
      ? [
          {
            title: LIST.ACTIONS,
            dataIndex: "id",
            align: "right",
            render: (id, project) => {
              const status = project.statuses.find(
                ({ status }) =>
                  get(status, "process.type") === PROCESS_TYPES.B2B
              );
              const tracer = tracers.find(
                ({ flow }) =>
                  flow.process.id === get(status, "status.process.id")
              );
              const currentStatus = get(
                find(
                  project.statuses,
                  ({ status }) =>
                    status?.process?.id === get(tracer, "flow.process.id")
                ),
                "status"
              );
              const disableDetail =
                currentStatus?.tag === PROCESS_TAGS_STATUSES.CLOSE;
              const isSigned = get(project, "subscriptions", [])?.some(
                (subscription) =>
                  get(subscription, "status") === SUBSCRIPTION_STATUSES.SIGNED
              );
              return (
                get(project, "author") && (
                  <div className="d-flex justify--end">
                    {!isEmpty(get(project, "subscriptions", [])) &&
                      intersection(
                        [
                          PROCESS_TAGS_STATUSES.CLOSE,
                          PROCESS_TAGS_STATUSES.EXPIRED,
                          PROCESS_TAGS_STATUSES.SIGNED,
                        ],
                        get(project, "statuses", []).map(
                          ({ status: { tag } }) => tag
                        )
                      ).length === 0 &&
                      actions[ACTIONS.UPDATE_SUBSCRIPTION] &&
                      !project?.expired && (
                        <Button
                          type="primary--light"
                          icon={<BsViewList />}
                          onClick={() => showDrawerSubscription(id)}
                        >
                          {LIST.RESUME_SUBSCRIPTION}
                        </Button>
                      )}

                    {actions[ACTIONS.DUPLICATE_PROJECT] && (
                      <IoDuplicateOutline
                        size={24}
                        onClick={() => {
                          const status = project.statuses.find(
                            ({ status }) =>
                              get(status, "process.type") === PROCESS_TYPES.B2B
                          );
                          const tracer = tracers.find(
                            ({ flow }) =>
                              flow.process.id ===
                              get(status, "status.process.id")
                          );
                          setTracer(tracer);
                          duplicate({
                            variables: {
                              where: { id },
                              data: {
                                visible: true,
                                statuses: [
                                  {
                                    id: cuid(),
                                    status: {
                                      id: get(
                                        first(
                                          sortBy(
                                            get(
                                              tracer,
                                              "flow.process.processStatuses"
                                            ),
                                            "order"
                                          )
                                        ),
                                        "id"
                                      ),
                                    },
                                  },
                                ],
                              },
                            },
                          });
                        }}
                      />
                    )}

                    {actions[ACTIONS.DELETE_PROJECT] && (
                      <MdDeleteOutline
                        color={
                          isSigned
                            ? COLORS.C_DANGER_BACKGROUND
                            : COLORS.C_DANGER
                        }
                        size={24}
                        onClick={() => (isSigned ? null : onDelete(id))}
                      />
                    )}
                    {actions[ACTIONS.PROJECT] &&
                      actions[ACTIONS.UPDATE_PROJECT] && (
                        <MdOpenInNew
                          color={
                            disableDetail
                              ? COLORS.C_PRIMARY_BACKGROUND
                              : COLORS.C_PRIMARY
                          }
                          size={24}
                          onClick={() =>
                            disableDetail ? null : onDetail(project)
                          }
                        />
                      )}
                  </div>
                )
              );
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <Header
        key={"header"}
        title={LIST.HEADER.TITLE}
        actions={[
          ...(actions[ACTIONS.ADD_PROJECT]
            ? [
                {
                  icon: <BiPlus size={20} />,
                  text: LIST.HEADER.CREATE.NORMAL,
                  onClick: () => setCreateOfferDrawer(true),
                },
              ]
            : []),
          ...(actions[ACTIONS.EXPORT_SUBSCRIPTIONS]
            ? [
                {
                  icon: <TbFileExport size={20} />,
                  text: LIST.HEADER.EXPORT_SUBSCRIPTIONS,
                  onClick: exportSubscriptions,
                  loading: exportLoading,
                },
              ]
            : []),
        ]}
      />
      <Card type="simple">
        <div className="filter__area">
          <div className="search--bar">
            <Input
              placeholder={LIST.FILTERS.CLIENT_LAST_NAME}
              onChange={({ target: { value } }) =>
                setSearch((prev) => ({ ...prev, clientLastName: value }))
              }
            />
            <Input
              placeholder={LIST.FILTERS.CLIENT_FIST_NAME}
              onChange={({ target: { value } }) =>
                setSearch((prev) => ({ ...prev, clientFirstName: value }))
              }
            />
            {user?.meta?.type === USER_TYPES.INTERNAL_SUPERVISOR.type && (
              <>
                <Input
                  placeholder={LIST.FILTERS.ADVISOR_LAST_NAME}
                  onChange={({ target: { value } }) =>
                    setSearch((prev) => ({ ...prev, advisorLastName: value }))
                  }
                />
                <Input
                  placeholder={LIST.FILTERS.ADVISOR_FIST_NAME}
                  onChange={({ target: { value } }) =>
                    setSearch((prev) => ({ ...prev, advisorFirstName: value }))
                  }
                />
              </>
            )}
            <Button
              type="secondary"
              onClick={() => onSearch(search)}
              icon={<FaSearch size={14} />}
              size={"full--w"}
            >
              {LIST.FILTERS.FILTER}
            </Button>
            <Filter
              translate={FILTER.OFFER}
              items={tracers.map(({ name }) => name)}
              setFilter={(offers) => {
                setOffers(offers);
                applyFilter({ offers, ...status });
              }}
            />
          </div>
          <div className="filter__search">
            <Filter
              translate={FILTER.PROCESS_TAG}
              items={Object.keys(PROCESS_TAGS)}
              setFilter={(items) => {
                setStatus({
                  expired: !!items.find(
                    (item) => item === PROCESS_TAGS_STATUSES.EXPIRED
                  ),
                  tags: items.filter(
                    (item) => item !== PROCESS_TAGS_STATUSES.EXPIRED
                  ),
                });
                applyFilter({
                  offers,
                  expired: !!items.find(
                    (item) => item === PROCESS_TAGS_STATUSES.EXPIRED
                  ),
                  tags: items.filter(
                    (item) => item !== PROCESS_TAGS_STATUSES.EXPIRED
                  ),
                });
              }}
            />
            <Select
              options={PAGINATION_OPTIONS}
              value={pagination.take || INITIAL_VALUES.take}
              onChange={(value) => {
                setPaginationHistory({
                  footprint: "projects",
                  value: Math.floor(pagination.skip / value) + 1,
                  take: value,
                  count: pagination.count,
                });
                setPagination((prev) => ({ ...prev, take: value }));
              }}
            />
          </div>
        </div>
      </Card>
      {contactId && (
        <ContactCard
          contactId={contactId}
          project={project}
          open={open}
          tracer={tracer}
          tracers={tracers}
          setOpen={setOpen}
        />
      )}
      <OfferDrawer
        setCreateOfferDrawer={setCreateOfferDrawer}
        createProject={createProject}
        open={createOfferDrawer}
        tracers={tracers}
      />
      <div className="m-bottom-24" />
      <Projects
        View={({ count, data, setValues, type, skip }) => (
          <Card type={"simple"}>
            <Table
              dataSource={data}
              columns={columns}
              className="table__wrapper"
              rowKey={"id"}
              pagination={false}
              scroll={{ x: true }}
            />
            <Pagination
              footprint={type}
              count={count}
              setValues={setValues}
              skip={skip || 0}
              take={pagination.take}
            />
          </Card>
        )}
        setValues={setPagination}
        skip={pagination.skip}
        filter={{ ...filter, ...pagination }}
        fetchPolicy="network-only"
      />
      {openSubscriptionDrawer && (
        <Subscriptions
          onChangeStatus={onChangeStatus}
          onClose={onCloseSubscription}
          project={openSubscriptionDrawer}
        />
      )}
      {openSignDrawer?.id && (
        <SignProjectDrawer onClose={onCloseSign} project={openSignDrawer} />
      )}
    </>
  );
};

export default List;
