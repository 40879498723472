import { gql } from "@apollo/client";

const USER_ACTIVITY_ADDED = gql`
  subscription userActivityAdded($where: UserActivityWhereInput) {
    userActivityAdded(where: $where) {
      id
      module
      action
      status
      critical
      createdDate
      updatedDate
      process {
        id
      }
      user {
        id
        firstname
        lastname
      }
      data
    }
  }
`;


const userActivity = {
  USER_ACTIVITY_ADDED
};

export default userActivity;
