import { DeleteOutlined } from "@ant-design/icons";
import cuid from "cuid";
import { FieldArray } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { BiPlus } from "react-icons/bi";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Radio from "shared/components/Form/Radio";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { USERS } from "utils/api/graphql/queries/users";
import { GENDER, SKILL_TYPES, USER_TYPES } from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";

const Advisor = ({ formProps }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const FORM = t("FORM", { returnObjects: true });

  return [
    <div className="grid--1">
      <Card key="user" type={"simple"} head title={FORM.GENERAL_INFO}>
        <div className="grid--2">
          <Select
            type="async"
            node={{
              query: USERS,
              variables: {
                where: { meta: { type: USER_TYPES.INTERNAL_SUPERVISOR.type } },
              },
              accessors: {
                root: "users.data",
                label: {
                  separator: " ",
                  keys: ["firstname", "lastname"],
                },
                value: "pole.id",
              },
            }}
            placeholder={FORM.SUPERVISOR}
            label={FORM.SUPERVISOR}
            {...bindInputProps({
              name: "pole.id",
              ...formProps,
            })}
          />
          <div></div>
          <Input
            placeholder={FORM.NETWORK}
            label={FORM.NETWORK}
            {...bindInputProps({ name: "meta.network", ...formProps })}
            required
          />
          <Input
            placeholder={FORM.AGENCY}
            label={FORM.AGENCY}
            {...bindInputProps({ name: "meta.agency", ...formProps })}
            required
          />
          <Input
            placeholder={FORM.AGENCY_EMAIL}
            label={FORM.AGENCY_EMAIL}
            {...bindInputProps({ name: "meta.emailAgency", ...formProps })}
          />
          <Radio
            isHorizontal={false}
            label={FORM.CIVILITY.LABEL}
            options={[
              {
                label: FORM.CIVILITY.MALE,
                value: GENDER.MALE,
              },
              {
                label: FORM.CIVILITY.FEMALE,
                value: GENDER.FEMALE,
              },
            ]}
            {...bindInputProps({ name: "gender", ...formProps })}
            required
          />
          <Input
            placeholder={FORM.FIRST_NAME}
            label={FORM.FIRST_NAME}
            {...bindInputProps({ name: "firstname", ...formProps })}
            required
          />
          <Input
            placeholder={FORM.LAST_NAME}
            label={FORM.LAST_NAME}
            {...bindInputProps({ name: "lastname", ...formProps })}
            required
          />
          <Input
            placeholder={FORM.PHONE_AGENCY}
            label={FORM.PHONE_AGENCY}
            {...bindInputProps({ name: "phone", ...formProps })}
          />
          <Input
            placeholder={FORM.EMAIL}
            label={FORM.EMAIL}
            {...bindInputProps({ name: "email", ...formProps })}
            required
          />
          <Input
            placeholder={FORM.ADDRESS}
            label={FORM.ADDRESS}
            {...bindInputProps({ name: "address", ...formProps })}
            required
          />
          <Input
            placeholder={FORM.ZIP_CODE}
            label={FORM.ZIP_CODE}
            {...bindInputProps({ name: "zipCode", ...formProps })}
            required
          />
          <Input
            placeholder={FORM.CITY}
            label={FORM.CITY}
            {...bindInputProps({ name: "city", ...formProps })}
            required
          />
        </div>
      </Card>
      <Card title={FORM.SKILLS.TITLE} divider={false} key="skills">
        <div className="grid--1">
          <FieldArray
            name={"skills"}
            render={(arrayHelpers) => [
              get(formProps, "values.skills", []).map((_, i) => [
                <div className="grid--2" key={`row-${i}`}>
                  <Select
                    options={Object.entries(SKILL_TYPES)
                      .map(([key, value]) => ({
                        label: get(FORM, `SKILLS.TYPES.${key}`, key),
                        value,
                      }))
                      .filter(
                        ({ value }) =>
                          value === get(formProps, `values.skills.${i}.type`) ||
                          get(formProps, "values.skills", []).every(
                            ({ type }) => type !== value
                          )
                      )}
                    placeholder={FORM.SKILLS.TYPE}
                    label={FORM.SKILLS.TYPE}
                    {...bindArrayInputProps({
                      parent: "skills",
                      index: i,
                      name: "type",
                      ...formProps,
                    })}
                  />
                  <div className="d-flex align--end">
                    <Button
                      onClick={() => {
                        arrayHelpers.remove(i);
                      }}
                      type="danger--outlined"
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                </div>,
              ]),
              <Button
                key="add-row"
                onClick={() => {
                  arrayHelpers.push({ id: cuid(), type: null, value: null });
                }}
                type="primary"
              >
                <BiPlus size={24} />
              </Button>,
            ]}
          />
        </div>
      </Card>
    </div>,
  ];
};

export default Advisor;
