import { Col, Divider, Row, Table, Tag } from "antd";
import Qualifications from "entities/Qualifications";
import { cond, find, get, isEmpty } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Card from "shared/components/Card";
import Header from "shared/components/Header/Header";
import Input from "shared/components/Input";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_POINT_SYSTEM,
  DATE_TIME_FORMAT,
  SCREENS,
} from "utils/constants";
import Qualifiers from "./Qualifiers";
import Qualify from "./Qualify";

function List({ filter, setFilter, setPagination }) {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION);
  const LIST = t("LIST", { returnObjects: true });
  const QUALIFIERS = t("QUALIFIERS", { returnObjects: true });
  const navigate = useNavigate();

  const columns = [
    {
      title: LIST.FULLNAME,
      dataIndex: "contact",
      render: (record, qualification) => (
        <Tag
          onClick={() =>
            navigate(
              `${SCREENS.PRIVATE.ADMIN.QUALIFICATIONS.SHOW.path}/${qualification.id}`,
              {
                state: { qualification },
              }
            )
          }
        >
          {`${get(record, "user.firstname", "")} ${get(
            record,
            "user.lastname",
            ""
          )}`}
        </Tag>
      ),
    },
    {
      title: LIST.SOURCE,
      dataIndex: "contact",
      render: (record) => get(record, "footprint.source.name", "-"),
    },
    {
      title: LIST.GENDER.TITLE,
      dataIndex: "contact",
      render: (record) => LIST.GENDER.OPTIONS[get(record, "user.gender", "-")],
    },
    {
      title: LIST.PHONE,
      dataIndex: "contact",
      render: (record) =>
        find(get(record, "telecoms", []), {
          system: CONTACT_POINT_SYSTEM.PHONE,
        })?.value,
    },
    {
      title: LIST.REGIME,
      dataIndex: "contact",
      render: (record) =>
        LIST.SOCIAL_REGIMES[get(record, "fields.socialRegime", "-")] ||
        get(record, "fields.socialRegime", "-"),
    },
    {
      title: LIST.DATE,
      dataIndex: "contact",
      render: ({ createdDate }) =>
        moment(createdDate).format(DATE_TIME_FORMAT) || "-",
    },
    {
      title: LIST.HISTORY,
      render: (_, __) => {
        return <Qualify qualification={__} />;
      },
    },
    {
      title: LIST.SALES_PERSON,
      render: (_, __) => get(__, "broker.firstname"),
    },
  ];

  const View = ({ data, count, setValues, skip }) => (
    <>
      <Table
        className="table__wrapper"
        dataSource={data}
        columns={columns}
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
      />
      <br />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
  return (
    <>
      <Header
        key={"header"}
        title={LIST.HEADER.TITLE}
        okText={LIST.HEADER.SAVE}
        cancelText={LIST.HEADER.REFRESH}
        hideButtons={false}
        onOkClick={() =>
          navigate(SCREENS.PRIVATE.ADMIN.QUALIFICATIONS.CREATE.path)
        }
        onCancelClick={() => {}}
      />
      <Card type="simple">
        <Row>
          <Col lg={15} xs={24}>
            <Row gutter={[8, 8]}>
              <Col lg={8} xs={24}>
                <Input
                  type="date"
                  variant="range"
                  label={LIST.FILTERS.PERIOD}
                  onChange={(_, dates) => {
                    setFilter((filter) => ({
                      ...filter,
                      gt: { createdDate: moment(dates[0]).toDate() },
                      lt: { createdDate: moment(dates[1]).toDate() },
                    }));
                  }}
                />
              </Col>
              <Col lg={8} xs={24}>
                <Input
                  label={LIST.FILTERS.SEARCH}
                  placeholder={LIST.FILTERS.SEARCH}
                  onChange={(e) => {
                    setFilter((filter) => ({
                      ...filter,
                      concat: cond([
                        [(value) => isEmpty(value), () => undefined],
                        [
                          (value) => !isEmpty(value),
                          (value) => [
                            { contact: { user: { firstname: value } } },
                            { contact: { user: { lastname: value } } },
                          ],
                        ],
                      ])(e.target.value),
                    }));
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Qualifiers
          {...{ filter, setFilter, setPagination }}
          translation={QUALIFIERS}
        />
        <Divider />
        <Qualifications
          View={View}
          filter={filter}
          setValues={setPagination}
          skip={filter.skip || 0}
          NoData={View}
        />
      </Card>
    </>
  );
}

export default List;
