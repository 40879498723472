import { ColorPicker } from "antd";
import PropTypes from "prop-types";

import classnames from "classnames";
import Label from "shared/components/Label";

const Simple = ({
  label,
  placeholder,
  errors,
  touched,
  icon,
  innerRef,
  required,
  value,
  onChange,
  defaultValue,
  showText,
  ...rest
}) => {
  return (
    <div
      className={classnames("input__wrapper", { __error: touched && errors })}
    >
      <Label label={label} icon={icon} required={required} />
      <div className={`input__wrapper--field`}>
        <div className="input__wrapper--colorPicker">
          <ColorPicker
            value={value} 
            defaultValue={defaultValue} 
            onChange={(color) => onChange(color?.toHexString())} 
            showText={showText} 
            {...rest}
          />
        </div>
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

Simple.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  showText: PropTypes.func,
};

Simple.defaultProps = {
  label: "",
  placeholder: "",
  value: "",
  onChange: () => {},
  defaultValue: "#ffffff",
  showText: (color) => color.toHexString(), 
};

export default Simple;