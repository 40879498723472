import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { INSURANCE_PLAN_RISKS } from "utils/constants";
import DetailHealth from "./Health";
import DetailTerminal from "./Terminal";

const VIEW_BY_RISK = {
  [INSURANCE_PLAN_RISKS.HEALTH]: DetailHealth,
  [INSURANCE_PLAN_RISKS.TERMINAL]: DetailTerminal,
};

const Detail = (props) => {
  const { state } = useLocation();
  const View = useMemo(
    () => VIEW_BY_RISK[state?.risk || INSURANCE_PLAN_RISKS.HEALTH],
    [state?.risk]
  );
  return <View {...props} />;
};

export default Detail;
