import { gql } from "@apollo/client";

export const OFFERS = gql`
  query Offers(
    $where: OfferWhereInput
    $take: Int
    $skip: Int
    $isIn: JSONObject
    $like: OfferWhereInput
    $orderBy: [OfferWhereInput]
  ) {
    offers(
      where: $where
      take: $take
      skip: $skip
      isIn: $isIn
      like: $like
      orderBy: $orderBy
    ) {
      data {
        id
        name
        description
        process {
          id
          name
        }
        offerInsurancePlans {
          id
          main
          insurancePlan {
            id
            name
          }
        }
      }
      count
    }
  }
`;

export const OFFER = gql`
  query offer($where: OfferWhereInput) {
    offer(where: $where) {
      id
      name
      description
      process {
        id
        name
      }
      offerInsurancePlans {
        id
        main
        insurancePlan {
          id
          name
        }
      }
    }
  }
`;

const products = { OFFERS, OFFER };

export default products;
