import Input from "shared/components/Input";
import Checkbox from "shared/components/Input/Checkbox";
import Radio from "shared/components/Input/Radio";
import Switch from "shared/components/Input/Switch";
import Date from "shared/components/Input/Date/Simple";
import DateRange from "shared/components/Input/Date/Range";
import Select from "shared/components/Select";
import { bindInputProps, bindArrayInputProps, bindDateInputProps } from "utils/helpers/input";

const bindingFunctions = {
  bindInputProps,
  bindArrayInputProps,
  bindDateInputProps,
};

const toolbox = {
  input: {
    text: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue,
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <Input
            {...inputProps}
            name={fieldName}
            label={label}
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
          />
        ),
      };
    },

    email: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue,
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <Input
            {...inputProps}
            type="email"
            name={name || fieldName}
            label={label}
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
          />
        ),
      };
    },

    phone: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue,
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <Input
            {...inputProps}
            type="phone"
            name={name || fieldName}
            label={label}
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
          />
        ),
      };
    },

    password: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue,
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <Input
            {...inputProps}
            type="password"
            name={name || fieldName}
            label={label}
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
          />
        ),
      };
    },

    number: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue,
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <Input
            {...inputProps}
            type="number"
            name={name || fieldName}
            label={label}
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
          />
        ),
      };
    },

    textarea: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue,
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <Input
            {...inputProps}
            type="textarea"
            name={name || fieldName}
            label={label}
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
          />
        ),
      };
    },



    switch: ({
      name,
      label,
      entity,
      accessor,
      defaultValue = false,
      required = false,
      formProps = {},
      checkedChildren,
      unCheckedChildren,
      ...rest
    }) => {
      const bindProps = bindingFunctions.bindInputProps;
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      return {
        displayer: (
          <Switch
            {...props}
            name={name || fieldName}
            label={label}
            defaultChecked={defaultValue}
            checkedChildren={checkedChildren}
            unCheckedChildren={unCheckedChildren}
            required={required}
            {...rest}
          />
        ),
      };
    },

    checkbox: ({
      name,
      label,
      defaultValue = false,
      required = false,
      formProps = {},
      ...rest
    }) => {
      const props = {
        name,
        label,
        checked: defaultValue,
        required,
        ...formProps,
        ...rest,
      };

      return {
        displayer: (
          <Checkbox
            {...props}
            onChange={(e) => props.formProps.setFieldValue?.(props.name, e.target.checked)}
          />
        ),
      };
    },
  },

  color: {
    color: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue,
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <Input
            {...inputProps}
            type="color"
            name={name || fieldName}
            label={label}
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
          />
        ),
      };
    },
    picker: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue,
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <Input
            {...inputProps}
            type="colorPicker"
            name={name || fieldName}
            label={label}
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
          />
        ),
      };
    },
  },

  date: {
    simple: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue,
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <Date
            {...inputProps}
            name={name || fieldName}
            label={label}
            required={required}
            props={{
              value: formProps?.values?.[fieldName] || defaultValue,
              onChange: (date, dateString) =>
                formProps?.setFieldValue?.(fieldName, dateString),
              onBlur: () => formProps?.setFieldTouched?.(fieldName, true),
            }}
            {...rest}
          />
        ),
      };
    },

    range: ({
      name,
      label,
      entity,
      accessor,
      mode = "bindInputProps",
      formProps = {},
      defaultValue = [],
      required = false,
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      const { handleChange, setFieldTouched, ...inputProps } = props;

      return {
        displayer: (
          <DateRange
            {...inputProps}
            name={name || fieldName}
            label={label}
            required={required}
            props={{
              value: formProps?.values?.[fieldName] || defaultValue,
              onChange: (dates, dateStrings) =>
                formProps?.setFieldValue?.(fieldName, dateStrings),
              onBlur: () => formProps?.setFieldTouched?.(fieldName, true),
            }}
            {...rest}
          />
        ),
      };
    },
  },

  radio: {
    radioSimple: ({
      name,
      label,
      values = [],
      defaultValue,
      required = false,
      direction = "row",
      formProps = {},
      ...rest
    }) => {
      const props = {
        name,
        values,
        value: defaultValue,
        direction,
        required,
        ...formProps,
        ...rest,
      };

      return {
        displayer: (
          <Radio
            {...props}
            variant="simple"
            label={label}
          />
        ),
      };
    },
    radioGroup: ({
      name,
      label,
      values = [],
      defaultValue,
      required = false,
      direction = "column",
      formProps = {},
      ...rest
    }) => {
      const props = {
        name,
        values,
        value: defaultValue,
        direction,
        required,
        ...formProps,
        ...rest,
      };

      return {
        displayer: (
          <Radio
            {...props}
            variant="group"
            label={label}
          />
        ),
      };
    },
  },

  select: {
    simple: ({
      name,
      label,
      options,
      entity,
      accessor,
      formProps = {},
      defaultValue,
      required = false,
      mode = "bindArrayInputProps",
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      return {
        displayer: (
          <Select
            {...props}
            {...formProps}
            {...rest}
            options={options}
            label={label}
            name={name}
            type="simple"
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
          />
        ),
      };
    },
    async: ({
      name,
      label,
      options,
      entity,
      accessor,
      formProps = {},
      node = {},
      defaultValue,
      required = false,
      mode = "bindArrayInputProps",
      ...rest
    }) => {
      const bindProps = bindingFunctions[mode];
      const fieldName = `${entity}.${accessor}`;
      const props = bindProps
        ? bindProps({ name: fieldName, ...formProps, ...rest })
        : {};

      return {
        displayer: (
          <Select
            {...props}
            {...formProps}
            {...rest}
            options={options}
            label={label}
            name={name}
            type="async"
            placeholder={label}
            defaultValue={defaultValue}
            required={required}
            node={node}
          />
        ),
      };
    },

  },

  fallback: ({ type }) => ({
    displayer: (
      <p style={{ color: "red" }}>
        {`Error: Type "${type || "undefined"}" not supported.`}
      </p>
    ),
  }),
};

export default toolbox;
