import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { MdArrowForwardIos, MdOutlineAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { INSURANCE_PLAN_RISKS, SCREENS } from "utils/constants";

const View = ({ open, onClose }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const DRAWER = t("DRAWER", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  const navigate = useNavigate();

  const risks = [
    { label: FORM.HEALTH, value: INSURANCE_PLAN_RISKS.HEALTH },
    { label: FORM.TERMINAL, value: INSURANCE_PLAN_RISKS.TERMINAL },
  ];

  return (
    <Drawer
      title={FORM.RISK}
      placement="right"
      onClose={onClose}
      open={open}
      className="custom__drawer"
    >
      <div className="risk__cards">
        {risks.map(
          ({ value, label }, index) => (
            <div
              className="risk__cards--item"
              key={`risk-${index}`}
              onClick={() =>
                navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.CREATE.path, {
                  state: { risk: value },
                })
              }
            >
              <span>{label}</span>
              <span className="icon" >
                <MdArrowForwardIos size={16} />
              </span>
            </div>
          )
        )}
      </div>
    </Drawer>
  );
};
export default View;
