import { Col, Row } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { find, get, head, isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "shared/components/Button";
import Container from "shared/components/Container";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  FRANCE_DEPARTMENTS,
  INSURANCE_PLAN_PARAMS_RULES,
  SOCIO_PROFESSIONAL_CATEGORIES,
} from "utils/constants";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
import Stepper from "../Components/Stepper";
import UpdateCostModal from "./Widgets/UpdateCostModal";
import validation from "./validation";

const View = ({
  onSubmit,
  initialValues,
  subscription,
  generalCost,
  onPersonalInformationEdit,
  onSocialRegimeEdit,
  onProductEdit,
  cities,
  recalculateCost,
}) => {
  const [deltaRia, setDeltaRia] = useState(30);
  const [deltaStart, setDeltaStart] = useState(1);
  const [deltaEndRia, setDeltaEndRia] = useState();
  const [deltaEnd, setDeltaEnd] = useState();
  const [validStartDate, setValidStartDate] = useState();
  const [validEndDate, setValidEndDate] = useState();
  const [oldIssuanceDate, setOldIssuanceDate] = useState(
    get(initialValues, "project.contract.issuanceDate")
  );
  const [showChangeCostModal, setShowChangeCostModal] = useState({});
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  useEffect(() => {
    const rules = get(
      head(get(subscription, "insurancePlan.params", [])),
      "rules",
      []
    );
    const deltaRia = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
      }),
      "value",
      30
    );
    const deltaStart = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
      }),
      "value",
      1
    );
    const deltaEndRia = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
      }),
      "value",
      0
    );
    const deltaEnd = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
      }),
      "value",
      0
    );
    setDeltaRia(deltaRia);
    setDeltaStart(deltaStart);
    setDeltaEndRia(deltaEndRia);
    setDeltaEnd(deltaEnd);
    setValidStartDate(
      get(subscription, "project.ria")
        ? dayjs(
            moment
              .max(
                moment().add(deltaRia, "days"),
                moment().startOf("y").add(5, "h")
              )
              .toDate()
          )
        : dayjs(
            moment
              .max(
                moment().add(deltaStart, "days"),
                moment().startOf("y").add(5, "h")
              )
              .toDate()
          )
    );
    setValidEndDate(
      get(subscription, "project.ria")
        ? deltaEndRia === 0
          ? dayjs().endOf("year")
          : dayjs().add(deltaEndRia, "days")
        : deltaEnd === 0
        ? dayjs().endOf("year")
        : dayjs().add(deltaEnd, "days")
    );
  }, []);
  const checkExpired = useCallback(({ issuanceDate }) => {
    const minIssuanceDate = dayjs().startOf("day").add(deltaRia, "days");
    const expired = dayjs(issuanceDate).isBefore(minIssuanceDate);
    return expired;
  }, []);

  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        }),
        {
          hasPartner: !isEmpty(get(initialValues, "spouse")),
        }
      ),
    });

  const onChangeCost = () => {
    setShowChangeCostModal({});
  };

  const onCancelChangeCost = () => {
    if (showChangeCostModal?.isRia) {
      setFieldValue("resiliation", false);
      setFieldValue("project.ria", false);
      setFieldValue("project.fields.selfOccupation", undefined);
      setFieldValue(
        "project.contract.issuanceDate",
        get(initialValues, "project.contract.issuanceDate")
      );
      setValidStartDate(dayjs().add(deltaStart, "days"));
      setPickerValue(dayjs().add(deltaStart, "days"));
    }
    recalculateCost({
      oldIssuanceDate: values?.project?.contract?.issuanceDate,
      cost: get(generalCost, "cost"),
      issuanceDate: oldIssuanceDate,
      setFieldValue,
      setShowChangeCostModal,
      isCancel: true,
    });
  };

  const [pickerValue, setPickerValue] = useState(
    !get(values, "project.contract.issuanceDate") && dayjs()
  );
  const riaValidation = () => {
    const projectRia = get(values, "project.ria");
    const resiliation = get(values, "resiliation");
    const selfOccupation = get(values, "project.fields.selfOccupation");
    const contractNumber = get(
      values,
      "project.terminationRequest.contractNumber"
    );
    const currentCompany = get(
      values,
      "project.terminationRequest.brokerageMandate.currentCompany"
    );
    return (
      (resiliation && !selfOccupation && !projectRia) ||
      (projectRia && (isEmpty(contractNumber) || isEmpty(currentCompany)))
    );
  };

  return (
    <Container size="medium">
      <UpdateCostModal
        showChangeCostModal={showChangeCostModal}
        insurancePlanName={get(subscription, "insurancePlan.name")}
        setShowChangeCostModal={setShowChangeCostModal}
        onChangeCost={onChangeCost}
        onCancelChangeCost={onCancelChangeCost}
      />
      <div className="b2c__area">
        <Stepper
          items={[
            { label: TRANSLATION.STTEPER.SITUATION, completed: true },
            { label: TRANSLATION.STTEPER.QUOTE, completed: true },
            { label: TRANSLATION.STTEPER.SUBSCRIPTION, completed: true },
            { label: TRANSLATION.STTEPER.VALIDATION, completed: false },
          ]}
        />

        <div className="b2c__area--header">
          <div className="b2c__area--header__title">
            {TRANSLATION.MORE_INFORMATION}
          </div>
        </div>
        <div className="subscription__section">
          <div className="product__reacp">
            <div className="product__reacp--top">
              <div className="product__reacp--top--offer">
                <div className="content">
                  <span className="label">
                    {get(subscription, "insurancePlan.name")}
                  </span>
                  <span className="price__tag">
                    <span>{get(generalCost, "cost")} €</span>
                    {TRANSLATION.PRODUCTS.PER_MONTH}
                  </span>
                </div>

                <Button className="danger--link" onClick={onProductEdit}>
                  {TRANSLATION.CHANGE_PRODUCT}
                </Button>
              </div>
              <div className="product__reacp--top--infos">
                <div className="item">
                  <label>{TRANSLATION.ADHERENT_INFO.BLOCK_TITLE} :</label>
                  <p>
                    {`${get(
                      subscription,
                      "project.contact.user.firstname"
                    )} ${get(subscription, "project.contact.user.lastname")}`}
                  </p>
                  <Button
                    className="danger--link"
                    onClick={onPersonalInformationEdit}
                  >
                    {TRANSLATION.EDIT}
                  </Button>
                </div>
                <div className="item">
                  <label>{TRANSLATION.ADHERENT_INFO.REGIME} :</label>
                  <p>
                    {
                      TRANSLATION.MANDATORY_SCHEME.OPTIONS[
                        get(subscription, "project.contact.socialRegime")
                      ]
                    }
                  </p>
                  <Button className="danger--link" onClick={onSocialRegimeEdit}>
                    {TRANSLATION.EDIT}
                  </Button>
                </div>
              </div>
            </div>
            <div className="product__reacp--bottom">
              <div className="product__reacp--bottom__left">
                <span>{TRANSLATION.CURRENT_SITUATION.TITLE}</span>
                <p>{TRANSLATION.CURRENT_SITUATION.DESCRIPTION}</p>
              </div>
              <div className="product__reacp--bottom__right">
                <div className="d-flex">
                  <Button
                    type={classNames({
                      primary: get(values, "resiliation"),
                      "primary--outlined": !get(values, "resiliation"),
                    })}
                    onClick={() => {
                      if (subscription?.locked) return;
                      setFieldValue("resiliation", true);
                      if (
                        checkExpired({
                          issuanceDate: values?.project?.contract?.issuanceDate,
                        })
                      ) {
                        setValidStartDate(dayjs().add(deltaRia, "days"));
                        recalculateCost({
                          oldIssuanceDate: oldIssuanceDate,
                          cost: get(generalCost, "cost"),
                          issuanceDate: dayjs().add(deltaRia, "days"),
                          setFieldValue,
                          setShowChangeCostModal,
                          isRia: true,
                        });
                        setFieldValue(
                          "project.contract.issuanceDate",
                          dayjs().add(deltaRia, "days")
                        );
                        setPickerValue(dayjs().add(deltaRia, "days"));
                      }
                    }}
                  >
                    {TRANSLATION.YES}
                  </Button>
                  <Button
                    type={classNames({
                      primary: !get(values, "resiliation"),
                      "primary--outlined": get(values, "resiliation"),
                    })}
                    onClick={() => {
                      if (subscription?.locked) return;
                      setFieldValue("resiliation", false);
                      setFieldValue("project.ria", false);
                      setFieldValue("project.fields.selfOccupation", undefined);
                      setFieldValue(
                        "project.contract.issuanceDate",
                        get(initialValues, "project.contract.issuanceDate")
                      );
                      setValidStartDate(dayjs().add(deltaStart, "days"));
                      setPickerValue(dayjs().add(deltaStart, "days"));
                    }}
                  >
                    {TRANSLATION.NO}
                  </Button>
                </div>
              </div>
            </div>
            {get(values, "resiliation") && (
              <div className="product__reacp--ria">
                <span className="product__reacp--ria__title">
                  {TRANSLATION.RIA.TITLE}
                </span>
                <div className="d-flex flex--column align--start">
                  <div className="d-flex">
                    <Input
                      type="checkbox"
                      label={TRANSLATION.RIA.ME.LABEL}
                      checked={get(values, "project.fields.selfOccupation")}
                      {...bindInputProps({
                        name: "project.fields.selfOccupation",
                        values,
                        ...formProps,
                      })}
                      onChange={() => {
                        setFieldValue("project.fields.selfOccupation", true);
                        setFieldValue("project.ria", false);
                        setFieldValue("project.terminationRequest", {
                          contractNumber: null,
                          brokerageMandate: { currentCompany: null },
                        });
                      }}
                      disabled={subscription?.locked}
                    />
                  </div>
                  <div className="d-flex">
                    <Input
                      type="checkbox"
                      label={TRANSLATION.RIA.RADIANCE.LABEL}
                      checked={
                        !get(values, "project.fields.selfOccupation", true)
                      }
                      {...bindInputProps({
                        name: "project.fields.selfOccupation",
                        values,
                        ...formProps,
                      })}
                      onChange={() => {
                        setFieldValue("project.fields.selfOccupation", false);
                        setFieldValue("project.ria", true);
                      }}
                      disabled={subscription?.locked}
                    />
                  </div>
                </div>
              </div>
            )}
            {get(values, "resiliation") && get(values, "project.ria") && (
              <div className="ria__section">
                <span className="ria__section--title">
                  {TRANSLATION.RIA.CURRENT_CONTRACT.TITLE}
                </span>
                <p className="ria__section--description">
                  {TRANSLATION.RIA.CURRENT_CONTRACT.DESCRIPTION}
                </p>
                <Row gutter={[16, 16]}>
                  <Col lg={24} xs={24}>
                    <Input
                      label={TRANSLATION.CURRENT_CONTRACT.LABEL}
                      {...bindInputProps({
                        name: `project.terminationRequest.contractNumber`,
                        values,
                        ...formProps,
                      })}
                      disabled={subscription?.locked}
                    />
                  </Col>
                  <Col lg={24} xs={24}>
                    <Input
                      label={TRANSLATION.CURRENT_COMPANY.LABEL}
                      {...bindInputProps({
                        name: `project.terminationRequest.brokerageMandate.currentCompany`,
                        values,
                        ...formProps,
                      })}
                      disabled={subscription?.locked}
                    />
                  </Col>
                </Row>
                <div className="ria__section--caption">
                  {TRANSLATION.RIA.CAPTION.TEXT}
                </div>
                <div className="ria__section__caption">
                  <span>{TRANSLATION.RIA.CAPTION.TITLE}</span>
                  <p>{TRANSLATION.RIA.CAPTION.DESCRIPTION}</p>
                </div>
              </div>
            )}
            <div className="product__reacp--bottom">
              <div className="product__reacp--bottom__left">
                <p>{TRANSLATION.IS_RETIRED}</p>
              </div>
              <div className="product__reacp--bottom__right">
                <div className="d-flex">
                  <Button
                    type={classNames({
                      primary:
                        get(
                          values,
                          "project.contact.socioProfessionalCategory"
                        ) === SOCIO_PROFESSIONAL_CATEGORIES.RETIREES,
                      "primary--outlined":
                        get(
                          values,
                          "project.contact.socioProfessionalCategory"
                        ) !== SOCIO_PROFESSIONAL_CATEGORIES.RETIREES,
                    })}
                    onClick={() => {
                      if (subscription?.locked) return;
                      setFieldValue(
                        "project.contact.socioProfessionalCategory",
                        SOCIO_PROFESSIONAL_CATEGORIES.RETIREES
                      );
                    }}
                  >
                    {TRANSLATION.YES}
                  </Button>
                  <Button
                    type={classNames({
                      primary:
                        get(
                          values,
                          "project.contact.socioProfessionalCategory"
                        ) === SOCIO_PROFESSIONAL_CATEGORIES.OTHER,
                      "primary--outlined":
                        get(
                          values,
                          "project.contact.socioProfessionalCategory"
                        ) !== SOCIO_PROFESSIONAL_CATEGORIES.OTHER,
                    })}
                    onClick={() => {
                      if (subscription?.locked) return;
                      setFieldValue(
                        "project.contact.socioProfessionalCategory",
                        SOCIO_PROFESSIONAL_CATEGORIES.OTHER
                      );
                    }}
                  >
                    {TRANSLATION.NO}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              type="date"
              variant="simple"
              label={TRANSLATION.EFFECTIVE_DATE.LABEL}
              defaultPickerValue={pickerValue}
              placeholder={TRANSLATION.EFFECTIVE_DATE.PLACEHOLDER}
              disabledDate={(current) => {
                return (
                  current &&
                  (current < validStartDate || current > validEndDate)
                );
              }}
              {...bindDateInputProps({
                name: "project.contract.issuanceDate",
                values,
                ...formProps,
                setFieldValue,
              })}
              format={DATE_FORMAT}
              onFocus={() =>
                setOldIssuanceDate(values?.project?.contract?.issuanceDate)
              }
              onChange={(date) => {
                setFieldValue("project.contract.issuanceDate", date);
                if (
                  date.isValid() &&
                  date >= validStartDate &&
                  date <= validEndDate
                ) {
                  recalculateCost({
                    oldIssuanceDate: oldIssuanceDate,
                    cost: get(generalCost, "cost"),
                    issuanceDate: date,
                    setFieldValue,
                    setShowChangeCostModal,
                  });
                }
              }}
              onBlur={({ target: { value: issuanceDate } }) => {
                const date = dayjs(issuanceDate, DATE_FORMAT).add(2, "h");
                if (
                  date.isValid() &&
                  date >= validStartDate &&
                  date <= validEndDate
                ) {
                  setFieldValue("project.contract.issuanceDate", date);
                } else
                  formProps.setFieldTouched("project.contract.issuanceDate");
              }}
              disabled={subscription?.locked}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.ADDRESS.LABEL}
              placeholder={TRANSLATION.ADDRESS.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                values,
                ...formProps,
              })}
              disabled={subscription?.locked}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.COMPLEMENT.LABEL}
              placeholder={TRANSLATION.COMPLEMENT.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`,
                values,
                ...formProps,
              })}
              disabled={subscription?.locked}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Select
              options={cities}
              label={TRANSLATION.CITY.LABEL}
              placeholder={TRANSLATION.CITY.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`,
                values,
                ...formProps,
              })}
              showSearch
              onChange={(value) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`,
                  value
                );
              }}
              disabled={subscription?.locked}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Select
              options={TRANSLATION.NATIONALITIES.map((name) => ({
                label: name,
                value: name,
              }))}
              label={TRANSLATION.NATIONALITY.LABEL}
              placeholder={TRANSLATION.NATIONALITY.PLACEHOLDER}
              {...bindInputProps({
                name: `project.contact.nationality`,
                values,
                ...formProps,
              })}
              showSearch
              disabled={subscription?.locked}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Select
              options={FRANCE_DEPARTMENTS.map(({ code, name }) => ({
                label: `${code && `(${code})`} ${name}`,
                value: name,
              }))}
              label={TRANSLATION.DEPARTMENT_OF_BIRTH.LABEL}
              placeholder={TRANSLATION.DEPARTMENT_OF_BIRTH.LABEL}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`,
                values,
                ...formProps,
              })}
              showSearch
              disabled={subscription?.locked}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Select
              label={TRANSLATION.BIRTH_COUNTRY.LABEL}
              placeholder={TRANSLATION.BIRTH_COUNTRY.PLACEHOLDER}
              options={TRANSLATION.COUNTRIES.map((name) => ({
                label: name,
                value: name,
              }))}
              showSearch
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`,
                values,
                ...formProps,
              })}
              disabled={subscription?.locked}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              inputMode="numeric"
              label={TRANSLATION.SOCIAL_SECURITY_NUMBER.LABEL}
              placeholder={TRANSLATION.SOCIAL_SECURITY_NUMBER.PLACEHOLDER}
              maxLength={13}
              {...bindInputProps({
                name: "project.contact.socialSecurityNumber",
                values,
                ...formProps,
                onBlur: (value) => {
                  setFieldValue(
                    `project.contact.socialSecurityNumber`,
                    value?.toString()
                  );
                },
              })}
              disabled={subscription?.locked}
            />
          </Col>
        </Row>
        {(get(values, "spouse") || get(values, "children", []).length > 0) && (
          <span className="subscription__informations--title">
            {TRANSLATION.RELATED_PERSONS}
          </span>
        )}
        {get(values, "spouse") && (
          <div className="subscription__informations--children">
            <span className="subscription__informations--children_title">
              {TRANSLATION.SPOUSE}
            </span>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.LAST_NAME.LABEL}
                  placeholder={TRANSLATION.LAST_NAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: `spouse.identity.user.lastname`,
                    values,
                    ...formProps,
                  })}
                  disabled={subscription?.locked}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.FIRST_NAME.LABEL}
                  placeholder={TRANSLATION.FIRST_NAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: `spouse.identity.user.firstname`,
                    values,
                    ...formProps,
                  })}
                  disabled={subscription?.locked}
                />
              </Col>
              <Col lg={24} xs={24}>
                <Input
                  inputMode="numeric"
                  label={TRANSLATION.SOCIAL_SECURITY_NUMBER.LABEL}
                  placeholder={TRANSLATION.SOCIAL_SECURITY_NUMBER.PLACEHOLDER}
                  maxLength={13}
                  {...bindInputProps({
                    name: "spouse.identity.socialSecurityNumber",
                    values,
                    ...formProps,
                    onBlur: (value) => {
                      setFieldValue(
                        `spouse.identity.socialSecurityNumber`,
                        value?.toString()
                      );
                    },
                  })}
                  disabled={subscription?.locked}
                />
              </Col>
            </Row>
          </div>
        )}
        {get(values, "children", []).length > 0 && (
          <div className="subscription__informations--children">
            <span className="subscription__informations--children_title">
              {TRANSLATION.CHILDREN.LABEL}
            </span>
            {get(values, "children", []).map((_, i) => (
              <div
                key={`child_${i}`}
                className="subscription__informations--children__card"
              >
                <Row gutter={[16, 16]}>
                  <Col lg={24} xs={24}>
                    <span>
                      {TRANSLATION.CHILDREN.CHILD.TITLE} {i + 1}
                    </span>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Input
                      label={TRANSLATION.LAST_NAME.LABEL}
                      placeholder={TRANSLATION.LAST_NAME.PLACEHOLDER}
                      {...bindInputProps({
                        name: `children.${i}.identity.user.lastname`,
                        values,
                        ...formProps,
                      })}
                      disabled={subscription?.locked}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Input
                      label={TRANSLATION.FIRST_NAME.LABEL}
                      placeholder={TRANSLATION.FIRST_NAME.PLACEHOLDER}
                      {...bindInputProps({
                        name: `children.${i}.identity.user.firstname`,
                        values,
                        ...formProps,
                      })}
                      disabled={subscription?.locked}
                    />
                  </Col>
                  <Col
                    lg={24}
                    xs={24}
                    className={classNames({
                      hidden:
                        moment()
                          .startOf("year")
                          .diff(
                            moment(
                              get(
                                values,
                                `children.${i}.identity.user.birthDate`
                              )
                            ).startOf("year"),
                            "years"
                          ) < 16,
                    })}
                  >
                    <Input
                      inputMode="numeric"
                      label={TRANSLATION.SOCIAL_SECURITY_NUMBER.LABEL}
                      placeholder={
                        TRANSLATION.SOCIAL_SECURITY_NUMBER.PLACEHOLDER
                      }
                      maxLength={13}
                      {...bindInputProps({
                        name: `children.${i}.identity.socialSecurityNumber`,
                        values,
                        ...formProps,
                        onBlur: (value) => {
                          setFieldValue(
                            `children.${i}.identity.socialSecurityNumber`,
                            value?.toString()
                          );
                        },
                      })}
                      disabled={subscription?.locked}
                    />
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        )}
        <div className="b2c__area--actions justify--end">
          <Button
            shape={true}
            className="b2c__area--actions__next"
            onClick={handleSubmit}
            disabled={!isEmpty(formProps.errors) || riaValidation()}
          >
            {TRANSLATION.NEXT}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default View;
