import { Col, Row } from "antd";
import dayjs from "dayjs";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOutlineCake, MdOutlineCall, MdOutlineFreeCancellation } from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import {
  ANSET_SUBSCRIPTION_FORM_KEYS,
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
} from "utils/constants";

const Terminal = ({ setPercent, isFieldRequired, initialValues, errors }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const BLOCK = t("ANSET_SUBSCRIPTION.ADDITIONAL_INFORMATION", {
    returnObjects: true,
  });
  return (
    <div
      className="subscription-form--wrapper"
      id={`${ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINAL}-section`}
    >
      <Form
        type="vertical"
        title={BLOCK.TERMINAL.TITLE}
        subtitle={BLOCK.TERMINAL.SUBTITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              placeholder={BLOCK.TERMINAL.FIELDS.VINI.PLACEHOLDER}
              label={BLOCK.TERMINAL.FIELDS.VINI.LABEL}
              icon={<MdOutlineCall size={16} />}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
              )}
              errors={
                errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`]
              }
              touched={
                !isEmpty(
                  errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`]
                )
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={BLOCK.TERMINAL.FIELDS.IMEI.PLACEHOLDER}
              label={BLOCK.TERMINAL.FIELDS.IMEI.LABEL}
              icon={<MdOutlineFreeCancellation size={16} />}
              defaultValue={get(initialValues, "project.fields.imei")}
              errors={errors["project.fields.imei"]}
              touched={!isEmpty(errors["project.fields.imei"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.fields.imei", value);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired("project.fields.imei")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={BLOCK.TERMINAL.FIELDS.PURCHASE_DATE.LABEL}
              placeholder={BLOCK.TERMINAL.FIELDS.PURCHASE_DATE.PLACEHOLDER}
              icon={<MdOutlineCake size={16} />}
              type="date"
              variant="simple"
              errors={errors["project.fields.purchaseDate"]}
              defaultValue={dayjs()}
              onChange={(_, date) => {
                set(initialValues, "project.fields.purchaseDate", date);
              }}
              touched={!isEmpty(errors["project.fields.purchaseDate"])}
              onBlur={() => setPercent(initialValues)}
              format={DATE_FORMAT}
              required={isFieldRequired("project.fields.purchaseDate")}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Terminal;
