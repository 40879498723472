import { useMemo } from "react";
import { AiOutlineEuroCircle } from "react-icons/ai";
import {
  MdBusiness,
  MdCallToAction,
  MdLineWeight,
  MdLogout,
  MdMoreHoriz,
  MdOutlineAccountTree,
  MdOutlineAssignment,
  MdOutlineDashboardCustomize,
  MdOutlineGroup,
  MdOutlineHealthAndSafety,
  MdOutlineHome,
  MdOutlineManageAccounts,
  MdOutlinePriceChange,
  MdOutlineSchema,
  MdOutlineSmartphone,
  MdOutlineVolunteerActivism,
} from "react-icons/md";
import { RiFlowChart } from "react-icons/ri";
import { COLORS } from "shared/style/colors";
import { MODULES, SCREENS, SUBMODULES, USER_TYPES } from "utils/constants";

const Siders = ({ t, i18n, navigate, user = {}, language, setLanguage }) => {
  const { adminItems, brokerItems } = useMemo(
    () => ({
      adminItems: [
        {
          key: "dashboard",
          label: t("DASHBOARD"),
          [MODULES.DASHBOARD]: {
            key: SCREENS.PRIVATE.ADMIN.DASHBOARD.path,
            icon: <MdOutlineHome size={20} key="dashboard" />,
            label: t("DASHBOARD_PROCESS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
        },
        {
          key: "products",
          label: t("PRODUCTS"),
          [MODULES.INSURANCE_PLAN]: {
            key: SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path,
            icon: <MdOutlineVolunteerActivism size={20} key="products" />,
            label: t("INSURANCE_PLANS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.OFFER]: {
            key: SCREENS.PRIVATE.ADMIN.OFFERS.LIST.path,
            icon: <MdBusiness size={20} key="offers" />,
            label: t("OFFERS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.COVERAGE]: {
            key: SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path,
            icon: <MdOutlineHealthAndSafety size={20} key="coverages" />,
            label: t("COVERAGES"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.PRICER]: {
            key: SCREENS.PRIVATE.ADMIN.PRICERS.LIST.path,
            icon: <MdOutlinePriceChange size={20} />,
            label: t("PRICERS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          // [MODULES.COMMISSION]: {
          //   key: SCREENS.PRIVATE.ADMIN.COMMISSIONS.LIST.path,
          //   icon: <MdPercent size={20} />,
          //   label: t("COMMISSIONS"),
          //   screen: SUBMODULES.ADMIN_LIST,
          // },
          [MODULES.COVERAGE_BENEFIT]: {
            key: SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.LIST.path,
            icon: <MdLineWeight size={20} />,
            label: t("COVERAGE_CATEGORIES"),
            screen: SUBMODULES.ADMIN_LIST,
          },
        },
        {
          key: "sales",
          label: t("SALES"),
          [MODULES.PROJECT]: {
            key: SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path,
            icon: <MdBusiness key="projects" size={20} />,
            label: t("PROJECTS"),
            screen: SUBMODULES.BROKER_LIST,
          },
          [MODULES.CONTRACT]: {
            key: SCREENS.PRIVATE.BROKER.CONTRACTS.LIST.path,
            icon: <MdOutlineAssignment size={20} key="contracts" />,
            label: t("CONTRACTS"),
            screen: SUBMODULES.BROKER_LIST,
          },
        },
        {
          key: "distribution",
          label: t("DISTRIBUTION"),
          [MODULES.PROCESS]: {
            key: SCREENS.PRIVATE.ADMIN.PROCESSES.LIST.path,
            icon: <MdMoreHoriz size={20} />,
            label: t("PROCESSES"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.ORGANIZATION]: {
            key: SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.LIST.path,
            icon: <MdBusiness size={20} />,
            label: t("ORGANIZATIONS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.BROKER]: {
            key: SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path,
            icon: <MdOutlineGroup size={20} />,
            label: t("BROKERS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.BRAND]: {
            key: SCREENS.PRIVATE.ADMIN.BRANDS.LIST.path,
            icon: <MdOutlineSmartphone size={20} />,
            label: t("BRANDS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          // [MODULES.COMMISSION_STATEMENT]: {
          //   key: SCREENS.PRIVATE.ADMIN.COMMISSION_STATEMENTS.LIST.path,
          //   icon: <AiOutlineEuroCircle size={20} />,
          //   label: t("COMMISSION_STATEMENTS"),
          //   screen: SUBMODULES.ADMIN_LIST,
          // },
          [MODULES.WORKFLOW]: {
            key: SCREENS.PRIVATE.ADMIN.WORKFLOWS.LIST.path,
            icon: <RiFlowChart size={20} />,
            label: t("WORKFLOWS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.TEMPLATE]: {
            key: SCREENS.PRIVATE.ADMIN.TEMPLATES.LIST.path,
            icon: <MdOutlineDashboardCustomize size={20} />,
            label: t("Templates"),
            screen: SUBMODULES.ADMIN_LIST,
          },
        },
        {
          key: "digital",
          label: t("DIGITAL_ACQUISITION"),
          [MODULES.SOURCE]: {
            key: SCREENS.PRIVATE.ADMIN.SOURCES.LIST.path,
            icon: <MdOutlineAccountTree size={20} />,
            label: t("SOURCES"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.TRACER]: {
            key: SCREENS.PRIVATE.ADMIN.TRACERS.LIST.path,
            icon: <MdOutlineAssignment size={20} />,
            label: t("TRACERS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.FLOW]: {
            key: SCREENS.PRIVATE.ADMIN.FLOWS.LIST.path,
            icon: <MdOutlineSchema size={20} />,
            label: t("FLOWS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
          [MODULES.QUALIFIER]: {
            key: SCREENS.PRIVATE.ADMIN.QUALIFIERS.LIST.path,
            icon: <MdCallToAction size={20} />,
            label: t("QUALIFIERS"),
            screen: SUBMODULES.ADMIN_LIST,
          },
        },
      ].map(({ key, label, ...modules }) => ({
        key: key,
        label: label,
        children: Object.entries(modules)
          .filter(([module, { screen }]) =>
            user?.permissions?.find(
              ({ module: permissionModule, granted, submodules }) =>
                granted &&
                module === permissionModule &&
                submodules?.find(
                  ({ name, granted }) => granted && name === screen
                )
            )
          )
          .map(([_, item]) => item),
      })),
      brokerItems: Object.entries({
        [MODULES.DASHBOARD]: {
          key: SCREENS.PRIVATE.BROKER.DASHBOARD.path,
          icon: <MdOutlineHome key="dashboard" size={20} />,
          label: t("DASHBOARD"),
          screen: SUBMODULES.BROKER_LIST,
        },
        [MODULES.PROJECT]: {
          key: SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path,
          icon: <MdBusiness key="projects" size={20} />,
          label: t("PROJECTS"),
          screen: SUBMODULES.BROKER_LIST,
        },
        [MODULES.CONTRACT]: {
          key: SCREENS.PRIVATE.BROKER.CONTRACTS.LIST.path,
          icon: <MdOutlineAssignment size={20} key="contracts" />,
          label: t("CONTRACTS"),
          screen: SUBMODULES.BROKER_LIST,
        },
        [MODULES.COMMISSION_STATEMENT]: {
          key: SCREENS.PRIVATE.BROKER.COMMISSION_STATEMENTS.LIST.path,
          icon: <AiOutlineEuroCircle size={20} />,
          label: t("COMMISSION_STATEMENTS"),
          screen: SUBMODULES.BROKER_LIST,
        },
      })
        .filter(([module, { screen }]) =>
          user?.permissions?.find(
            ({ module: permissionModule, granted, submodules }) =>
              granted &&
              module === permissionModule &&
              submodules?.find(
                ({ name, granted }) => granted && name === screen
              )
          )
        )
        .map(([_, item]) => item),
    }),
    [t, user?.permissions]
  );
  const USERS_MENU = {
    [USER_TYPES.ADMIN.type]: adminItems,
    [USER_TYPES.INSURANCE_ADMIN.type]: adminItems,
    [USER_TYPES.BROKER_ADMIN.type]: brokerItems,
    [USER_TYPES.BROKER_DASHBOARD.type]: brokerItems,
    [USER_TYPES.BROKER_EMPLOYEE.type]: brokerItems,
    [USER_TYPES.BROKER_REPRESENTATIVE.type]: brokerItems,
    [USER_TYPES.INSURANCE_REPRESENTATIVE.type]: brokerItems,
    [USER_TYPES.INTERNAL_ADVISOR.type]: brokerItems,
    [USER_TYPES.INTERNAL_SUPERVISOR.type]: brokerItems,
  };
  if (!user) return;
  return {
    menu: USERS_MENU[user?.meta?.type],
    actions: [
      ...(user?.permissions?.find(
        ({ module, granted, submodules }) =>
          granted &&
          module === MODULES.USER &&
          submodules?.find(
            ({ name, granted }) => granted && name === SUBMODULES.BROKER_LIST
          )
      )
        ? [
            {
              key: "management",
              label: t("USER_MANAGEMENT"),
              icon: <MdOutlineManageAccounts size={16} />,
              onClick: () =>
                navigate(SCREENS.PRIVATE.BROKER.COLLABORATORS.LIST.path),
            },
          ]
        : []),
      {
        onClick: () => {
          const language = i18n.languages.find(
            (lang) => lang !== i18n.language
          );
          i18n.changeLanguage(language);
          setLanguage({ language });
        },
        label: t("CHANGE_LANGUAGE"),
        key: "language",
      },
      {
        onClick: () => navigate(SCREENS.AUTH.LOGOUT.path),
        label: t("LOGOUT"),
        icon: <MdLogout size={16} color={COLORS.C_DANGER} />,
        key: "logout",
      },
    ],
  };
};

export default Siders;
