import cuid from "cuid";
import { get } from "lodash";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { CREATE_DISTRIBUTION } from "utils/api/graphql/mutations/distributions";
import { INITIAL_VALUES, INSURANCE_PLAN_RISKS, SCREENS } from "utils/constants";
import View from "./View";

const CreateTerminal = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const { user } = useContext(StoreContext);

  const [addDistribution] = useMutation(CREATE_DISTRIBUTION);

  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);

  const [addInsurancePlan] = useMutation(
    graphql.mutations.CREATE_INSURANCE_PLAN,
    {
      refetchQueries: [
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
      ],
      onCompleted: () => {
        addDistribution({
          variables: {
            data: {
              id: cuid(),
              insurancePlan: { id },
              broker: { id: get(user, "organization.broker.id") },
            },
          },
        });
        navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);
      },
      onError: () => {},
    }
  );

  const onSubmit = (values) => {
    const { date, rules, iconAttachment, icon, ...rest } = values;

    addInsurancePlan({
      variables: {
        data: {
          ...rest,
          id,
          risk: INSURANCE_PLAN_RISKS.TERMINAL,
          identifier: cuid(),
          startDate: get(date, "0"),
          endDate: get(date, "1"),
          icon: iconAttachment,
          ...(rules?.length > 0 && {
            params: [{ id: cuid(), rules }],
          }),
        },
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default CreateTerminal;
