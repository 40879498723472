import { gql } from "@apollo/client";

export const USER_ACTIVITY = gql`
  query UserActivity(
    $where: UserActivityWhereInput
    $like: UserActivityWhereInput
    $take: Int
    $skip: Int
    $isIn: JSONObject
    $raw: [String]
  ) {
    userActivities(where: $where, take: $take, skip: $skip, like: $like, raw :$raw isIn: $isIn) {
      data {
        id
        module
        action
        status
        critical
        createdDate
        data
        user {
          id
          firstname
          lastname
        }
        process {
          id
        }
      }
      count
    }
  }
`;

const userActivity = { USER_ACTIVITY };

export default userActivity;
