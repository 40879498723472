import cuid from "cuid";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
} from "utils/api/graphql/mutations/attachment";
import { CREATE_OFFER, DELETE_OFFER } from "utils/api/graphql/mutations/offers";
import { ATTACHMENTS } from "utils/api/graphql/queries/attachments";
import { OFFERS } from "utils/api/graphql/queries/offers";
import {
  INITIAL_VALUES,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
  INSURANCE_PLAN_RISKS,
  SCREENS,
} from "utils/constants";
import View from "./View";

const EditTerminal = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const refetchAttachments = [
    {
      query: graphql.queries.ATTACHMENTS,
      variables: {
        where: {
          insurancePlans: { id },
        },
        isIn: { type: Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES) },
      },
    },
  ];
  const [addAttachment] = useMutation(CREATE_ATTACHMENT, {
    refetchQueries: refetchAttachments,
  });
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
    refetchQueries: refetchAttachments,
  });

  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);

  const [updateInsurancePlan] = useMutation(
    graphql.mutations.UPDATE_INSURANCE_PLAN,
    {
      refetchQueries: [
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
        },
        {
          query: graphql.queries.INSURANCE_PLAN,
          awaitRefetchQueries: true,
          variables: { where: { id } },
        },
      ],
      onCompleted: () =>
        navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path),
    }
  );
  const { data: attachmentsData, loading: attachmentsLoading } = useQuery(
    ATTACHMENTS,
    {
      variables: {
        where: {
          insurancePlans: { id },
        },
        isIn: { type: Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES) },
      },
    }
  );

  const { data, loading, error } = useQuery(graphql.queries.INSURANCE_PLAN, {
    variables: { where: { id } },
  });

  const [getOffers, { data: offers, loading: offersLoading }] =
    useLazyQuery(OFFERS);
  const { refetch, loading: mainOffersLoading } = useQuery(OFFERS, {
    variables: {
      where: {
        AND: [
          {
            offerInsurancePlans: {
              insurancePlan: {
                id,
              },
            },
          },
          {
            offerInsurancePlans: {
              main: true,
            },
          },
        ],
      },
    },
    onCompleted: ({ offers }) =>
      getOffers({
        variables: {
          where: {
            offerInsurancePlans: {
              main: false,
            },
          },
          isIn: { id: get(offers, "data", []).map(({ id }) => id) },
        },
      }),
  });

  const [addOffer] = useMutation(CREATE_OFFER, {
    onCompleted: () => refetch(),
  });

  const [deleteOffer] = useMutation(DELETE_OFFER, {
    onCompleted: () => refetch(),
  });

  const onSubmit = (values) => {
    const {
      date,
      rules,
      commissions,
      iconAttachment,
      attachments,
      newAttachments,
      offers = [],
      initialOffers = [],
      ...rest
    } = values;
    const offerIds = offers.map((offer) => offer.id);
    const offersToDelete = initialOffers.filter(
      (initialOffer) => !offerIds.includes(initialOffer)
    );
    offersToDelete.forEach((offer) => {
      deleteOffer({
        variables: {
          where: { id: offer },
        },
      });
    });
    offers.forEach((offer) => {
      addOffer({
        variables: {
          data: {
            ...offer,
            offerInsurancePlans: [
              ...get(offer, "offerInsurancePlans", []),
              { id: cuid(), main: true, insurancePlan: { id } },
            ],
          },
        },
      });
    });

    updateInsurancePlan({
      variables: {
        where: { id },
        data: {
          ...rest,
          id,
          risk: INSURANCE_PLAN_RISKS.TERMINAL,
          identifier: cuid(),
          startDate: get(date, "0") || null,
          endDate: get(date, "1") || null,
          icon: iconAttachment,
          params: [{ id: cuid(), rules }],
        },
      },
    });
  };
  if (loading || attachmentsLoading || offersLoading || mainOffersLoading)
    return <Loading />;
  if (error) return "Error occurred";

  return (
    <View
      onSubmit={onSubmit}
      id={id}
      cancel={cancel}
      data={omitDeep(data?.insurancePlan, "__typename")}
      addAttachment={addAttachment}
      deleteAttachment={deleteAttachment}
      attachments={omitDeep(
        get(attachmentsData, "attachments.data", []),
        "__typename"
      )}
      offers={omitDeep(get(offers, "offers.data", []), "__typename")}
    />
  );
};

export default EditTerminal;
