import { Col, Row } from "antd";
import { get, isEmpty, set } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAddRoad,
  MdOutlineAlternateEmail,
  MdOutlineMap,
  MdOutlinePinDrop,
  MdOutlineSouthAmerica,
} from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { useLazyQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { FRANCE_ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import { CONTACT_POINT_SYSTEM, SUBSCRIPTION_FORM_KEYS } from "utils/constants";

const Contact = ({
  formProps,
  values,
  setPercent,
  setFieldValue,
  isFieldRequired,
  initialValues,
  errors,
}) => {
  const [cities, setCities] = useState([]);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("ANSET_SUBSCRIPTION", { returnObjects: true });
  const [addressZipCodeCity] = useLazyQuery(FRANCE_ADDRESS_ZIP_CODE_CITY);
  useEffect(() => {
    addressZipCodeCity({
      variables: {
        where: {
          zipCode: get(
            initialValues,
            `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
          ),
        },
      },
      onCompleted: ({ franceAddressZipCodeCity: { response } }) => {
        setCities(response);
      },
    });
  }, [
    get(
      initialValues,
      `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
    ),
  ]);

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.TITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.POSTAL_CODE.LABEL}
              icon={<MdOutlinePinDrop size={16} />}
              placeholder={SUBSCRIPTION.FORM.POSTAL_CODE.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
              )}
              errors={
                errors[
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                ]
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                  value
                );
              }}
              touched={
                !isEmpty(
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                  ]
                )
              }
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.ADDRESS.LABEL}
              icon={<MdOutlineAddRoad size={16} />}
              placeholder={SUBSCRIPTION.FORM.ADDRESS.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
              )}
              errors={
                errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`]
              }
              touched={
                !isEmpty(
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                  ]
                )
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Select
              icon={<MdOutlineSouthAmerica size={16} />}
              label={SUBSCRIPTION.FORM.CITY.LABEL}
              placeholder={SUBSCRIPTION.FORM.CITY.PLACEHOLDER}
              showSearch
              options={cities}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
              )}
              errors={
                errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`]
              }
              touched={
                !isEmpty(
                  errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`]
                )
              }
              onChange={(value) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.DEPARTMENT_OF_BIRTH.LABEL}
              icon={<MdOutlineAddRoad size={16} />}
              placeholder={SUBSCRIPTION.FORM.DEPARTMENT_OF_BIRTH.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`
              )}
              errors={
                errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`]
              }
              touched={
                !isEmpty(
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`
                  ]
                )
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Select
              icon={<MdOutlineMap size={16} />}
              label={SUBSCRIPTION.FORM.BIRTH_COUNTRY.LABEL}
              placeholder={SUBSCRIPTION.FORM.BIRTH_COUNTRY.PLACEHOLDER}
              showSearch
              options={Object.entries(SUBSCRIPTION.FORM.COUNTRIES).map(
                ([value, label]) => ({ value, label })
              )}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`
              )}
              errors={
                errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`]
              }
              touched={
                !isEmpty(
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`
                  ]
                )
              }
              onChange={(value) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`
              )}
            />
          </Col>
          {/* {get(
            initialValues,
            `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
          ) !== "FRANCE" && (
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.COUNTRY.LABEL}
                icon={<MdOutlineMap size={16} />}
                placeholder={SUBSCRIPTION.FORM.COUNTRY.PLACEHOLDER}
                defaultValue={get(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
                )}
                errors={
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
                  ]
                }
                touched={
                  !isEmpty(
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
                    ]
                  )
                }
                onChange={({ target: { value } }) => {
                  set(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`,
                    value
                  );
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired(
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
                )}
              />
            </Col>
          )} */}
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.EMAIL.LABEL}
              icon={<MdOutlineAlternateEmail size={16} />}
              placeholder={SUBSCRIPTION.FORM.EMAIL.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
              )}
              errors={
                errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`]
              }
              touched={
                !isEmpty(
                  errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`]
                )
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
              )}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Contact;
