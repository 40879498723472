import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Toogle = ({ children, title, actions, toggle  }) => {
  const [isBodyVisible, setIsBodyVisible] = useState(toggle);

  const toggleBodyVisibility = () => {
    setIsBodyVisible((prev) => !prev); 
  };

  return (
    <div className="card__toggle">
        <div className="card__toggle--head">
          <div className="card__toggle--content">
            <h3 className="--title">{title}</h3>
            <span className="--actions">{actions}</span>
          </div>
          <span className="card__toggle--icon" onClick={toggleBodyVisibility} style={{ cursor: "pointer" }}>
            {isBodyVisible ?  <MdKeyboardArrowUp size={24} /> :  <MdKeyboardArrowDown size={24} />}
          </span>
        </div>
      {isBodyVisible && <div className="card__toggle--body">{children}</div>}
    </div>
  );
};

export default Toogle;
