import { Table } from "antd";
import { get } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IoDuplicateOutline } from "react-icons/io5";
import { MdDeleteOutline, MdOpenInNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/components/Pagination";
import Status from "shared/components/Status";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { DATE_FORMAT, INSURANCE_PLAN_RISKS, SCREENS } from "utils/constants";

function List({ data, count, setValues, skip, duplicate }) {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.PRICER);
  const LIST = t("LIST", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });


  const columns = [
    {
      title: LIST.NAME,
      dataIndex: "name",
    },
    {
      title: LIST.START_DATE,
      dataIndex: "startDate",
      render: (record) => moment(record).format(DATE_FORMAT),
    },
    {
      title: LIST.END_DATE,
      dataIndex: "endDate",
      render: (record) => moment(record).format(DATE_FORMAT),
    },
    {
      title: LIST.INSURANCE_PLAN,
      dataIndex: "insurancePlan",
      render: (record) => get(record, "name", "-"),
    },
    {
      title: "risk",
      dataIndex: "insurancePlan",
      render: (record) => {
        const risk = get(record, "risk", "-");
        const riskText = risk === INSURANCE_PLAN_RISKS.HEALTH 
          ? FORM.HEALTH 
          : risk === INSURANCE_PLAN_RISKS.TERMINAL 
          ? FORM.TERMINAL 
          : "-";
        return (
          <Status
            status={risk === INSURANCE_PLAN_RISKS.HEALTH ? "main" : "default"}
            text={riskText}
          />
        );
      },
    },
    {
      title: LIST.ACTIONS,
      dataIndex: "id",
      align: "right",
      render: (id) => (
        <div className="d-flex justify--end">
          <IoDuplicateOutline
            size={24}
            onClick={() => {
              duplicate({
                variables: {
                  where: { id },
                },
              });
            }}
          />
          <MdOpenInNew
            size={24}
            color={COLORS.C_PRIMARY}
            onClick={() => {
              const risk = get(data.find((item) => item.id === id), "insurancePlan.risk", "-");
              navigate(`${SCREENS.PRIVATE.ADMIN.PRICERS.DETAIL.path}/${id}`, {
                state: { risk },
              });
            }}
          />
          <MdDeleteOutline
            size={24}
            color={COLORS.C_DANGER}
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.PRICERS.DELETE.path}/${id}`)
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        className="table__wrapper"
        columns={columns}
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
      />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
}

export default List;
