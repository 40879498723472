import Card from "antd/es/card/Card";
import Pricers from "entities/Pricers";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Header from "shared/components/Header";
import Search from "shared/components/Input";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { DUPLICATE_PRICER } from "utils/api/graphql/mutations/pricers";
import { PRICERS } from "utils/api/graphql/queries/pricers";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";
import RisksDrawer from "./RisksDrawer";

const List = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.PRICER);
  const LIST = t("LIST", { returnObjects: true });
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);
  const [duplicatePricer] = useMutation(DUPLICATE_PRICER, {
    refetchQueries: [
      {
        query: PRICERS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
  });
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState("");
  const filter = {
    like: isEmpty(search)
      ? undefined
      : {
          OR: [
            { name: `%${search}%` },
            { insurancePlan: { name: `%${search}%` } },
          ],
        },
  };
  const navigate = useNavigate();

  const buttons = [
    {
      icon: <MdAdd size={24} />,
      text: LIST.HEADER.SAVE,
      // onClick: () =>
      //   navigate(SCREENS.PRIVATE.ADMIN.PRICERS.CREATE.path, {
      //     state: { ...filter, ...pagination },
      //   }),
        onClick: () => setOpenCreateDrawer(true)
    },
  ];

  return (
    <>
      <Header title={LIST.HEADER.TITLE} actions={buttons} />
      <Card type="simple">
        <div className="m-bottom-20 grid--4">
          <Search
            placeholder={LIST.SEARCH}
            type="search"
            onChange={({ target: { value } }) => {
              setPagination((prev) => ({ ...prev, skip: 0 }));
              setSearch(value);
            }}
          />
        </div>
        <Pricers
          View={View}
          filter={{ ...filter, ...pagination }}
          setValues={setPagination}
          skip={pagination.skip || 0}
          NoData={View}
          duplicate={duplicatePricer}
        />
         <RisksDrawer
          open={openCreateDrawer}
          onClose={() => setOpenCreateDrawer(false)}
        />
      </Card>
    </>
  );
};

export default List;
