import dayjs from "dayjs";
import { get } from "lodash";
import {
  MdNumbers,
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineCall,
  MdOutlineHealthAndSafety,
  MdOutlineLocationOn,
  MdPercent,
  MdWorkOutline,
} from "react-icons/md";
import { STEPS_KEYS } from "screens/Private/Broker/Clients/Create";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import {
  ACTIVITY_TIME_OPTIONS,
  DATE_FORMAT,
  INCOME_TYPES,
  JOB_LIST,
  SOCIAL_REGIMES,
  SOCIO_PROFESSIONAL_CATEGORIES,
} from "utils/constants";
import { isMajor } from "utils/helpers/date";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
export const step = { ADHERENT: "ADHERENT" };

const Adherent = ({
  index,
  translation,
  values,
  setFieldValue,
  ...formProps
}) => {
  return (
    <div className="contact-stepper" key={step.ADHERENT}>
      <div className="contact-stepper__header">
        <div className="contact-stepper__header--counter">
          <span>{index}</span>
        </div>
        <div className="contact-stepper__header--content">
          <h1 className="contact-stepper__header--content--title">
            <span>
              {translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].TITLE}
            </span>
          </h1>
          <p className="contact-stepper__header--content--description">
            {translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].DESCRIPTION}
          </p>
        </div>
      </div>
      <div className="contact-stepper__content">
        <Input
          label={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.LASTNAME
              .LABEL
          }
          icon={<MdOutlineBadge size={22} />}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.LASTNAME
              .PLACEHOLDER
          }
          {...bindInputProps({
            name: "contact.user.lastname",
            values,
            ...formProps,
          })}
        />
        <Input
          label={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.FIRSTNAME
              .LABEL
          }
          icon={<MdOutlineBadge size={22} />}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.FIRSTNAME
              .PLACEHOLDER
          }
          {...bindInputProps({
            name: "contact.user.firstname",
            values,
            ...formProps,
          })}
        />
        <Input
          icon={<MdOutlineCake size={22} />}
          label={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.BIRTHDAY
              .LABEL
          }
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.BIRTHDAY
              .PLACEHOLDER
          }
          type="date"
          variant="simple"
          format={DATE_FORMAT}
          disabledDate={(current) => current > isMajor}
          defaultPickerValue={
            !get(values, "contact.user.birthDate") &&
            dayjs().subtract(30, "years")
          }
          {...bindDateInputProps({
            name: "contact.user.birthDate",
            values,
            setFieldValue,
            ...formProps,
          })}
        />
        <Input
          icon={<MdOutlineLocationOn size={22} />}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .ZIPCODE.PLACEHOLDER
          }
          label={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .ZIPCODE.LABEL
          }
          {...bindInputProps({
            name: "zipCode.value",
            values,
            ...formProps,
          })}
        />
        <Select
          icon={<MdOutlineHealthAndSafety size={22} />}
          options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
            value,
            label: translation.SOCIAL_REGIMES[key],
          }))}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .SOCIAL_REGIME.PLACEHOLDER
          }
          label={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .SOCIAL_REGIME.LABEL
          }
          {...bindInputProps({
            name: "contact.socialRegime",
            values,
            ...formProps,
          })}
          onChange={(value) => {
            setFieldValue("contact.socialRegime", value);
            if (value !== SOCIAL_REGIMES.REGIME_SALARIES_AGRICOLES) {
              setFieldValue(
                "contact.socioProfessionalCategory",
                SOCIO_PROFESSIONAL_CATEGORIES.OTHER
              );
              setFieldValue("contact.exerciseFrame", null);
            }
          }}
        />
        <Select
          showSearch
          icon={<MdWorkOutline size={16} />}
          options={Object.entries(JOB_LIST).map(([key, value]) => ({
            label:
              translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                .PROFESSION.JOB_LIST[key],
            value,
          }))}
          label={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .PROFESSION.LABEL
          }
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .PROFESSION.PLACEHOLDER
          }
          {...bindInputProps({
            name: "contact.profession",
            values,
            ...formProps,
          })}
          onChange={(value) => {
            setFieldValue("contact.profession", value);
            setFieldValue("contact.fields", {});
          }}
        />
        {!get(values, "contact.profession") ? (
          <></>
        ) : get(values, "contact.profession") ===
          JOB_LIST.HOSPITAL_CIVIL_SERVICE ? (
          <>
            <Input
              type="number"
              icon={<MdNumbers size={16} />}
              placeholder={
                translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                  .CAPPED_INDEX.PLACEHOLDER
              }
              label={
                translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                  .CAPPED_INDEX.LABEL
              }
              {...bindInputProps({
                name: "contact.fields.cappedIndex",
                values,
                ...formProps,
              })}
            />
            <Select
              showSearch
              icon={<MdPercent size={16} />}
              options={ACTIVITY_TIME_OPTIONS.map((value) => ({
                label: `${value}%`,
                value,
              }))}
              label={
                translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                  .ACTIVITY_TIME.LABEL
              }
              placeholder={
                translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                  .ACTIVITY_TIME.PLACEHOLDER
              }
              {...bindInputProps({
                name: "contact.fields.activityTime",
                values,
                ...formProps,
              })}
              onChange={(value) =>
                setFieldValue("contact.fields.activityTime", value)
              }
            />
          </>
        ) : (
          <Input
            type="number"
            icon={<MdNumbers size={16} />}
            placeholder={
              translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                .ANNUAL_INCOME.PLACEHOLDER
            }
            label={
              translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                .ANNUAL_INCOME.LABEL
            }
            {...bindInputProps({
              name: "contact.fields.income",
              values,
              ...formProps,
              onBlur: () => {
                setFieldValue("contact.fields.incomeType", INCOME_TYPES.ANNUAL);
              },
            })}
          />
        )}
        <Input
          icon={<MdOutlineCall size={16} />}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM.PHONE
              .PLACEHOLDER
          }
          label={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM.PHONE
              .LABEL
          }
          {...bindInputProps({
            name: "phoneNumber.value",
            values,
            ...formProps,
          })}
        />
      </div>
    </div>
  );
};
export default Adherent;
