import gql from "graphql-tag";

export const CREATE_ATTACHMENT = gql`
  mutation AddAttachment($data: AttachmentDataInput) {
    addAttachment(data: $data) {
      id
      key
      name
      fileUrl
      contentType
      type
      filename
    }
  }
`;

export const GENERATE_SUBSCRIPTION_DOCUMENTS = gql`
  mutation generateSubscriptionDocuments($data: SubscriptionWhereInput) {
    generateSubscriptionDocuments(data: $data) {
      base64
      attachments {
        id
        type
      }
    }
  }
`;

export const GENERATE_QUOTE_DOCUMENTS = gql`
  mutation GenerateQuoteDocuments($data: GenerateQuoteDocumentsDataInput) {
    generateQuoteDocuments(data: $data) {
      base64
      attachments {
        id
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($where: AttachmentWhereInput) {
    deleteAttachment(where: $where) {
      id
    }
  }
`;

const attachments = {
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
  GENERATE_SUBSCRIPTION_DOCUMENTS,
  GENERATE_QUOTE_DOCUMENTS,
};

export default attachments;
