import { FieldArray, Formik } from "formik";
import omitDeep from "omit-deep-lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { ATTACHMENT_TYPES, INPUT_TYPES_FULL, SCREENS } from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import validation from "./validation";
import { get, isEmpty } from "lodash";
import Switch from "shared/components/Input/Switch";
import { Divider } from "antd";
import { MdDeleteOutline, MdOutlineAdd, MdOutlineContentCopy, MdOutlineFileUpload } from "react-icons/md";
import Button from "shared/components/Button";
import Select from "shared/components/Select";
import { INPUT_TYPES_OPTIONS } from "utils/helpers/array";
import cuid from "cuid";

const Detail = ({ onSubmit, data, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.TEMPLATE);
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  const handleFileUpload = async (file, setFieldValue) => {
    const { base64, size, uid, ...fileData } = file;
    setFieldValue("header.logo", {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue("header.logo", file.base64);
  };


  return (
    <Formik
      initialValues={{
        ...omitDeep(data, "__typename"),
        form: Array.isArray(data.form) ? data.form : [],
        theme: {
          colors: Array.isArray(data?.theme?.colors)
            ? data.theme.colors
            : [{ label: "", value: "" }],
          fonts: Array.isArray(data?.theme?.fonts)
            ? data.theme.fonts
            : [{ value: "" }],
        },
        header: {
          title: data?.header?.title || "",
          logo: data?.header?.logo || null,
          actions: data?.header?.actions || [],
        },
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, setFieldValue, values, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "gray--link",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(
                  `${SCREENS.PRIVATE.ADMIN.TEMPLATES.DELETE.path}/${data.id}`
                ),
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--2">
            <Input
              label={FORM.NAME}
              placeholder={FORM.NAME}
              {...bindInputProps({ name: "name", values, ...formProps })}
            />

            <div className="d-flex">
              <Switch
                label={FORM.ACTIVE}
                className="button__switch"
                checkedChildren={FORM.ACTIVE}
                unCheckedChildren={FORM.INACTIVE}
                defaultChecked={get(values, "active")}
                onChange={(checked) => setFieldValue("active", checked)}
              />
            </div>

          </div>
        </Card>,
        <div key="div-1" className="m-top-24" />,
        <Card key="form-card" type={"toggle"} toggle title={FORM.FORMS}>
          <FieldArray
            name="form"
            render={(arrayHelpers) => (
              <div className="m-top-24">
                {values.form.map((form, index) => (
                  <Card key={`form-${index}`} type={"custom"} styleType="bordered" size="full" className={"m-bottom-16"}>
                    <div className="d-flex justify--end m-bottom-8">
                      <Button
                        type="danger--outlined"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <MdDeleteOutline size={16} />
                      </Button>
                      <Button
                        type="primary--outlined"
                        onClick={() =>
                          arrayHelpers.insert(index + 1, { label: "", name: "", type: "", required: false, defaultValue: "", options: [] })
                        }
                      >
                        <MdOutlineContentCopy size={16} />
                      </Button>
                    </div>
                    <div className="grid--5 align--start ">

                      <Select
                        label={FORM.TYPE_FIELD}
                        options={INPUT_TYPES_OPTIONS}
                        {...bindArrayInputProps({
                          parent: "form",
                          index: index,
                          name: "type",
                          values,
                          ...formProps,
                        })}
                      />

                      <Input
                        label={FORM.LABEL}
                        placeholder={FORM.LABEL}
                        {...bindArrayInputProps({
                          parent: "form",
                          index,
                          name: "label",
                          values,
                          ...formProps,
                        })}
                      />

                      <Input
                        label={FORM.PLACEHOLDER}
                        placeholder={FORM.PLACEHOLDER}
                        {...bindArrayInputProps({
                          parent: "form",
                          index,
                          name: "placeholder",
                          values,
                          ...formProps,
                        })}
                      />

                      <Input
                        label={FORM.NAME}
                        placeholder={FORM.NAME}
                        {...bindArrayInputProps({
                          parent: "form",
                          index,
                          name: "name",
                          values,
                          ...formProps,
                        })}
                      />

                      {(
                        values.form[index].type === INPUT_TYPES_FULL.CHECKBOX ||
                        values.form[index].type === INPUT_TYPES_FULL.SWITCH ||
                        values.form[index].type === INPUT_TYPES_FULL.RADIO.SIMPLE ||
                        values.form[index].type === INPUT_TYPES_FULL.RADIO.GROUP) && (
                          <Switch
                            label={FORM.DEFAULT_VALUE}
                            checkedChildren="Checked"
                            unCheckedChildren="Unchecked"
                            checked={values.form[index].defaultValue}
                            onChange={(checked) =>
                              setFieldValue(`form[${index}].defaultValue`, checked)
                            }
                          />
                        )}

                      {(
                        values.form[index].type === INPUT_TYPES_FULL.SELECT.NORMAL ||
                        values.form[index].type === INPUT_TYPES_FULL.SELECT.ASYNC) && (
                          <FieldArray
                            name={`form[${index}].options`}
                            render={(arrayHelpers) => (
                              <div className="">
                                {(values.form[index]?.options || []).map((option, optIndex) => (
                                  <div key={optIndex} className="grid--1">
                                    <Input
                                      label={FORM.LABEL}
                                      placeholder="Option Label"
                                      value={option.label}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `form[${index}].options[${optIndex}].label`,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <Input
                                      label={FORM.LABEL}
                                      placeholder="Option Value"
                                      value={option.value}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `form[${index}].options[${optIndex}].value`,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <Button
                                      type="danger--outlined"
                                      onClick={() => arrayHelpers.remove(optIndex)}
                                    >
                                      <MdDeleteOutline size={20} />
                                    </Button>
                                  </div>
                                ))}
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    arrayHelpers.push({ label: "", value: "" })
                                  }
                                >
                                  <MdOutlineAdd size={20} />
                                </Button>
                              </div>
                            )}
                          />
                        )}


                      {
                        values.form[index].type !== INPUT_TYPES_FULL.CHECKBOX &&
                        values.form[index].type !== INPUT_TYPES_FULL.SWITCH &&
                        values.form[index].type !== INPUT_TYPES_FULL.RADIO.SIMPLE &&
                        values.form[index].type !== INPUT_TYPES_FULL.RADIO.GROUP &&
                        values.form[index].type !== INPUT_TYPES_FULL.SELECT.NORMAL &&
                        values.form[index].type !== INPUT_TYPES_FULL.SELECT.ASYNC && (
                          <Input
                            label={FORM.DEFAULT_VALUE}
                            placeholder={FORM.DEFAULT_VALUE}
                            {...bindArrayInputProps({
                              parent: "form",
                              index,
                              name: "defaultValue",
                              values,
                              ...formProps,
                            })}
                          />
                        )
                      }

                      <Switch
                        label={FORM.REQUIRE}
                        className="button__switch"
                        checkedChildren={FORM.REQUIRED}
                        unCheckedChildren={FORM.OPTIONAL}
                        defaultChecked={get(values, `form[${index}].required`)}
                        onChange={(checked) => setFieldValue(`form[${index}].required`, checked)}
                      />

                    </div>
                  </Card>
                ))}
                <div className="m-top-16" >
                  <Button
                    type="primary"
                    onClick={() => arrayHelpers.push({ label: "", name: "", type: "", required: false, defaultValue: "", options: [] })}
                  >
                    <MdOutlineAdd size={24} />
                    {FORM.ADD_NEW}
                  </Button>
                </div>
              </div>
            )}
          />
        </Card>,
        <div key="div-2" className="m-top-24" />,

        <Card key="colors-card" type={"toggle"} toggle={false} title={FORM.COLOR}>
          <FieldArray
            name="theme.colors"
            render={(arrayHelpers) => (
              <div className="m-top-24">
                {values.theme.colors.map((color, index) => (
                  <div key={`color-${index}`} className="m-bottom-16">
                    <div className="d-flex justify--end m-bottom-8">
                      <Button
                        type="danger--outlined"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <MdDeleteOutline size={24} />
                      </Button>
                      <Button
                        type="primary--outlined"
                        onClick={() =>
                          arrayHelpers.insert(index + 1, { label: "", value: "" })
                        }
                      >
                        <MdOutlineContentCopy size={24} />
                      </Button>
                    </div>
                    <div className="grid--2">
                      <Input
                        label={FORM.LABEL}
                        placeholder={FORM.LABEL}
                        {...bindArrayInputProps({
                          parent: "theme.colors",
                          index,
                          name: "label",
                          values,
                          ...formProps,
                        })}
                      />

                      <Input
                        type={"colorPicker"}
                        label={FORM.COLOR}
                        value={color.value}
                        defaultValue={"#0e93e5"}
                        showText={(color) => color.toHexString()}
                        {...bindArrayInputProps({
                          parent: "theme.colors",
                          index,
                          name: "value",
                          values,
                          ...formProps,
                        })}
                      />
                    </div>
                  </div>
                ))}
                <Divider />
                <div className="p-top-16">
                  <Button
                    type="primary"
                    onClick={() => arrayHelpers.push({ label: "", value: "" })}
                  >
                    <MdOutlineAdd size={24} />
                    {FORM.ADD_NEW}
                  </Button>
                </div>
              </div>
            )}
          />
          <div key="div-1" className="m-top-24" />,
          <Divider />

          <h3>{FORM.FONT}</h3>

          <FieldArray
            name="theme.fonts"
            render={(arrayHelpers) => (
              <div className="m-top-16">
                {values.theme.fonts.map((font, index) => (
                  <div key={`font-${index}`} className="m-bottom-16">
                    <div className="d-flex justify--end m-bottom-8">
                      <Button
                        type="danger--outlined"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <MdDeleteOutline size={24} />
                      </Button>
                      <Button
                        type="primary--outlined"
                        onClick={() => arrayHelpers.insert(index + 1, { value: "" })}
                      >
                        <MdOutlineContentCopy size={24} />
                      </Button>
                    </div>
                    <div className="grid--1">
                      <Input
                        label={FORM.FONT_NAME}
                        placeholder={FORM.FONT_NAME}
                        {...bindArrayInputProps({
                          parent: "theme.fonts",
                          index,
                          name: "value",
                          values,
                          ...formProps,
                        })}
                      />
                    </div>
                  </div>
                ))}
                <Divider />
                <Button
                  type="primary"
                  onClick={() => arrayHelpers.push({ value: "" })}
                >
                  <MdOutlineAdd size={24} />
                  {FORM.ADD_NEW}
                </Button>
              </div>
            )}
          />
        </Card>,
        <div key="div-3" className="m-top-24" />,
        <Card key="header-card" type={"toggle"} toggle={false} title={FORM.HEADER}>
          <div className="grid--2 m-top-24">
            <Input
              label={FORM.HEADER_TITLE}
              placeholder={FORM.HEADER_TITLE}
              {...bindInputProps({
                name: "header.title",
                values,
                ...formProps,
              })}
            />

            <Input
              type="file"
              variant="uploader"
              listType="picture"
              showUploadList={false}
              maxCount={1}
              onUpload={([file]) => handleFileUpload(file, setFieldValue)}
              fileList={
                isEmpty(get(values, "header.logo", [])) ? [] : [get(values, "header.logo")]
              }
            >
              <Button type="primary--link" icon={<MdOutlineFileUpload />}>{FORM.UPLOAD}</Button>
              <div>
                {get(values, "header.logo") && (
                  <img
                    className="icon"
                    alt="icon"
                    src={get(values, "header.logo")}
                  />
                )}
              </div>
            </Input>
          </div>

          <FieldArray
            name="header.actions"
            render={(arrayHelpers) => (
              <div className="m-top-16">
                <Divider />

                {values.header.actions.map((action, index) => (
                  <div key={`action-${index}`} className="m-bottom-16">
                    <div className="d-flex justify--end m-bottom-8">
                      <Button
                        type="danger--outlined"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <MdDeleteOutline size={24} />
                      </Button>
                      <Button
                        type="primary--outlined"
                        onClick={() =>
                          arrayHelpers.insert(index + 1, { label: "", to: "" })
                        }
                      >
                        <MdOutlineContentCopy size={24} />
                      </Button>
                    </div>
                    <div className="grid--2">
                      <Input
                        label={FORM.NAME}
                        placeholder={FORM.NAME}
                        {...bindArrayInputProps({
                          parent: "header.actions",
                          index,
                          name: "label",
                          values,
                          ...formProps,
                        })}
                      />
                      <Input
                        label={FORM.LINK}
                        placeholder={FORM.LINK}
                        {...bindArrayInputProps({
                          parent: "header.actions",
                          index,
                          name: "to",
                          values,
                          ...formProps,
                        })}
                      />
                    </div>
                  </div>
                ))}
                <Divider />
                <div className="p-top-16" >
                  <Button
                    type="primary"
                    onClick={() => arrayHelpers.push({ label: "", to: "" })}
                  >
                    <MdOutlineAdd size={24} />
                    {FORM.ADD_NEW}
                  </Button>
                </div>
              </div>
            )}
          />
        </Card>
      ]}
    </Formik>
  );
};

export default Detail;
