import { Col, Row } from "antd";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOutlineFreeCancellation } from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import {
  ANSET_SUBSCRIPTION_FORM_KEYS
} from "utils/constants";

const Subscriber = ({ setPercent, isFieldRequired, initialValues, errors }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const BLOCK = t("ANSET_SUBSCRIPTION.ADDITIONAL_INFORMATION", {
    returnObjects: true,
  });
  return (
    <div
      className="subscription-form--wrapper"
      id={`${ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.SUBSCRIBER}-section`}
    >
      <Form
        type="vertical"
        title={BLOCK.SUBSCRIBER.TITLE}
        subtitle={BLOCK.SUBSCRIBER.SUBTITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              placeholder={BLOCK.SUBSCRIBER.FIELDS.FIRST_NAME.PLACEHOLDER}
              label={BLOCK.SUBSCRIBER.FIELDS.FIRST_NAME.LABEL}
              icon={<MdOutlineFreeCancellation size={16} />}
              defaultValue={get(initialValues, "additionalInfo.terminalUser.firstName")}
              errors={errors["additionalInfo.terminalUser.firstName"]}
              touched={!isEmpty(errors["additionalInfo.terminalUser.firstName"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "additionalInfo.terminalUser.firstName", value);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired("additionalInfo.terminalUser.firstName")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={BLOCK.SUBSCRIBER.FIELDS.LAST_NAME.PLACEHOLDER}
              label={BLOCK.SUBSCRIBER.FIELDS.LAST_NAME.LABEL}
              icon={<MdOutlineFreeCancellation size={16} />}
              defaultValue={get(initialValues, "additionalInfo.terminalUser.lastName")}
              errors={errors["additionalInfo.terminalUser.lastName"]}
              touched={!isEmpty(errors["additionalInfo.terminalUser.lastName"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "additionalInfo.terminalUser.lastName", value);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired("additionalInfo.terminalUser.lastName")}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Subscriber;
