import { Col, Row } from "antd";
import classNames from "classnames";
import { get, isEmpty, range } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Budget from "shared/assets/images/budget.png";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Slider from "shared/components/Slider";
import { NAME_SPACES } from "shared/locales/constants";

const View = ({ project, next, onBack, data }) => {
  const [needs, setNeeds] = useState(get(project, "fields.needs"));
  const [fields, setFields] = useState({
    ...project.fields,
    budget: {
      ...get(project.fields, "budget", { min: 0, max: 1000 }),
    },
  });
  const levels = range(1, 5);
  const max = 4;
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [budgetError, setBudgetError] = useState(false);

  return (
    <>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.CONTACT.TITLE}
        actions={[]}
      />
      <div className="template-product-filters--container">
        <div className="template-product-filters--wrapper">
          <Card
            title={DETAIL.TEMPLATES.FILTERS.HEADER.TITLE}
            subtitle={DETAIL.TEMPLATES.FILTERS.HEADER.SUBTITLE}
          >
            <div className="levels--container">
              <div className="levels--wrapper">
                <div />
                <div className="levels--wrapper--levels">
                  {levels.map((element) => (
                    <div key={element} className="label">
                      <div className="popover-wrapper">
                        {/* <Popover
                        text={DETAIL.TEMPLATES.FILTERS.HEADER.LEVELS.POPOVER[element]}
                        /> */}
                        {`${DETAIL.TEMPLATES.FILTERS.HEADER.LEVELS.SOLYON[element]}`}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="levels-coverage--container">
                {data?.map(({ icon, label, id, value }) => (
                  <div key={id} className="levels-coverage--wrapper">
                    <div className="coverage-trailing--wrapper">
                      <img className="img" src={icon} alt={label} />
                      <span className="label">{label}</span>
                    </div>
                    <div className="custom-slider--wrapper">
                      <Slider
                        type="dot"
                        items={levels}
                        name={"grp" + label}
                        value={
                          (get(needs, value, 1) > max
                            ? max
                            : get(needs, value, 1)) || 1
                        }
                        onChange={(item) =>
                          setNeeds((prev) => ({
                            ...prev,
                            [value]: item,
                          }))
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>
          <div className="budget--container">
            <Row align="middle" gutter={[16, 16]}>
              <Col lg={8} xs={24}>
                <div className="trailing--wrapper">
                  <img className="img" src={Budget} alt="Budget client" />
                  <div className="text">
                    <span className="main">
                      {DETAIL.TEMPLATES.FILTERS.BUDGET.TITLE}
                    </span>
                    <span className="second">
                      {DETAIL.TEMPLATES.FILTERS.BUDGET.SUBTITLE}
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={16} xs={24}>
                <div className="budget-action--wrapper">
                  <Row gutter={[24, 24]}>
                    <Col lg={24} xs={24}>
                      <div className="custom-slider--wrapper">
                        <Slider
                          type="line"
                          tooltip={{
                            open: true,
                            formatter: (value) => `${value} €`,
                          }}
                          range
                          min={0}
                          max={1000}
                          value={[
                            get(fields, "budget.min"),
                            get(fields, "budget.max"),
                          ]}
                          onChange={([min, max]) =>
                            setFields((fields) => ({
                              ...fields,
                              budget: {
                                min,
                                max,
                              },
                            }))
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={24} xs={24}>
                      <div className="custom-input--wrapper">
                        <Row align="bottom" gutter={[7, 7]}>
                          <Col lg={11} xs={24}>
                            <Input
                              type="number"
                              suffix="€"
                              value={get(fields, "budget.min")}
                              label={DETAIL.TEMPLATES.FILTERS.BUDGET.MIN}
                              min={0}
                              max={get(fields, "budget.max")}
                              onChange={({ target: { value } }) =>
                                value <= 1000 &&
                                setFields((fields) => ({
                                  ...fields,
                                  budget: {
                                    ...fields.budget,
                                    min: Number(value),
                                  },
                                }))
                              }
                              onBlur={({ target: { value } }) => {
                                if (value > get(fields, "budget.max"))
                                  return setBudgetError(true);
                                setBudgetError(false);
                              }}
                              touched
                              errors={budgetError}
                            />
                          </Col>
                          <Col lg={2} xs={24}>
                            <div className="divider-input" />
                          </Col>
                          <Col lg={11} xs={24}>
                            <Input
                              type="number"
                              suffix="€"
                              value={get(fields, "budget.max")}
                              label={DETAIL.TEMPLATES.FILTERS.BUDGET.MAX}
                              max={1000}
                              min={get(fields, "budget.min")}
                              onChange={({ target: { value } }) =>
                                value <= 1000 &&
                                setFields((fields) => ({
                                  ...fields,
                                  budget: {
                                    ...fields.budget,
                                    max: Number(value),
                                  },
                                }))
                              }
                              onBlur={({ target: { value } }) => {
                                if (value < get(fields, "budget.min"))
                                  return setBudgetError(true);
                                setBudgetError(false);
                              }}
                              touched
                              errors={budgetError}
                            />
                          </Col>
                          <span
                            className={classNames("input--errors", {
                              hidden: !budgetError,
                            })}
                          >
                            {DETAIL.TEMPLATES.FILTERS.BUDGET.ERROR}
                          </span>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify--between">
            <Button onClick={onBack} type="secondary--link">
              <MdArrowBackIos size={16} />
              {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.PREVIOUS}
            </Button>

            <Button
              onClick={() => next({ ...fields, needs })}
              type="primary"
              disabled={
                isEmpty(fields) ||
                get(fields, "budget.min") > get(fields, "budget.max") ||
                project?.expired
              }
            >
              {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.SHOW_PRODUCT_LIST}
              <MdArrowForwardIos size={16} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
