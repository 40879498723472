import { get } from "lodash";
import moment from "moment";
import { INSURANCE_PLAN_PARAMS_RULES } from "utils/constants";
import Card from "./Card";

const InsurancePlans = ({
  insurancePlan,
  cost,
  currency,
  translation,
  subscriptions = [],
  onSubscribe,
  issuanceDate,
  isRIA,
  expired: projectExpired,
  locked,
  selectedModules,
  setSelectedModules,
}) => {
  const subscription = subscriptions.find(
    (subscription) => get(subscription, "insurancePlan.id") === insurancePlan.id
  );
  const expired =
    projectExpired ||
    get(insurancePlan, "params", []).some((param) => {
      return (
        moment(issuanceDate)
          .startOf("day")
          .diff(moment().startOf("day"), "days") <
        get(param, "rules", []).find(
          ({ name }) =>
            name ===
            (isRIA
              ? INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA
              : INSURANCE_PLAN_PARAMS_RULES.DELTA_START)
        )?.value
      );
    });

  return (
    <Card
      key={insurancePlan.id}
      insurancePlan={insurancePlan}
      cost={cost}
      currency={currency}
      period={translation.MONTH}
      popover={{
        active: subscription && expired,
        text: translation.POPOVER,
      }}
      action={{
        label: translation.SUBSCRIPTION,
        onClick: () => {
          onSubscribe({
            actionsTemplate: {
              id: subscription?.id,
              status: subscription?.status,
            },
          });
        },
        disabled: !subscription || expired || !locked,
      }}
      selectedModules={selectedModules}
      setSelectedModules={setSelectedModules}
      translation={translation}
    />
  );
};

export default InsurancePlans;
