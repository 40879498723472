import { ANSET_SUBSCRIPTION_FORM_KEYS, PAYMENT_TYPES } from "utils/constants";
import BankAccount from "./BankAccount";
import Id from "./Id";
import ProofOfResidence from "./ProofOfResidence";
import PurchaseInvoice from "./PurchaseInvoice";

const generateProofForm = ({ paymentType }) => ({
  [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.ID]: Id,
  [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.PROOF_OF_RESIDENCE]: ProofOfResidence,
  [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.PURCHASE_INVOICE]: PurchaseInvoice,
  [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.BANK_ACCOUNT]:
    paymentType === PAYMENT_TYPES.DEBIT && BankAccount,
});

export default generateProofForm;
