import { Dropdown } from "antd";
import classnames from "classnames";
import { findKey } from "lodash";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdMenu } from "react-icons/md";
import logo from "shared/assets/images/logo.svg";
import Avatar from "shared/components/Avatar";
import Profile from "shared/components/Profile";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import { USER_TYPES } from "utils/constants";
import Menu from "../Menu";

const Sidebar = ({ menu, actions }) => {
  const { user } = useContext(StoreContext);
  const { t } = useTranslation(NAME_SPACES.HOME);
  const USER_TYPES_TRANSLATION = t("USER_TYPES", { returnObjects: true });
  const [isOpen, setIsopen] = useState(false);
  const toggleSidebar = () => setIsopen((value) => !value);

  return (
    <>
      <div className={`sidebar__area ${isOpen ? "show" : ""}`}>
        <div className="sidebar__area--head">
          <img src={logo} alt="" />
        </div>
        <div className="sidebar__area--body">
          <div className="sidebar__area--menu">
            <Menu topics={menu} />
          </div>
        </div>
        <div className="sidebar__area--bottom">
          <Dropdown
            menu={{ items: actions }}
            trigger={["click"]}
            placement="topLeft"
          >
            <Profile
              avatar={
                <Avatar
                  mode={"text"}
                  value={`${user?.firstname?.substring(
                    0,
                    1
                  )}${user?.lastname?.substring(0, 1)}`}
                />
              }
              title={`${user.firstname} ${user.lastname}`}
              type={
                USER_TYPES_TRANSLATION[
                findKey(USER_TYPES, ({ type }) => type === user.meta.type)
                ]
              }
            />
          </Dropdown>
        </div>
      </div>
      <div className="float__menu" onClick={() => setIsopen(true)}>
        <MdMenu size={24} /> <span>Menu</span>
      </div>
      <div
        className={classnames({ "sidebar--overlay active": isOpen })}
        onClick={toggleSidebar}
      ></div>
    </>
  );
};

export default Sidebar;
