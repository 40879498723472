import logo from "shared/assets/images/anset-logo.png";
import { PricingItem } from "shared/components/SmartphoneWidgets";
import { CURRENCY_SYMBOL } from "utils/constants";

const EmailTemplate = ({
  civility,
  lastName,
  firstName,
  endOfValidityDate,
  adminPhoneNumber,
  adminFirstName,
  adminLastName,
  adminEmail,
  adminAddress,
  translation,
  costs = [],
}) => {
  return (
    <div className="quote-template__container--full">
      <div className="grid--1">
        <p>{translation.HELLO},</p>
        <p>{translation.P1}</p>
        <div className="grid--3">
          {costs?.map(({ id, cost, currency, insurancePlan }) => (
            <PricingItem
              key={id}
              title={insurancePlan?.name}
              price={`${cost} ${CURRENCY_SYMBOL[currency] || currency}`}
              monthlyPrice={`${Number(cost) * 12} ${
                CURRENCY_SYMBOL[currency] || currency
              }`}
            />
          ))}
        </div>
        <p>
          {translation.P2} {endOfValidityDate}.
        </p>
        <p>{translation.P3}</p>
        <p> {translation.P4} </p>
        <p> {translation.P5}</p>
      </div>

      <div className="quote-template__info m-top-24">
        <p className="quote-template__info--name">
          {adminFirstName} {adminLastName}
        </p>
        <br />
        <img src={logo} alt="logo" />
        <div className="quote-template__info--label">
          <div>{translation.TEL}</div> : <span>{adminPhoneNumber}</span>
        </div>
        <div className="quote-template__info--label">
          <div>{translation.EMAIL}</div> : <span>{adminEmail} </span>
        </div>
        <div className="quote-template__info--label">
          <div>{translation.WEBSITE}</div> : <span>www.anset.fr</span>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplate;
