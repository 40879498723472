import { ANSET_SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import Adherent from "./Adherent";
import Contact from "./Contact";
import Payment from "./Payment";
import Subscriber from "./Subscriber";
import Terminal from "./Terminal";

export const generateFormMap = () => {
  return {
    [ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ADHERENT]: Adherent,
    [ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT]: Contact,
    [ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINAL]: Terminal,
    [ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.SUBSCRIBER]: Subscriber,
    [ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PAYMENT]: Payment,
  };
};
