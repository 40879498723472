import { useTranslation } from "react-i18next";
import { MdOutlinePhone } from "react-icons/md";
import processDone from "shared/assets/icons/process_nocomplete.svg";
import Button from "shared/components/Button";
import Container from "shared/components/Container";
import { NAME_SPACES } from "shared/locales/constants";

const View = ({ onBack }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  return (
    <Container size="medium">
      <div className="b2c__area contact-us">
        <div className="b2c__area--header">
          <div className="b2c__area--header--icon">
            <img alt="done" src={processDone} />
          </div>
          <h2 className="b2c__area--header__title">
            {TRANSLATION.CASAE_TER.CONTACT_US.THANKS}
          </h2>
          <h3 className="b2c__area--header__desc">
            {TRANSLATION.CASAE_TER.CONTACT_US.NO_SUBSCRIPTION}
          </h3>
        </div>

        <div className="contact-us__content">
          <h3> {TRANSLATION.CASAE_TER.CONTACT_US.RECALL} </h3>
        </div>
        <div className="contact-us__action">
          <a href="tel:+330380282905">
            <Button onClick={onBack} type="primary">
              <MdOutlinePhone size={42} />
              03 80 28 29 05
            </Button>
          </a>
        </div>
        <div className="contact-us__link" onClick={onBack}>
          {TRANSLATION.CASAE_TER.CONTACT_US.LINK}
        </div>
      </div>
    </Container>
  );
};

export default View;
