// components/DetailsDrawer.js
import { useQuery } from "@apollo/client";
import { Drawer } from "antd";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import Button from "shared/components/Button";
import { GENERAL_COST } from "utils/api/graphql/queries/general-cost";
import { COVERAGE_LIMIT_CATEGORIES, CURRENCY_SYMBOL } from "utils/constants";

const DetailsDrawer = ({ open, onClose, filter = {}, translation }) => {
  const coverageBenefitLimitValue = (value) => ({
    [COVERAGE_LIMIT_CATEGORIES.BASE_REFUND]: `${value} % BR`,
    [COVERAGE_LIMIT_CATEGORIES.REAL_COSTS]: translation.REAL_COSTS,
    [COVERAGE_LIMIT_CATEGORIES.INTEGRAL_RBT]: translation.INTEGRAL_RBT,
    [COVERAGE_LIMIT_CATEGORIES.COST]: `${value} €`,
    [COVERAGE_LIMIT_CATEGORIES.COST_PER_YEAR]: `${translation.UP_TO} ${value} € / ${translation.YEAR}`,
    [COVERAGE_LIMIT_CATEGORIES.TEXT]: value,
  });
  const [displayedData, setDisplayedData] = useState([]);
  const { loading } = useQuery(GENERAL_COST, {
    variables: filter,
    skip: isEmpty(filter),
    onCompleted: ({ generalCost }) => {
      const [integer, decimal = ""] = get(generalCost, "cost")
        ?.toString()
        ?.split(".") || ["-"];
      const coverages = get(
        generalCost,
        "insurancePlan.insurancePlanCoverages",
        []
      ).flatMap(({ coverageMappings }) =>
        coverageMappings.map(({ coverage, limits }) => ({
          name: get(coverage, "type", "-"),
          limits: limits
            .map(
              ({ value }) =>
                coverageBenefitLimitValue(value?.value)[value?.category]
            )
            .join(" - "),
        }))
      );
      setDisplayedData({
        integer,
        decimal,
        currency: CURRENCY_SYMBOL[get(generalCost, "currency")],
        icon: get(generalCost, "insurancePlan.icon"),
        name: get(generalCost, "insurancePlan.name"),
        cost: get(generalCost, "cost"),
        coverages,
      });
    },
  });

  return (
    <Drawer
      className="drawer__area"
      title={get(translation, "UPSELLS.COMPLEMENT_INFORMATION", "")}
      width={700}
      onClose={onClose}
      open={open}
      placement="right"
    >
      <div className="upsells__details">
        <div className="upsells__details--header">
          <div className="upsells__details--left">
            <img
              className={"upsells__details--image"}
              src={displayedData?.icon}
              alt={displayedData?.name}
            />
            <div className="upsells__details--name">{displayedData?.name}</div>
          </div>
          <div className="upsells__details--right">
            <div className="insurance-plan__cost">
              <span className="insurance-plan__cost--integer">
                {`${displayedData?.integer} ${displayedData?.currency} `}
                <span className="insurance-plan__cost--decimal">{`${displayedData?.decimal}`}</span>
              </span>
              <span className="insurance-plan__cost--period">{` /mois`}</span>
            </div>
            <span className="insurance-card__item--total">
              {`${(+displayedData?.cost * 12).toFixed(0)} ${
                displayedData?.currency
              } / an `}
              <span>{get(translation, "UPSELLS.ALL_INCLUDED", "")}</span>
            </span>
          </div>
        </div>
        <table className="guarantees-table">
          <thead>
            <tr>
              <th>{get(translation, "UPSELLS.COVERAGE", "")}</th>
              <th>{get(translation, "UPSELLS.LIMIT", "")}</th>
            </tr>
          </thead>
          <tbody>
            {get(displayedData, "coverages", []).map((guarantee, index) => (
              <tr key={`garantee-${index}`}>
                <td>{guarantee.name}</td>
                <td>{guarantee.limits}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button type="primary" size="full--width" onClick={onClose}>
          {get(translation, "UPSELLS.CLOSE", "")}
        </Button>
      </div>
    </Drawer>
  );
};

export default DetailsDrawer;
