import { Col, Row } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Form from "shared/components/Form";
import Input from "shared/components/Form/Input";
import Header from "shared/components/Header";
import Label from "shared/components/Label";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, user, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.ORGANIZATION);
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const { firstname, lastname, phone, id } = user;
  return (
    <Formik
      initialValues={{ firstname, lastname, phone }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "gray",
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(`${SCREENS.PRIVATE.BROKER.USERS.DELETE.path}/${id}`),
              type: "danger",
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
            },
          ]}
        />,
        <Row gutter={[16, 16]} key={"row"}>
          <Col span={24}>
            <Form title={FORM.GENERAL_INFO}>
              <Col lg={24}>
                <Label placeholder={FORM.ID} value={id} />
              </Col>
              <Col lg={8}>
                <Input
                  placeholder={FORM.FIRST_NAME}
                  {...bindInputProps({ name: "firstname", ...formProps })}
                />
              </Col>
              <Col lg={8}>
                <Input
                  placeholder={FORM.LAST_NAME}
                  {...bindInputProps({ name: "lastname", ...formProps })}
                />
              </Col>
              <Col lg={8}>
                <Input
                  placeholder={FORM.PHONE}
                  {...bindInputProps({ name: "phone", ...formProps })}
                />
              </Col>
            </Form>
          </Col>
        </Row>,
      ]}
    </Formik>
  );
};

export default Detail;
