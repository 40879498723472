
import { notification } from "antd";
import cuid from "cuid";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { CREATE_PRICER, UPDATE_PRICER } from "utils/api/graphql/mutations/pricers";
import { PRICER, PRICERS } from "utils/api/graphql/queries/pricers";
import { INITIAL_VALUES, PRICER_TYPES, SCREENS } from "utils/constants";
import View from "./View";
import { useQuery } from "@apollo/client";
import omitDeep from "omit-deep-lodash";
import Loading from "shared/components/Spin";

const Detail = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.PRICER);
  const ERRORS = t("ERRORS", { returnObjects: true });
  const navigate = useNavigate();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.PRICERS.LIST.path);
  const { id } = useParams();
  const [updatePricer] = useMutation(UPDATE_PRICER, {
    refetchQueries: [
      {
        query: PRICERS,
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: () => {
      notification.success({
        message: "Pricer Created",
      });
      navigate(SCREENS.PRIVATE.ADMIN.PRICERS.LIST.path);
    },
    onError: (error) => {
      console.error("Error updating pricer:", error.message || error);
      notification.error({ message: error.message || ERRORS.DATE_CONFLICT });
    },
  });

  const { data, loading, error } = useQuery(PRICER, {
    variables: { where: { id } },
  });


  const onSubmit = ({ prices, dates, ...values }) => {
    const [startDate, endDate] = dates;
    return updatePricer({
      variables: {
        where: { id },
        data: {
          ...values,
          type: PRICER_TYPES.DB,
          startDate: startDate?.startOf("day").toISOString() || null,
          endDate: endDate?.endOf("day").toISOString() || null,
          prices: {
            ranges: prices.ranges.map(({ min, max, cost }) => ({
              min,
              max: max === "" ? null : max,
              cost,
            })),
          },
        },
      },
    });

  }
  if (loading || error) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      data={omitDeep(data.pricer, "__typename")}
      cancel={cancel}
    />
  );
};

export default Detail;

