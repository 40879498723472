import * as yup from "yup";

const validation = (messages, { isRetired }) => {
  return yup.object().shape({
    pensionFund:
      isRetired &&
      yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required(messages.REQUIRED),
          })
        )
        .required(messages.REQUIRED_ATTACHMENT)
        .min(1, messages.REQUIRED_ATTACHMENT)
        .max(1, messages.ONE_FILE),
    attachmentOfCommunity:
      !isRetired &&
      yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required(messages.REQUIRED),
          })
        )
        .required(messages.REQUIRED_ATTACHMENT)
        .min(1, messages.REQUIRED_ATTACHMENT)
        .max(1, messages.ONE_FILE),
    paymentAccount: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(messages.REQUIRED),
        })
      )
      .required(messages.REQUIRED_ATTACHMENT)
      .min(1, messages.REQUIRED_ATTACHMENT)
      .max(1, messages.ONE_FILE),
    cin: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(messages.REQUIRED),
        })
      )
      .required(messages.REQUIRED_ATTACHMENT)
      .min(1, messages.REQUIRED_ATTACHMENT),
    // .max(1, messages.ONE_FILE),
  });
};

export default validation;
