import { gql } from "@apollo/client";

export const BRANDS = gql`
  query Brands(
    $where: BrandWhereInput
    $like: BrandWhereInput
    $take: Int
    $skip: Int
    $orderBy: [BrandWhereInput]
  ) {
    brands(
      where: $where
      like: $like
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      data {
        id
        name
        logo
        models {
          id
          name
          image
        }
      }
      count
    }
  }
`;

export const BRAND = gql`
  query Brand($where: BrandWhereInput) {
    brand(where: $where) {
      id
      name
      logo
      models {
        id
        name
        image
      }
    }
  }
`;

const brands = { BRANDS, BRAND };

export default brands;
