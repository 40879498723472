import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { get } from "lodash";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import { BENEFICIARY_TYPES, DATE_FORMAT, DEPARTMENTS, INSURANCE_PLAN_RISKS, SOCIAL_REGIMES } from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import { useLocation } from "react-router-dom";
import Button from "shared/components/Button";
import { RiAddFill } from "react-icons/ri";
import { MdDeleteOutline, MdOutlineAdd, MdOutlineContentCopy } from "react-icons/md";
import { COMMISSIONS } from "utils/api/graphql/queries/commissions";
import { COLORS } from "shared/style/colors";
import validation from "./validation";
import dayjs from "dayjs";

const HealthView = ({ onSubmit, data, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.PRICER);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const [insurancePlans, setInsurancePlans] = useState([]);
  const [filteredInsurancePlans, setFilteredInsurancePlans] = useState([]);
  const [showCommission, setShowCommission] = useState(
    !get(data, "insurancePlan.internal")
  );

  useQuery(INSURANCE_PLANS, {
    onCompleted: ({ insurancePlans: { data: plans } }) => {
      const formattedPlans = plans.map(({ id, name, internal, risk }) => ({
        value: id,
        label: name,
        internal,
        risk,
      }));
      setInsurancePlans(formattedPlans);
      setFilteredInsurancePlans(
        formattedPlans.filter(
          (plan) => plan.risk === get(data, "insurancePlan.risk")
        )
      );
    },
  });

  return (
    <Formik
      initialValues={{
        name: data.name || "",
        insurancePlan: {
          id: get(data, "insurancePlan.id", ""),
          name: get(data, "insurancePlan.name", ""),
        },
        prices: get(data, "prices", {}).map(
          ({
            costs,
            departments,
            socialRegime,
            beneficiaryType,
            commission,
          }) => ({
            
            costs: costs ? JSON.stringify(costs, null, 2) : "{}",
            socialRegime: socialRegime || "",
            departments: departments || [{ values: [] }],
            beneficiaryType: beneficiaryType || "",
            commission: commission || "",
          })
        ),
        discount: data.discount || null,
        dates:
          data.startDate && data.endDate
            ? [dayjs(data.startDate), dayjs(data.endDate)]
            : [],
      }}
      validationSchema={validation(t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }))}
      onSubmit={onSubmit}
    >
      {({ isValid, handleSubmit, setFieldValue, values,errors, ...formProps }) => (
        <>
          <Header
            title={CREATE.HEADER.TITLE}
            subtitle={CREATE.HEADER.ACTION}
            actions={[
              {
                text: CREATE.HEADER.DISCARD,
                onClick: cancel,
                type: "primary--outlined",
                icon: <BiChevronLeft size={20} />,
              },
              {
                text: CREATE.HEADER.SAVE,
                onClick: handleSubmit,
                type: "primary",
                icon: <BiCheck size={20} />,
              },
            ]}
          />
          <Card title={FORM.GENERAL_INFO}>
            <div className="grid--2">
              <Select
                options={filteredInsurancePlans}
                placeholder={FORM.INSURANCE_PLAN}
                label={FORM.INSURANCE_PLAN}
                value={get(values, "insurancePlan.id")}
                {...bindInputProps({
                  name: "insurancePlan.id",
                  values,
                  ...formProps,
                })}
                onChange={(id) => {
                  const selectedPlan = insurancePlans.find((plan) => plan.value === id);
                  const isInternal = get(selectedPlan, "internal");
                  setFieldValue("insurancePlan.id", id || "");
                  setShowCommission(!isInternal);
                }}
              />
              <Input
                label={FORM.NAME}
                placeholder={FORM.NAME}
                {...bindInputProps({
                  name: "name",
                  values,
                  ...formProps,
                })}
              />
              <Input
                type="date"
                variant="range"
                label={FORM.DATE}
                format={DATE_FORMAT}
                placeholders={[FORM.START_DATE, FORM.END_DATE]}
                {...bindInputProps({
                  name: "dates",
                  values,
                  ...formProps,
                })}
                defaultValue={values.dates}
                onChange={(value) => setFieldValue("dates", value)}
              />
            </div>
          </Card>


          <div key="div" className="m-top-24" />
          <Card key="card-discount" head type="simple" title={FORM.DISCOUNTS}>
            {/* <div className="grid--3 m-bottom-16">
              <Input
                label={FORM.CONJOINT_PERCENTAGE.LABEL}
                placeholder={FORM.CONJOINT_PERCENTAGE.PLACEHOLDER}
                type="number"
                {...bindInputProps({
                  name: "discount.conjoint.percentage",
                  values,
                  ...formProps,
                })}
              />
            </div> */}
            <div className="grid--3">
              <Input
                label={FORM.CHILDREN_PERCENTAGE.LABEL}
                placeholder={FORM.CHILDREN_PERCENTAGE.PLACEHOLDER}
                type="number"
                {...bindInputProps({
                  name: "discount.children.percentage",
                  values,
                  ...formProps,
                })}
              />
              <Input
                label={FORM.AGE_LIMIT.LABEL}
                placeholder={FORM.AGE_LIMIT.PLACEHOLDER}
                type="number"
                {...bindInputProps({
                  name: "discount.children.ageLimit",
                  values,
                  ...formProps,
                })}
              />
              <Input
                label={FORM.NUMBER_LIMIT.LABEL}
                placeholder={FORM.NUMBER_LIMIT.PLACEHOLDER}
                type="number"
                {...bindInputProps({
                  name: "discount.children.discountFromNthChild",
                  values,
                  ...formProps,
                })}
              />
            </div>
          </Card>
          <div key="div-1" className="m-top-24" />
          <Card key="card-price" head type="simple" title={FORM.PRICES}>
            <FieldArray
              name={"prices"}
              render={(arrayHelpers) => (
                <div>
                  {get(values, "prices", []).map((_, i) => (
                    <div key={`price-${i}`}>
                      <div className="d-flex justify--end m-bottom-8">
                        <Button
                          type="danger--outlined"
                          onClick={() => {
                            arrayHelpers.remove(i);
                          }}
                        >
                          {FORM.DELETE}
                          <MdDeleteOutline size={24} />
                        </Button>
                        <Button
                          type="primary--outlined"
                          onClick={() => {
                            arrayHelpers.insert(
                              i + 1,
                              get(values, `prices.${i}`)
                            );
                          }}
                        >
                          {FORM.DUPLICATE}
                          <MdOutlineContentCopy
                            size={24}
                            color={COLORS.C_PRIMARY}
                          />
                        </Button>
                      </div>
                      <div className={"grid--3"} key={`price-${i}`}>
                        <Select
                          mode="multiple"
                          options={Object.values(SOCIAL_REGIMES).map((value) => ({
                            label: FORM.SOCIAL_REGIMES_OPTIONS[value],
                            value,
                          }))}
                          label={FORM.SOCIAL_REGIME}
                          placeholder={FORM.SOCIAL_REGIME}
                          {...bindArrayInputProps({
                            parent: "prices",
                            index: i,
                            name: "socialRegime",
                            values,
                            ...formProps,
                          })}
                          onChange={(value) =>
                            setFieldValue(`prices.${i}.socialRegime`, value)
                          }
                        />
                        <div className="d-flex align--start">
                          <div className="flex--grow-1">
                            <Select
                              mode="multiple"
                              options={DEPARTMENTS.map((value) => ({
                                label: value,
                                value,
                              }))}
                              label={FORM.DEPARTMENTS}
                              placeholder={FORM.DEPARTMENTS}
                              {...bindArrayInputProps({
                                parent: "prices",
                                index: i,
                                name: "departments.values",
                                values,
                                ...formProps,
                              })}
                              onChange={(value) =>
                                setFieldValue(
                                  `prices.${i}.departments.values`,
                                  value
                                )
                              }
                            />
                          </div>
                        </div>
                        <Input
                          type="checkbox"
                          label={FORM.EXCLUDED}
                          {...bindArrayInputProps({
                            parent: "prices",
                            index: i,
                            name: "departments.excluded",
                            values,
                            ...formProps,
                          })}
                          checked={
                            !!get(values, `prices.${i}.departments.excluded`)
                          }
                        />
                        {showCommission && (
                          <Select
                            type="async"
                            node={{
                              query: COMMISSIONS,
                              variables: {
                                where: {
                                  insurancePlans: {
                                    id: get(values, "insurancePlan.id"),
                                  },
                                },
                              },
                              accessors: {
                                root: "commissions.data",
                                label: {
                                  separator: "/",
                                  keys: ["value", "withholding/value"],
                                },
                              },
                            }}
                            label={FORM.COMMISSION}
                            placeholder={FORM.COMMISSION}
                            allowClear
                            {...bindArrayInputProps({
                              parent: "prices",
                              index: i,
                              name: "commission",
                              values,
                              ...formProps,
                            })}
                            onChange={(value) =>
                              setFieldValue(
                                `prices.${i}.commission`,
                                value || null
                              )
                            }
                          />
                        )}
                        <Select
                          mode="multiple"
                          options={Object.values(BENEFICIARY_TYPES).map(
                            (value) => ({
                              label: FORM.BENEFICIARY_OPTIONS[value],
                              value,
                            })
                          )}
                          label={FORM.BENEFICIARY_TYPE}
                          placeholder={FORM.BENEFICIARY_TYPE}
                          {...bindArrayInputProps({
                            parent: "prices",
                            index: i,
                            name: "beneficiaryType",
                            values,
                            ...formProps,
                          })}
                          onChange={(value) =>
                            setFieldValue(`prices.${i}.beneficiaryType`, value)
                          }
                        />
                        <Input
                          type="textarea"
                          label={FORM.PRICE_PER_AGE}
                          placeholder={FORM.PRICES}
                          autoSize={{ minRows: 3, maxRows: 6 }}
                          {...bindArrayInputProps({
                            parent: "prices",
                            index: i,
                            name: "costs",
                            values,
                            ...formProps,
                          })}
                        />
                      </div>
                      <div className="divider m-top-24 m-bottom-24" />
                    </div>
                  ))}
                  <Button
                    type="primary"
                    onClick={() => {
                      arrayHelpers.push({
                        costs: JSON.stringify({ 18: 0 }, null, 2),
                        departments: { values: [], excluded: false },
                        socialRegime: null,
                        beneficiaryType: null,
                        commission: null,
                      });
                    }}
                  >
                    <MdOutlineAdd size={24} />
                    {FORM.ADD_NEW_ROW}
                  </Button>
                </div>
              )}
            />
          </Card>



        </>
      )}
    </Formik>
  );
};

export default HealthView;
