import PropTypes from "prop-types";

const Simple = ({
  children,
  head = false,
  title,
  subtitle,
  actions,
  className,
  size = "medium",
  styleType = "normal",
}) => {
  const styleClasses = {
    normal: "card--normal",
    bordered: "card--bordered",
    shadow: "card--shadow",
    primary: "card--primary",
    transparent: "card--transparent",
  };

  const sizeClasses = {
    small: "card--small",
    medium: "card--medium",
    full: "card--full",
  };

  const cardClass = `card__custom ${className}  ${sizeClasses[size] || sizeClasses.size} ${
    styleClasses[styleType] || styleClasses.normal
  }`;

  return (
    <div className={cardClass}>
      {head && (
        <div className="card__custom--head">
          <h3 className="card__custom--head--title">{title}</h3>
          <span className="card__custom--head--subtitle">{subtitle}</span>
        </div>
      )}
      <div className="card__custom--body">{children}</div>
      {actions && <div className="card__custom--actions">{actions}</div>}
    </div>
  );
};

Simple.propTypes = {
  children: PropTypes.node.isRequired,
  head: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.node,
  size: PropTypes.oneOf(["small","medium", "full"]),
  styleType: PropTypes.oneOf(["normal", "bordered", "shadow", "transparent"]),
};

export default Simple;
