import { Table, Tag } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { RiEyeFill } from "react-icons/ri";
import Header from "shared/components/Header/Header";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";

function List({ data, count, setValues, skip }) {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.WEBHOOK);
  const LIST = t("LIST", { returnObjects: true });

  const columns = [
    {
      title: LIST.ID,
      dataIndex: "id",
      render: (item) => item || "-",
    },
    {
      title: LIST.FIRST_NAME,
      dataIndex: "contact",
      render: (item) => get(item, "user.firstname", "-"),
    },
    {
      title: LIST.LAST_NAME,
      dataIndex: "contact",
      render: (item) => get(item, "user.lastname", "-"),
    },
    {
      title: LIST.STATUS,
      dataIndex: "meta",
      render: (item) => <Tag>{get(item, "status", "-")}</Tag>,
    },
    {
      title: LIST.ACTIONS,
      dataIndex: "actions",
      render: (_, __) => (
        <RiEyeFill style={{ fontSize: 18 }} onClick={() => {}} />
      ),
    },
  ];

  return (
    <>
      <Header
        key={"header"}
        title={LIST.HEADER.TITLE}
        okText={LIST.HEADER.SAVE}
        cancelText={LIST.HEADER.REFRESH}
        hideButtons={false}
        onOkClick={() => {}}
        onCancelClick={() => {}}
      />
      <Table
        dataSource={data}
        columns={columns}
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
      />
      <br />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
}

export default List;
