import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MdCheck, MdClose, MdInfoOutline } from "react-icons/md";
import Button from "shared/components/Button";
import OfferDetails from "shared/components/SmartphoneWidgets/OfferDetails";
import PricingItem from "shared/components/SmartphoneWidgets/PricingItem";
import { NAME_SPACES } from "shared/locales/constants";

const PricingCard = ({
  title,
  description,
  price,
  monthlyPrice,
  includedItems,
  excludedItems,
  onSubscribe,
  disabled,
  onLearnMore,
  limits = [],
}) => {
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const PRICING_CARD = t("PRICING_CARD", { returnObjects: true });

  return (
    <div className="pricing__card">
      <div className="pricing__card--header">
        <PricingItem
          title={title}
          description={description}
          price={price}
          monthlyPrice={monthlyPrice}
          variant="detailed"
          onLearnMore={onLearnMore}
        />
      </div>

      <div className="pricing__card--subscribe">
        <Button
          type="secondary"
          size={"full--width"}
          onClick={onSubscribe}
          disabled={disabled}
        >
          {PRICING_CARD.SUBSCRIBE}
        </Button>

        <div className="pricing__card--summary">
          {limits.map((value) => (
            <div className="pricing__card--summary-item">
              <MdInfoOutline size={20} />
              <span dangerouslySetInnerHTML={{ __html: value }} />
            </div>
          ))}
        </div>
      </div>

      <div className="pricing__card--details">
        <div className="box">
          <OfferDetails
            title="Inclut dans cette offre :"
            icon={<MdCheck size={20} color="green" />}
            items={includedItems}
          />
        </div>
        <div className="box">
          <OfferDetails
            title="Exclu de cette offre :"
            icon={<MdClose size={20} color="red" />}
            items={excludedItems}
          />
        </div>
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.string.isRequired,
  includedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  excludedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onLearnMore: PropTypes.func,
  claimPerYear: PropTypes.string,
  annualLimit: PropTypes.string,
};

export default PricingCard;
