import classNames from "classnames";
import cuid from "cuid";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiCalendar, FiPhone } from "react-icons/fi";
import { MdCheck } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import Button from "shared/components/Button";
import Container from "shared/components/Container";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { ATTACHMENT_TYPES, DATE_FORMAT, RELATIONSHIP } from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import { bindDateInputProps } from "utils/helpers/input";
import Stepper from "../Components/Stepper";
import validation from "./validation";
import ProductCard from "./widgets/ProductCard";

import { BsArrowLeftCircle } from "react-icons/bs";
import { FaMinus, FaPlus } from "react-icons/fa";
import Modal from "shared/components/Modal";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  hundredYearsAgoStart,
  isMajor,
  twentySevenYearsAgoStart,
} from "utils/helpers/date";

const phoneNumber = "+33380282905";

const View = ({
  onSendQuote,
  onBack,
  products,
  disabledProducts,
  contact,
  refetchCosts,
  downloadSingleDocument,
  locked,
  duplicateProject,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const FLAGSHIP_GUARANTEES = [
    {
      title: "Les garanties phares",
      items: [
        {
          coverage: "Honoraires",
          limit: "120%",
          complement: "(1)",
        },
        {
          coverage: "Hospitalisation",
          limit: "0 €/nuit",
          complement: "chambre indiv",
        },
        {
          coverage: "Médecine douce",
          limit: "20 €/séance",
          complement: "(2)",
        },
        {
          coverage: "Dentaire",
          limit: "150 €/implant",
          complement: "(3)",
        },
        {
          coverage: "Lunette verres complexes",
          limit: "jusqu'à 200 €",
        },
      ],
    },
    {
      title: "Les garanties phares",
      items: [
        {
          coverage: "Honoraires",
          limit: "150%",
          complement: "(1)",
        },
        {
          coverage: "Hospitalisation",
          limit: "45 €/nuit",
          complement: "chambre indiv",
        },
        {
          coverage: "Médecine douce",
          limit: "40 €/séance",
          complement: "(2)",
        },
        {
          coverage: "Dentaire",
          limit: "300 €/implant",
          complement: "(3)",
        },
        {
          coverage: "Lunette verres complexes",
          limit: "jusqu'à 360 €",
        },
      ],
    },
    {
      title: "Les garanties phares",
      items: [
        {
          coverage: "Honoraires",
          limit: "220%",
          complement: "(1)",
        },
        {
          coverage: "Hospitalisation",
          limit: "60 €/nuit",
          complement: "chambre indiv",
        },
        {
          coverage: "Médecine douce",
          limit: "60 €/séance",
          complement: "(2)",
        },
        {
          coverage: "Dentaire",
          limit: "500 €/implant",
          complement: "(3)",
        },
        {
          coverage: "Lunette verres complexes",
          limit: "jusqu'à 480 €",
        },
      ],
    },
  ];
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const onSubmit = async ({ spouse, children }) => {
    await refetchCosts({
      contact: {
        id: contact.id,
        relatedPersons: [spouse, ...children],
      },
    });
    setAddBeneficiaries((prev) => ({ ...prev, visible: false }));
  };

  const { handleSubmit, values, ...formProps } = useFormik({
    initialValues: {
      spouse: get(contact, "relatedPersons", []).find(
        ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
      ),
      children: get(contact, "relatedPersons", []).filter(
        ({ relationship }) => relationship === RELATIONSHIP.CHILD
      ),
    },
    onSubmit,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });
  const [childrenNumber, setChildrenNumber] = useState(
    get(values, "children", []).length
  );

  const [addBeneficiaries, setAddBeneficiaries] = useState({
    visible: false,
    spouse: !isEmpty(get(values, "spouse")),
    children: !isEmpty(get(values, "children")),
    status: !(
      isEmpty(get(values, "spouse")) && isEmpty(get(values, "children"))
    ),
  });
  const [disabled, setDisabled] = useState({});

  useEffect(() => {
    const children = get(values, "children", []);
    const oldChildrenLength = children.length;

    if (oldChildrenLength < childrenNumber) {
      formProps.setFieldValue("children", [
        ...children,
        {
          id: cuid(),
          relationship: RELATIONSHIP.CHILD,
          identity: {
            id: cuid(),
            user: {
              id: cuid(),
              birthDate: null,
            },
          },
        },
      ]);
    } else if (oldChildrenLength > childrenNumber) {
      formProps.setFieldValue("children", children.slice(0, childrenNumber));
    }
  }, [childrenNumber]);

  useEffect(() => {
    if (prevRef.current && nextRef.current) {
      prevRef.current.classList.add("custom-prev");
      nextRef.current.classList.add("custom-next");
    }
  }, []);

  const onOkClick = async () => {
    await duplicateProject();
    setOpenEditModal(false);
  };

  const handleYesClick = () => {
    if (locked) return setOpenEditModal(true);

    setAddBeneficiaries((prev) => ({
      ...prev,
      visible: true,
      status: true,
    }));
  };

  const handleNoClick = () => {
    if (!addBeneficiaries?.status) return;
    if (locked) return setOpenEditModal(true);

    formProps.setFieldValue("spouse", undefined);
    formProps.setFieldValue("children", []);
    setChildrenNumber(0);
    setAddBeneficiaries({
      spouse: false,
      children: false,
      visible: false,
      status: false,
    });
    handleSubmit();
  };

  return (
    <Container size="medium">
      <div className="b2c__area">
        <Modal
          type="info"
          visible={openEditModal}
          message={TRANSLATION.LOCKED_EDIT_MODAL.MESSAGE}
          okText={TRANSLATION.LOCKED_EDIT_MODAL.OK}
          cancelText={TRANSLATION.LOCKED_EDIT_MODAL.CANCEL}
          onOk={onOkClick}
          onCancel={() => setOpenEditModal(false)}
        />
        <Stepper
          items={[
            { label: TRANSLATION.STTEPER.SITUATION, completed: true },
            { label: TRANSLATION.STTEPER.QUOTE, completed: true },
            { label: TRANSLATION.STTEPER.SUBSCRIPTION, completed: false },
            { label: TRANSLATION.STTEPER.VALIDATION, completed: false },
          ]}
        />
        <div className="b2c__area--header">
          <div className="b2c__area--header__title">
            {TRANSLATION.PRODUCTS.TITLE_R}
          </div>
        </div>
        <div className="b2c__area--content">
          <div className="b2c__area--content__related_persons">
            <div className="beneficiary_container">
              <div className="beneficiary_container--main">
                <span className="question">
                  {TRANSLATION.PRODUCTS.ADD_BENEFICIARY}
                </span>
                <div className="d-flex">
                  <Button
                    type={classNames({
                      primary: addBeneficiaries?.status,
                      "primary--outlined": !addBeneficiaries?.status,
                    })}
                    onClick={handleYesClick}
                  >
                    {TRANSLATION.YES}
                  </Button>

                  <Button
                    type={classNames({
                      primary: !addBeneficiaries?.status,
                      "primary--outlined": addBeneficiaries?.status,
                    })}
                    onClick={handleNoClick}
                  >
                    {TRANSLATION.NO}
                  </Button>
                </div>
              </div>
              <div
                className={classNames("beneficiary_container--expand", {
                  hidden: !addBeneficiaries.visible,
                })}
              >
                <div className="beneficiary_container--expand--row">
                  <Input
                    type="checkbox"
                    label={TRANSLATION.PRODUCTS.SPOUSE}
                    checked={addBeneficiaries.spouse}
                    onChange={({ target: { checked } }) => {
                      setAddBeneficiaries((prev) => ({
                        ...prev,
                        spouse: checked,
                      }));
                      if (checked)
                        formProps.setFieldValue("spouse", {
                          id: cuid(),
                          relationship: RELATIONSHIP.SPOUSE,
                          identity: {
                            id: cuid(),
                            user: {
                              id: cuid(),
                              birthDate: null,
                            },
                          },
                        });
                      else formProps.setFieldValue("spouse", undefined);
                    }}
                  />
                  <Input
                    type="date"
                    placeholder={TRANSLATION.BIRTH_DATE.LABEL}
                    format={DATE_FORMAT}
                    disabled={!addBeneficiaries.spouse}
                    showToday={false}
                    {...bindDateInputProps({
                      name: "spouse.identity.user.birthDate",
                      values,
                      ...formProps,
                    })}
                    defaultPickerValue={
                      !get(values, `spouse.identity.user.birthDate`) &&
                      dayjs().subtract(24, "years")
                    }
                    disabledDate={(current) =>
                      current > isMajor || current < hundredYearsAgoStart
                    }
                  />
                </div>
                <div className="divider" />
                <div className="beneficiary_container--expand--row">
                  <Input
                    type="checkbox"
                    label={TRANSLATION.PRODUCTS.CHILDREN}
                    checked={addBeneficiaries.children}
                    onChange={({ target: { checked } }) => {
                      if (!checked) {
                        setChildrenNumber(0);
                        formProps.setFieldValue("children", []);
                      } else if (childrenNumber === 0) setChildrenNumber(1);
                      setAddBeneficiaries((prev) => ({
                        ...prev,
                        children: checked,
                      }));
                    }}
                  />
                  <div className="children-count">
                    <Button
                      className="button-small-rounded"
                      onClick={() => setChildrenNumber((prev) => +prev - 1)}
                      disabled={
                        childrenNumber < 2 || !addBeneficiaries.children
                      }
                    >
                      <FaMinus size={14} />
                    </Button>
                    <div className="flex--grow-1">
                      <Input
                        type="number"
                        min={0}
                        max={12}
                        placeholder={"0"}
                        name={"gender"}
                        value={childrenNumber}
                        onChange={({ target: { value } }) => {
                          setChildrenNumber(+value);
                        }}
                        disabled={!addBeneficiaries.children}
                      />
                    </div>
                    <Button
                      className="button-small-rounded"
                      onClick={() => setChildrenNumber((prev) => +prev + 1)}
                      disabled={
                        childrenNumber > 11 || !addBeneficiaries.children
                      }
                    >
                      <FaPlus size={14} />
                    </Button>
                  </div>
                </div>
                <div className="children-birthday">
                  {Array(childrenNumber)
                    .fill()
                    .map((_, i) => (
                      <Input
                        direction="row"
                        key={`child-${i}`}
                        label={`${
                          TRANSLATION.NUMBERS_MAPPING[i + 1] || i + 1
                        } ${TRANSLATION.CHILDREN_BIRTHDATES.CHILD}`}
                        placeholder={TRANSLATION.CHILDREN_BIRTHDATES.BIRTH_DATE}
                        type="date"
                        variant="simple"
                        format={DATE_FORMAT}
                        disabledDate={(current) => {
                          return (
                            current > dayjs() ||
                            current < twentySevenYearsAgoStart
                          );
                        }}
                        defaultPickerValue={
                          !get(
                            values,
                            `children.${i}.identity.user.birthDate`
                          ) && dayjs().subtract(1, "years")
                        }
                        showToday={false}
                        {...bindDateInputProps({
                          name: `children.${i}.identity.user.birthDate`,
                          values,
                          ...formProps,
                        })}
                      />
                    ))}
                </div>
                <Button
                  type="primary"
                  size={"full--width"}
                  onClick={() => handleSubmit()}
                  disabled={
                    !isEmpty(formProps.errors) ||
                    (childrenNumber === 0 && !addBeneficiaries.spouse)
                  }
                >
                  {TRANSLATION.PRODUCTS.SUBMIT}
                </Button>
              </div>
            </div>
            {(childrenNumber > 0 || addBeneficiaries.spouse) && (
              <div className="beneficiary_chips">
                {addBeneficiaries.spouse &&
                  get(values, "spouse.identity.user.birthDate") && (
                    <div className="beneficiary_chips--button">
                      <span>{TRANSLATION.PRODUCTS.SPOUSE}</span>
                      <RxCross2
                        size={20}
                        onClick={() => {
                          formProps.setFieldValue("spouse", undefined);
                          setAddBeneficiaries((prev) => ({
                            ...prev,
                            spouse: false,
                          }));
                          handleSubmit();
                        }}
                      />
                    </div>
                  )}
                {get(values, "children", [])
                  .filter((child) => get(child, "identity.user.birthDate"))
                  .map((_, i) => (
                    <div
                      key={`child_${i}`}
                      className="beneficiary_chips--button"
                    >
                      <span>{`${TRANSLATION.CHILDREN.CHILD.TITLE} ${
                        i + 1
                      }`}</span>
                      <button
                        onClick={() => {
                          values.children.splice(i, 1);
                          setChildrenNumber((prev) => +prev - 1);
                          if (childrenNumber === 1)
                            setAddBeneficiaries((prev) => ({
                              ...prev,
                              children: false,
                            }));
                          formProps.setFieldValue(
                            "children",
                            get(values, "children", [])
                          );
                          handleSubmit();
                        }}
                      >
                        <RxCross2 size={20} />
                      </button>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="b2c__area--products">
            <Swiper
              modules={[Navigation]}
              slidesPerView={3}
              spaceBetween={16}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  initialSlide: 1,
                },
                320: {
                  slidesPerView: 1,
                  centeredSlides: true,
                  initialSlide: 1,
                },
                520: {
                  slidesPerView: 1,
                  centeredSlides: true,
                  initialSlide: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                840: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                1099: {
                  slidesPerView: 3,
                },
                1199: {
                  slidesPerView: 3,
                },
              }}
            >
              {products.map(({ id, cost, currency, insurancePlan }, i) => (
                <SwiperSlide key={insurancePlan.id}>
                  <ProductCard
                    price={{
                      cost,
                      currency,
                      period: TRANSLATION.PRODUCTS.PER_MONTH,
                    }}
                    name={get(insurancePlan, "name")}
                    action={{
                      onClick: () => {
                        setDisabled({ [id]: true });
                        onSendQuote(insurancePlan.id);
                      },
                      label: TRANSLATION.PRODUCTS.CHOOSE_OFFER,
                      loading: disabled[id],
                      disabled: !isEmpty(disabled) || addBeneficiaries.visible,
                    }}
                    disabled={disabledProducts.includes(insurancePlan.id)}
                    info={{
                      title: "Les garanties phares",
                      items: get(
                        insurancePlan,
                        `fields.flagshipGuarantees`,
                        FLAGSHIP_GUARANTEES[i]?.items
                      ),
                    }}
                    link={{
                      onClick: () => {
                        downloadSingleDocument({
                          variables: {
                            where: {
                              insurancePlans: { id: insurancePlan?.id },
                              type: ATTACHMENT_TYPES.BROCHURE,
                            },
                          },
                          onCompleted: (_) => {
                            const { document } = omitDeep(
                              _.downloadSingleDocument,
                              "__typename"
                            );
                            downloadDocument(document);
                          },
                        });
                      },
                      label: TRANSLATION.PRODUCTS.COVERAGE_DETAIL,
                    }}
                    recommendation={{
                      label: TRANSLATION.PRODUCTS.RECOMMENDATION,
                      value: i === 1,
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper__arrows">
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>
          <div className="additional-info">
            <span className="additional-info__header">
              {TRANSLATION.PRODUCTS.ALL_INCLUDED}
            </span>
            <div className="additional-info__items">
              <div className="additional-info__item">
                <div className="additional-info__item--check">
                  <MdCheck
                    size={24}
                    className="additional-info__item--check--icon"
                  />
                </div>
                <div className="additional-info__item--label">
                  {TRANSLATION.PRODUCTS.NETWORK}
                </div>
              </div>
              <div className="additional-info__item">
                <div className="additional-info__item--check">
                  <MdCheck
                    size={24}
                    className="additional-info__item--check--icon"
                  />
                </div>
                <div className="additional-info__item--label">
                  {TRANSLATION.PRODUCTS.SERVICE_COSAE}
                </div>
              </div>
              <div className="additional-info__item">
                <div className="additional-info__item--check">
                  <MdCheck
                    size={24}
                    className="additional-info__item--check--icon"
                  />
                </div>
                <div className="additional-info__item--label">
                  {TRANSLATION.PRODUCTS.ASSISTANCE_COSAE}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="get-in-touch_container">
          <div className="contact__me">
            <div className="contact__me--content">
              <h3> {TRANSLATION.PRODUCTS.DO_NOT_FIND}</h3>
              <p> {TRANSLATION.PRODUCTS.ADVISOR_CALL}</p>
            </div>
            <Button
              type="primary"
              alignment="right"
              onClick={() => {
                window.location.href = `tel:${phoneNumber}`;
              }}
            >
              <FiPhone size={24} />
              {TRANSLATION.PRODUCTS.CONTACT_US}
            </Button>
          </div>
          <div className="contact__me">
            <div className="contact__me--content">
              <h3> {TRANSLATION.PRODUCTS.GET_IN_TOUCH.TITLE}</h3>
              <p> {TRANSLATION.PRODUCTS.GET_IN_TOUCH.DESCRIPTION}</p>
            </div>
            <Button
              type="primary"
              alignment="right"
              onClick={() => {
                window.location.href = `https://client.calizy.com/accounts/d2bb8a71-4418-4ecc-b272-66bbae71d815/category?source_id=COSAE TER`;
              }}
            >
              <FiCalendar size={24} />
              {TRANSLATION.PRODUCTS.GET_IN_TOUCH.CALL_TO_ACTION}
            </Button>
          </div>
        </div>
        {!locked && (
          <div className="b2c__area--actions">
            <Button onClick={onBack} className="prev">
              <BsArrowLeftCircle size={32} />
              <span>{TRANSLATION.PREVIOUS}</span>
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default View;
