import FILE_ICON from "shared/assets/images/file_icon.png";
import Loading from "shared/components/Spin";

const Attachments = ({ loading, unlinkAttachment, list }) => {
  if (loading) return <Loading />;

  return (
    <div className="quote-template__attachments">
      {list.map(({ id, name, contentType }, index) => (
        <div
          className="quote-template__attachments--file"
          key={`attachment_${index}`}
        >
          {/* <span
            className="quote-template__attachments--unlink"
            onClick={() => unlinkAttachment(id)}
          >
            <MdClose size={16} />
          </span> */}
          <img
            className="quote-template__attachments--fileIcon"
            src={FILE_ICON}
            alt={name}
          />
          <span className="quote-template__attachments--fileName">{name}</span>
          <span className="quote-template__attachments--fileType">
            [{contentType.split("/")[1]}]
          </span>
        </div>
      ))}
    </div>
  );
};

export default Attachments;
