import { Col, Row } from "antd";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOutlineFreeCancellation } from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";

const SponsorshipMembership = ({
  formProps,
  values,
  setPercent,
  isFieldRequired,
  initialValues,
  errors,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.SPONSORSHIP_MEMBERSHIP}-section`}
    >
      <Form
        type="vertical"
        title={
          SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.SPONSORSHIP_MEMBERSHIP.TITLE
        }
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              placeholder={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.SPONSORSHIP_MEMBERSHIP
                  .FULLNAME
              }
              label={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.SPONSORSHIP_MEMBERSHIP
                  .FULLNAME
              }
              icon={<MdOutlineFreeCancellation size={16} />}
              defaultValue={get(
                initialValues,
                "additionalInfo.sponsor.fullName"
              )}
              errors={errors["additionalInfo.sponsor.fullName"]}
              touched={!isEmpty(errors["additionalInfo.sponsor.fullName"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "additionalInfo.sponsor.fullName", value);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired("additionalInfo.sponsor.fullName")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.SPONSORSHIP_MEMBERSHIP
                  .SLM_ID
              }
              label={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.SPONSORSHIP_MEMBERSHIP
                  .SLM_ID
              }
              icon={<MdOutlineFreeCancellation size={16} />}
              defaultValue={get(initialValues, "additionalInfo.sponsor.slmId")}
              errors={errors["additionalInfo.sponsor.slmId"]}
              touched={!isEmpty(errors["additionalInfo.sponsor.slmId"])}
              onBlur={() => setPercent(initialValues)}
              onChange={({ target: { value } }) => {
                set(initialValues, "additionalInfo.sponsor.slmId", value);
              }}
              required={isFieldRequired("additionalInfo.sponsor.slmId")}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SponsorshipMembership;
