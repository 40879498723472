import { useQuery } from "@apollo/client";
import { flatten, get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery } from "shared/hooks/useApi";
import { useScreenActionPermissions } from "shared/hooks/usePermissions";
import format from "string-template";
import { OFFERS } from "utils/api/graphql/queries/offers";
import { INITIAL_VALUES, MODULES, SUBMODULES } from "utils/constants";
import View from "./View";

const generateSQLCondition = (ruleName, condition, value) => {
  return format(
    `EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}' AND ({condition})) OR NOT EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}')`,
    { ruleName, condition: condition.replace(":value", value) }
  );
};

const List = ({ project, onNext, onBack, processId }) => {
  const actions = useScreenActionPermissions({
    module: MODULES.PROJECT,
    submodule: SUBMODULES.BROKER_DETAIL,
  });
  const { id } = useParams();
  const take = 5;
  const [skip, setSkip] = useState(INITIAL_VALUES.skip);

  const [filter, setFilter] = useState({});
  const [selectedProducts, setSelectedProducts] = useState(
    get(project, "fields.selectedModules", [])
  );
  const [selectedInsurancePlans, setSelectedInsurancePlans] = useState([]);

  const [getOffers, { loading: offersLoading }] = useLazyQuery(OFFERS);
  const { loading: mainOffersLoading } = useQuery(OFFERS, {
    variables: {
      where: {
        OR: get(project, "fields.selectedToCompare", []).map((id) => ({
          AND: [
            {
              offerInsurancePlans: {
                insurancePlan: {
                  id,
                },
              },
            },
            {
              offerInsurancePlans: {
                main: true,
              },
            },
          ],
        })),
      },
    },
    onCompleted: ({ offers }) => {
      get(offers, "data", []).forEach(({ id }) =>
        getOffers({
          variables: {
            where: {
              id,
            },
          },
          onCompleted: ({ offers }) => {
            const offerInsurancePLan = get(offers, "data", []).map(
              ({ offerInsurancePlans }) => {
                return omitDeep(offerInsurancePlans, "__typename");
              }
            );
            setSelectedInsurancePlans((prev) => ({
              ...prev,
              [id]: flatten(offerInsurancePLan),
            }));
          },
        })
      );
    },
  });

  const onAdd = (product) =>
    setSelectedProducts((prev) => [...prev, product.id]);

  const onRemove = (product) =>
    setSelectedProducts((prev) => prev.filter(({ id }) => id !== product.id));

  if (offersLoading || mainOffersLoading) return <Loading />;

  return (
    <View
      initialFields={project.fields}
      initialNeeds={project.fields.needs}
      expired={project?.expired}
      onNext={onNext}
      filter={filter}
      take={take}
      onBack={onBack}
      onAdd={onAdd}
      onRemove={onRemove}
      selectedProducts={selectedProducts}
      onLoadMore={setSkip}
      loading={false}
      actions={actions}
      products={selectedInsurancePlans}
    />
  );
};

export default List;
