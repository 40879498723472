import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { useContext, useMemo, useState } from "react";
import Loading from "shared/components/Spin";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { SUBSCRIPTION_TEMPLATES } from "utils/constants";
import Templates from "./Templates";

export const STEPPER_KEYS = {
  ADDITIONAL_INFORMATION: "ADDITIONAL_INFORMATION",
  PROOF: "PROOF",
  DOCUMENT: "DOCUMENT",
};
const Detail = (_) => {
  const [organization, setOrganization] = useState(null);
  const { user } = useContext(StoreContext);

  useQuery(graphql.queries.ORGANIZATION, {
    variables: { where: { id: get(user, "organization.id") } },
    onCompleted: ({ organization }) => {
      setOrganization(organization);
    },
  });

  const SubscriptionTemplate = useMemo(() => {
    if (!organization) return;
    const Template = get(
      Templates,
      SUBSCRIPTION_TEMPLATES[get(organization, "config.template")],
      Templates.Health
    );
    return Template;
  }, [organization]);

  if (!organization) return <Loading />;
  return <SubscriptionTemplate />;
};

export default Detail;
