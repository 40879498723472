import { MdArrowForward, MdOutlineDashboard, MdSettings } from "react-icons/md";
import toolbox from "./toolbox";
import Header from "shared/components/Header";
import Sidebar from "shared/components/Layout/Sidebar";


const menu = [
  {
    key: "dashboard",
    label: "Dashboard",
    icon: <MdOutlineDashboard />,
    onClick: () => alert("Dashboard"),
  },
  {
    key: "settings",
    label: "Settings",
    icon: <MdSettings />,
    onClick: () => alert("Settings"),
  },
  {
    key: "profile",
    label: "Profile",
    icon: <MdArrowForward />,
    onClick: () => alert("Profile"),
  },
];

const actions = [
  {
    key: "logout",
    label: "Logout",
    onClick: () => alert("Logging out..."),
  },
  {
    key: "help",
    label: "Help",
    onClick: () => alert("Help"),
  },
];

const templates = [
  {
    name: "contact",
    header: {
      id: Math.random(), widget: <Header
        key={"header"}
        title={"TITLE"}
        actions={[
          {
            text: "DETAIL.HEADER.DISCARD",
            onClick: () => { },
            type: "gray--link",
          },
          {
            text: "DETAIL.HEADER.DELETE",
            onClick: () => { },
            type: "danger--outlined",
          },
          {
            text: "DETAIL.HEADER.SAVE",
            onClick: () => { },
            type: "primary",
          },
        ]}
      />
    },
    sider: {
      id: Math.random(),
      widget: (
        <Sidebar menu={menu} actions={actions} />
      ),
    },
    body: {
      components: [],
    },
  },
];

export default templates;
