import React from "react";
import { FiMenu } from "react-icons/fi";
import Menu from "./Menu";
import Sidebar from "./Sidebar";

const GlobalLayout = ({ children, sider, breadcrumb }) => {
  return (
    <main className="layout">
      <Sidebar
        menu={sider.menu}
        actions={sider.actions}
      />
      <div className="children--layout container">{children}</div>
    </main>
  );
};

export default GlobalLayout;
