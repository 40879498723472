// components/ProductsDrawer.js
import { Drawer } from "antd";
import { get } from "lodash";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { CURRENCY_SYMBOL } from "utils/constants";

const ProductsDrawer = ({
  open,
  onClose,
  selectedInsurancePlans,
  selectedModules,
  onCheck,
  insurancePlan,
  cost,
  currency,
  disabled,
  translation,
}) => {
  const [integer, decimal = ""] = cost?.toString()?.split(".") || ["-"];
  const symbol = CURRENCY_SYMBOL[currency];

  return (
    <Drawer
      className="drawer__area"
      title={get(translation, "UPSELLS.COMPLEMENTS_MANAGEMENT", "")}
      width={500}
      onClose={onClose}
      open={open}
      placement="right"
    >
      <div className="upsells__details">
        <div className="upsells__details--header">
          <div className="upsells__details--left">
            <img
              className="upsells__details--image"
              src={insurancePlan.icon}
              alt={insurancePlan.name}
            />
            <div className="upsells__details--name">{insurancePlan.name}</div>
          </div>
          <div className="upsells__details--right">
            <div className="insurance-plan__cost">
              <span className="insurance-plan__cost--integer">
                {`${integer} ${symbol} `}
                <span className="insurance-plan__cost--decimal">{`${decimal}`}</span>
              </span>
              <span className="insurance-plan__cost--period">{` /mois`}</span>
            </div>
            <span className="insurance-card__item--total">
              {`${(+cost * 12).toFixed(0)} ${symbol} / an `}
              <span>{get(translation, "UPSELLS.ALL_INCLUDED", "")}</span>
            </span>
          </div>
        </div>
        <div className="upsells__details--body">
          {selectedInsurancePlans?.map((module) => {
            const [integer, decimal = ""] = cost?.toString()?.split(".") || [
              "-",
            ];
            const symbol = CURRENCY_SYMBOL[currency];
            return (
              <div
                key={get(module, "insurancePlan.id")}
                className="upsells__checkbox"
              >
                <Input
                  type="checkbox"
                  label={get(module, "insurancePlan.name")}
                  onChange={() => onCheck(module)}
                  checked={
                    get(selectedModules, insurancePlan.id, []).find(
                      ({ insurancePlan }) =>
                        insurancePlan?.id ===
                        get(module, "insurancePlan.id", "")
                    ) || false
                  }
                  disabled={disabled}
                />
                <div className="upsells__details--right">
                  <div className="insurance-plan__cost">
                    <span className="upsells__details--integer">
                      {`${integer} ${symbol} `}
                      <sup>{`${decimal}`}</sup>
                    </span>
                    <span className="upsells__details--period">{` /mois`}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Button type="primary" size="full--width" onClick={onClose}>
          {get(translation, "UPSELLS.SAVE_CLOSE", "")}
        </Button>
      </div>
    </Drawer>
  );
};

export default ProductsDrawer;
