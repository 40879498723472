import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";

const PricingItem = ({
  title,
  description,
  price,
  monthlyPrice,
  variant = "compact",
  onLearnMore,
}) => {
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const PRICING_ITEM = t("PRICING_ITEM", { returnObjects: true });
  return (
    <div className={`pricing__item pricing__item--${variant}`}>
      <div className="--header">
        <div className="--top">
          <h3 className="--title">{title}</h3>
          {variant === "detailed" && (
            <Button
              type="primary--underline"
              onClick={onLearnMore}
              className="btn--modify"
            >
              {PRICING_ITEM.LEARN_MORE}
            </Button>
          )}
        </div>
        {description && <p className="--description">{description}</p>}
      </div>

      <div className="--price">
        <span className="main">{price}</span>
        <span className="sub">
          {PRICING_ITEM.PER_MONTH} {monthlyPrice} {PRICING_ITEM.PER_YEAR}
        </span>
      </div>
    </div>
  );
};

PricingItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["compact", "detailed", "highlighted"]),
  onLearnMore: PropTypes.func,
};

export default PricingItem;
