import { Affix, Col, Divider, Row } from "antd";
import { find, get, isEmpty, take } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import {
  ActionStepper,
  ClientInformation,
  PricingCard,
  ProductCard,
} from "shared/components/SmartphoneWidgets";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_POINT_SYSTEM,
  CURRENCY_SYMBOL,
  SUPPORTING_INFORMATION_CATEGORIES,
} from "utils/constants";
import { costToFrench } from "utils/helpers/values";
import DetailsDrawer from "./Widgets/Details";
import PlansModal from "./Widgets/Plans";

const View = ({
  project,
  onSubscribe,
  onDownload,
  onSendQuote,
  data,
  onBack,
  duplicateProject,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const COMPARISON_PRODUCTS = t("DETAIL.TEMPLATES.COMPARISON_PRODUCTS", {
    returnObjects: true,
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLearnMore = (data) => {
    setIsDrawerOpen(data);
  };

  const handleSubscribe = (id) => {
    const subscription = get(project, "subscriptions", []).find(
      (subscription) => get(subscription, "insurancePlan.id") === id
    );
    onSubscribe({
      actionsTemplate: {
        id: subscription?.id,
        status: subscription?.status,
      },
    });
  };

  return (
    <>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.COMPARISON.TITLE}
        actions={[]}
      />

      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={8} lg={6}>
          <Affix offsetTop={isTabletOrMobile ? "null" : 110}>
            <Card type={"custom"} size="full" styleType={"primary"}>
              <ProductCard
                image={get(project, "fields.terminal.image", "")}
                name={get(project, "fields.terminal.model", "")}
                price={`${get(
                  project,
                  "fields.terminal.purchasePrice",
                  ""
                )} XPF`}
                onModify={() => onBack({ steps: 2 })}
                variant="vertical"
                disabled={project.locked}
              />
              <Divider />
              <ClientInformation
                title={COMPARISON_PRODUCTS.CUSTOMER_INFORMATION}
                info={[
                  {
                    label: COMPARISON_PRODUCTS.FIRST_NAME,
                    value: get(project, "contact.user.lastname"),
                  },
                  {
                    label: COMPARISON_PRODUCTS.LAST_NAME,
                    value: get(project, "contact.user.firstname"),
                  },
                  {
                    label: COMPARISON_PRODUCTS.PHONE_NUMBER,
                    value:
                      find(get(project, "contact.telecoms", []), {
                        system: CONTACT_POINT_SYSTEM.PHONE,
                      })?.value || "-",
                  },
                ]}
                variant="client"
                onModify={onBack}
                disabled={project.locked}
              />
            </Card>
          </Affix>
        </Col>
        <Col xs={24} sm={24} md={16} lg={18}>
          <Card
            type={"custom"}
            size="full"
            head
            title={COMPARISON_PRODUCTS.TITLE}
            subtitle={COMPARISON_PRODUCTS.SUBTITLE}
          >
            <div className="d-flex gap-16 flex--column m-top-24">
              {data?.map(({ id, cost, currency, insurancePlan }) => (
                <PricingCard
                  key={id}
                  title={insurancePlan?.name}
                  description={get(insurancePlan, "description", "")}
                  price={`${costToFrench(cost, 0)} ${
                    CURRENCY_SYMBOL[currency] || currency
                  }`}
                  monthlyPrice={`${costToFrench(cost * 12, 0)} ${
                    CURRENCY_SYMBOL[currency] || currency
                  }`}
                  includedItems={take(
                    get(insurancePlan, "supportingInformation", [])
                      .filter(
                        ({ category }) =>
                          category ===
                          SUPPORTING_INFORMATION_CATEGORIES.INCLUDED
                      )
                      .sort((a, b) => b.weight - a.weight)
                      .map(({ value }) => ({ value: value.mainInfo })),
                    3
                  )}
                  excludedItems={take(
                    get(insurancePlan, "supportingInformation", [])
                      .filter(
                        ({ category }) =>
                          category ===
                          SUPPORTING_INFORMATION_CATEGORIES.EXCLUDED
                      )
                      .sort((a, b) => b.weight - a.weight)
                      .map(({ value }) => ({ value: value.mainInfo })),
                    3
                  )}
                  limits={take(
                    get(insurancePlan, "supportingInformation", [])
                      .filter(
                        ({ category }) =>
                          category === SUPPORTING_INFORMATION_CATEGORIES.LIMIT
                      )
                      .sort((a, b) => b.weight - a.weight)
                      .map(({ value }) => value.mainInfo),
                    3
                  )}
                  onSubscribe={() => handleSubscribe(insurancePlan?.id)}
                  onLearnMore={() =>
                    handleLearnMore({ cost, currency, insurancePlan })
                  }
                  disabled={
                    !project.locked ||
                    !get(project, "fields.insurancePlans", []).includes(
                      insurancePlan?.id
                    )
                  }
                />
              ))}
            </div>
          </Card>
          <Card type={"custom"} styleType={"transparent"} key="actions">
            <ActionStepper
              onPrevious={onBack}
              onNext={() => setIsModalOpen(true)}
              isNextDisabled={project.locked}
              isPrevDisabled={project.locked}
              nextLabel={COMPARISON_PRODUCTS.SEND_QUOTE}
            />
          </Card>
        </Col>
      </Row>

      <DetailsDrawer
        onClose={() => setIsDrawerOpen({})}
        open={!isEmpty(isDrawerOpen)}
        data={isDrawerOpen}
      />
      <PlansModal
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        onDownload={onDownload}
        onSendQuote={onSendQuote}
        fields={project.fields}
        data={data}
      />
    </>
  );
};

export default View;
