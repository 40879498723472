import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { get } from "lodash";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import { BENEFICIARY_TYPES, DATE_FORMAT, DEPARTMENTS, INSURANCE_PLAN_RISKS, SOCIAL_REGIMES } from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import { useLocation } from "react-router-dom";
import Button from "shared/components/Button";
import { RiAddFill } from "react-icons/ri";
import { MdDeleteOutline, MdOutlineAdd, MdOutlineContentCopy } from "react-icons/md";
import { COMMISSIONS } from "utils/api/graphql/queries/commissions";
import { COLORS } from "shared/style/colors";
import validation from "./validation";

const TerminalView = ({ onSubmit, data, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.PRICER);
  const { state } = useLocation();
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const [insurancePlans, setInsurancePlans] = useState([]);
  const [filteredInsurancePlans, setFilteredInsurancePlans] = useState([]);
  const [showCommission, setShowCommission] = useState(
    !get(data, "insurancePlan.internal")
  );

  useQuery(INSURANCE_PLANS, {
    onCompleted: ({ insurancePlans: { data } }) => {
      const plans = data.map(({ id, name, internal, risk }) => ({
        value: id,
        label: name,
        internal,
        risk,
      }));
      setInsurancePlans(plans);
      const filteredPlans = plans.filter(
        (plan) => plan.risk === state?.risk
      );
      setFilteredInsurancePlans(filteredPlans);
    },
  });



  return (
    <Formik
      initialValues={{
        name: "",
        insurancePlan: { id: "" },
        prices: {
          ranges: [{ min: "", max: "", cost: "" }],
        },
        dates: [],
      }}
      validationSchema={validation(t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }))}
      onSubmit={onSubmit}
    >
      {({ isValid, handleSubmit, setFieldValue, values,errors, ...formProps }) => (
        <>
          <Header
            title={CREATE.HEADER.TITLE}
            subtitle={CREATE.HEADER.ACTION}
            actions={[
              {
                text: CREATE.HEADER.DISCARD,
                onClick: cancel,
                type: "primary--outlined",
                icon: <BiChevronLeft size={20} />,
              },
              {
                text: CREATE.HEADER.SAVE,
                onClick: handleSubmit,
                type: "primary",
                icon: <BiCheck size={20} />,
              },
            ]}
          />
          <Card title={FORM.GENERAL_INFO}>

            <div className="grid--2">
              <Select
                options={filteredInsurancePlans}
                placeholder={FORM.INSURANCE_PLAN}
                label={FORM.INSURANCE_PLAN}
                value={get(values, "insurancePlan.id")}
                {...bindInputProps({
                  name: "insurancePlan.id",
                  values,
                  ...formProps,
                })}
                onChange={(id) => {
                  const selectedPlan = insurancePlans.find((plan) => plan.value === id);
                  const isInternal = get(selectedPlan, "internal");
                  setFieldValue("insurancePlan.id", id || "");
                  setShowCommission(!isInternal);
                }}
              />
              <Input
                label={FORM.NAME}
                placeholder={FORM.NAME}
                {...bindInputProps({
                  name: "name",
                  values,
                  ...formProps,
                })}
              />
              <Input
                type="date"
                variant="range"
                label={FORM.DATE}
                format={DATE_FORMAT}
                placeholders={[FORM.START_DATE, FORM.END_DATE]}
                {...bindInputProps({
                  name: "dates",
                  values,
                  ...formProps,
                })}
                onChange={(value) => setFieldValue("dates", value)}
              />
            </div>
          </Card>


          <div key="div-1" className="m-top-24" />,
          <Card key="card-price" type="simple" head title={FORM.PRICES}>
            <FieldArray
              name="prices.ranges"
              render={(arrayHelpers) => (
                <div>
                  {get(values, "prices.ranges", []).map((_, i) => (
                    <div key={`price-${i}`}>
                      <div className="d-flex justify--end m-bottom-8">
                        <Button
                          type="danger--outlined"
                          onClick={() => {
                            arrayHelpers.remove(i);
                          }}
                        >
                          <MdDeleteOutline size={24} />
                        </Button>
                        <Button
                          type="primary--outlined"
                          onClick={() => {
                            arrayHelpers.insert(
                              i + 1,
                              get(values, `prices.ranges.${i}`)
                            );
                          }}
                        >
                          <MdOutlineContentCopy
                            size={24}
                            color={COLORS.C_PRIMARY}
                          />
                        </Button>
                      </div>
                      <div className={"grid--3"}>
                        <Input
                          type="number"
                          label={FORM.MIN}
                          placeholder={FORM.MIN}
                          {...bindArrayInputProps({
                            parent: "prices.ranges",
                            index: i,
                            name: "min",
                            values,
                            ...formProps,
                          })}
                        />
                        <Input
                          type="number"
                          label={FORM.MAX}
                          placeholder={FORM.MAX}
                          {...bindArrayInputProps({
                            parent: "prices.ranges",
                            index: i,
                            name: "max",
                            values,
                            ...formProps,
                          })}
                        />
                        <Input
                          type="number"
                          label={FORM.COST}
                          placeholder={FORM.COST}
                          {...bindArrayInputProps({
                            parent: "prices.ranges",
                            index: i,
                            name: "cost",
                            values,
                            ...formProps,
                          })}
                        />
                      </div>
                      <div className="divider m-top-24 m-bottom-24" />
                    </div>
                  ))}
                  <Button
                    type="primary"
                    onClick={() => arrayHelpers.push({ min: "", max: "", cost: "" })}
                  >
                    <RiAddFill size={24} />
                    {FORM.ADD_NEW_ROW}
                  </Button>
                </div>
              )}
            />
          </Card>



        </>
      )}
    </Formik>
  );
};

export default TerminalView;
