import { TEMPLATE_TYPES } from "utils/constants";
import B2B_RADIANCE_COSA from "./CosaeTer";
import DEFAULT from "./Default";
import B2B_SMART_PHONE from "./SmartPhone";
import SOLYON from "./Solyon";

const Templates = {
  [TEMPLATE_TYPES.DEFAULT]: DEFAULT,
  [TEMPLATE_TYPES.B2B_RADIANCE_COSA]: B2B_RADIANCE_COSA,
  [TEMPLATE_TYPES.B2B_SMART_PHONE]: B2B_SMART_PHONE,
  [TEMPLATE_TYPES.B2B_SOLYON]: SOLYON,
};
export default Templates;
