import { isFunction } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";

const ActionStepper = ({
  onPrevious,
  onNext,
  prevLabel,
  nextLabel,
  isNextDisabled = false,
  isPrevDisabled = false,
  prevType = "gray--link",
  nextType = "primary",
  nextIcon = true,
}) => {
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const GENERAL = t("GENERAL", { returnObjects: true });
  return (
    <div className="action__stepper">
      {isFunction(onPrevious) && (
        <Button
          type={prevType}
          onClick={onPrevious}
          disabled={isPrevDisabled}
          className="action__stepper--previous"
        >
          <MdArrowBackIos />
          {prevLabel || GENERAL.PREV}
        </Button>
      )}

      {isFunction(onNext) && (
        <Button
          type={nextType}
          onClick={onNext}
          disabled={isNextDisabled}
          className="action__stepper--next"
        >
          {nextLabel || GENERAL.NEXT}
          {nextIcon && <MdArrowForwardIos />}
        </Button>
      )}
    </div>
  );
};

ActionStepper.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  prevLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  isNextDisabled: PropTypes.bool,
  nextIcon: PropTypes.bool,
};

ActionStepper.defaultProps = {
  prevLabel: "",
  nextLabel: "",
  isNextDisabled: false,
  prevType: "gray--link",
  nextType: "primary",
  nextIcon: true,
};

export default ActionStepper;
