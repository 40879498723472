import axios from "axios";
import { get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { BRANDS } from "utils/api/graphql/queries/brands";
import { DEVICES_API, SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.BRANDS.LIST.path);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);
  const getDevices = async (brand) => {
    const response = await axios.get(DEVICES_API);
    const deviceList = get(response, "data.data", []).filter(
      ({ device_list, brand_name }) => !isEmpty(device_list) && !!brand_name
    );
    setBrands(deviceList);
    const devices =
      get(
        deviceList?.find(({ brand_name }) => brand_name === brand),
        "device_list",
        []
      ).map(({ device_name, device_image }) => ({
        label: (
          <div className="d-flex ">
            <img
              width={16}
              height={16}
              src={device_image}
              alt={device_name}
              style={{ marginRight: 8 }}
            />
            {device_name}
          </div>
        ),
        value: device_name,
        image: device_image,
      })) || [];
    setDevices(devices);
    setLoading(false);
  };

  const [updateBrand] = useMutation(graphql.mutations.UPDATE_BRAND, {
    refetchQueries: [
      {
        query: BRANDS,
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.BRANDS.LIST.path);
    },
    onError: () => {},
  });
  const { data, loading: dataLoading } = useQuery(graphql.queries.BRAND, {
    variables: { where: { id } },
    onCompleted: ({ brand }) => getDevices(get(brand, "name")),
  });
  const onSubmit = ({ models, ...rest }) => {
    return updateBrand({
      variables: {
        where: {
          id,
        },
        data: {
          ...rest,
          models: models.map((model) => {
            return { id: model?.id, name: model?.value, image: model?.image };
          }),
        },
      },
    });
  };

  if (dataLoading) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      data={omitDeep(data.brand, "__typename")}
      cancel={cancel}
      brands={brands}
      setDevices={setDevices}
      devices={devices}
      loading={loading}
    />
  );
};

export default Detail;
