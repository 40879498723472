import { Col, Progress, Row, notification } from "antd";
import { get } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineDescription, MdOutlineEditNote } from "react-icons/md";
import { useParams } from "react-router-dom";
import Alert from "shared/components/Alert";
import Button from "shared/components/Button";
import Form from "shared/components/Form";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import format from "string-template";
import graphql from "utils/api/graphql";
import { GENERATE_SUBSCRIPTION_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { SIGN_DOCUMENT } from "utils/api/graphql/mutations/esign";
import {
  DATE_TIME_FORMAT,
  INITIAL_VALUES,
  PROJECT_TYPES,
  SALE_MODES,
  SUBSCRIPTION_STATUSES,
} from "utils/constants";
import { STEPPER_KEYS } from "../../../..";
import SubscriptionStatus from "../elements/statuses";

const Esignature = ({
  additionalInfoPercentage,
  proofPercentage,
  setActive,
  subscription,
}) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(
    SUBSCRIPTION_STATUSES.DRAFT
  );
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", {
    returnObjects: true,
  });
  const E_SIGNATURE = t("SUBSCRIPTION.DOCUMENT.E_SIGNATURE", {
    returnObjects: true,
  });
  useEffect(() => {
    setSubscriptionStatus(subscription.status);
  }, []);
  const { id } = useParams();
  const [generateSubscriptionDocuments, { loading: generateLoading }] =
    useMutation(GENERATE_SUBSCRIPTION_DOCUMENTS);
  const [signDocuments, { loading: signLoading }] = useMutation(SIGN_DOCUMENT, {
    refetchQueries: [
      {
        query: graphql.queries.SUBSCRIPTION,
        awaitRefetchQueries: true,
        variables: { where: { id } },
      },
      {
        query: graphql.queries.PROJECTS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
  });

  const signDocument = () => {
    if (!get(subscription, "locked"))
      generateSubscriptionDocuments({
        variables: {
          data: { id },
        },
        onCompleted: () => {
          signDocuments({
            variables: {
              data: {
                id,
              },
            },
            onCompleted: () => {
              setSubscriptionStatus(SUBSCRIPTION_STATUSES.PENDING);
              notification.open({
                message: SUBSCRIPTION.E_SIGNATURE_SENT,
                duration: 5,
                type: "success",
              });
            },
          });
        },
      });
    else
      signDocuments({
        variables: {
          data: {
            id,
          },
        },
        onCompleted: () => {
          setSubscriptionStatus(SUBSCRIPTION_STATUSES.PENDING);
          notification.open({
            message: SUBSCRIPTION.E_SIGNATURE_SENT,
            duration: 5,
            type: "success",
          });
        },
      });
  };

  const eSignDisabled =
    get(subscription, "project.contract.saleMode") === SALE_MODES.DISTANT &&
    get(subscription, "project.projectType") === PROJECT_TYPES.COLD_LEAD &&
    moment().diff(subscription.createdDate, "hours") <= 24;

  return (
    <div className="subscription-form--wrapper">
      <Form type="vertical" title={E_SIGNATURE.TITLE}>
        {additionalInfoPercentage < 100 || proofPercentage < 100 ? (
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <Alert showIcon type="error" message={E_SIGNATURE.INFO} />
            </Col>
            <Col lg={24} xs={24}>
              <div className="progress_wrapper">
                <div className="progress_wrapper--content">
                  <div className="progress_wrapper--content__title">
                    <MdOutlineDescription size={24} />
                    <span>{E_SIGNATURE.FURTHER_INFORMATION}</span>
                  </div>
                  <span className="progress_wrapper--content__percent">
                    {`${additionalInfoPercentage}% ${SUBSCRIPTION.DONE}`}
                  </span>
                </div>
                <div className="progress_wrapper--bar">
                  <Progress
                    percent={additionalInfoPercentage}
                    size={"small"}
                    showInfo={false}
                  />
                </div>
              </div>
            </Col>
            <Col lg={24} xs={24}>
              <div className="progress_wrapper">
                <div className="progress_wrapper--content">
                  <div className="progress_wrapper--content__title">
                    <MdOutlineDescription size={24} />
                    <span>{E_SIGNATURE.ATTACHMENT}</span>
                  </div>
                  <span className="progress_wrapper--content__percent">
                    {`${proofPercentage}% ${SUBSCRIPTION.DONE}`}
                  </span>
                </div>
                <div className="progress_wrapper--bar">
                  <Progress
                    percent={proofPercentage}
                    size={"small"}
                    showInfo={false}
                  />
                </div>
              </div>
            </Col>
            <Col lg={24} xs={24}>
              <Button
                type="primary"
                alignment="center"
                onClick={() =>
                  setActive(
                    additionalInfoPercentage < 100
                      ? STEPPER_KEYS.ADDITIONAL_INFORMATION
                      : STEPPER_KEYS.PROOF
                  )
                }
              >
                {SUBSCRIPTION.FINALIZE_SUBSCRIPTION}
                <MdOutlineEditNote size={16} />
              </Button>
            </Col>
          </Row>
        ) : (
          <Col lg={24} xs={24}>
            {eSignDisabled && (
              <Alert
                showIcon
                type="warning"
                message={format(E_SIGNATURE.WARNING, {
                  time: moment(subscription.createdDate)
                    .add(24, "hours")
                    .format(DATE_TIME_FORMAT),
                })}
              />
            )}
            <br />
            <SubscriptionStatus
              type={subscriptionStatus}
              subscription={subscription}
              translation={SUBSCRIPTION}
              signDocument={signDocument}
              disabled={eSignDisabled || generateLoading || signLoading}
            />
          </Col>
        )}
      </Form>
    </div>
  );
};

export default Esignature;
