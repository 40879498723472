import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.PROCESSES.LIST.path);
  const [updateProcess] = useMutation(graphql.mutations.UPDATE_PROCESS, {
    refetchQueries: [
      {
        query: graphql.queries.PROCESSES,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
      {
        query: graphql.queries.PROCESS,
        awaitRefetchQueries: true,
        variables: { where: { id } },
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.PROCESSES.LIST.path);
    },
    onError: () => {},
  });
  const { data, loading, error } = useQuery(graphql.queries.PROCESS, {
    variables: { where: { id } },
  });
  const onSubmit = ({
    name,
    type,
    theme,
    insurancePlans,
    processStatuses,
    skills,
  }) => {
    return updateProcess({
      variables: {
        where: {
          id,
        },
        data: {
          name,
          type,
          theme,
          processStatuses,
          skills,
          // insurancePlans: insurancePlans?.map((id) => ({ id })),
        },
      },
    });
  };

  if (loading || error) return "Loading";

  return (
    <View
      onSubmit={onSubmit}
      data={omitDeep(data.process, "__typename")}
      cancel={cancel}
    />
  );
};

export default Detail;
