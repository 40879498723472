import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";

const ProductCard = ({
  image,
  name,
  price,
  onModify,
  variant = "horizontal",
  disabled = false,
}) => {
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const PRODUCT_CARD = t("PRODUCT_CARD", { returnObjects: true });
  const cardClass = `product__card product__card--${variant}`;

  return (
    <div className={cardClass}>
      <img src={image} alt={name} className="product__card--image" />
      <div className="product__card--content">
        <h3 className="product__card--name">{name}</h3>
        <p className="product__card--price">
          <span className="label">{PRODUCT_CARD.PURCHASE_PRICE}</span>
          <span className="value">{price}</span>
        </p>
        <Button
          type="primary--underline"
          onClick={onModify}
          className="btn--modify"
          disabled={disabled}
        >
          {PRODUCT_CARD.EDIT}
        </Button>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onModify: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["horizontal", "vertical"]),
};

export default ProductCard;
