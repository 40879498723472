import PropTypes from "prop-types";

const OfferDetails = ({ title, items, icon, background }) => {
  return (
    <div
      className={`offer__items ${
        background ? `offer__items--${background}` : ""
      }`}
    >
      <h3 className="offer__items--title">{title}</h3>
      <ul className="offer__items--list">
        {items.map((item, index) => (
          <li key={index} className="offer__items--item">
            {icon && <span className="offer__items--icon">{icon}</span>}
            <div className="content">
              {item.subtitle && (
                <span
                  className="offer__items--subtitle"
                  dangerouslySetInnerHTML={{ __html: item?.subtitle }}
                />
              )}
              <span
                className="offer__items--value"
                dangerouslySetInnerHTML={{ __html: item?.value }}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

OfferDetails.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      subtitle: PropTypes.string,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  icon: PropTypes.node,
  background: PropTypes.string,
};

export default OfferDetails;
