import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MdCheck, MdClose, MdHandshake, MdInfoOutline } from "react-icons/md";
import Button from "shared/components/Button";
import OfferDetails from "shared/components/SmartphoneWidgets/OfferDetails";
import PricingItem from "shared/components/SmartphoneWidgets/PricingItem";
import { NAME_SPACES } from "shared/locales/constants";

const PricingCard = ({
  title,
  description,
  price,
  monthlyPrice,
  includedItems,
  excludedItems,
  onLearnMore,
  claimPerYear,
  annualLimit,
}) => {
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const PRICING_CARD = t("PRICING_CARD", { returnObjects: true });

  return (
    <div className="pricingVertical__card">
      <div className="pricingVertical__card--header">
        <PricingItem
          title={title}
          description={description}
          price={price}
          monthlyPrice={monthlyPrice}
          variant="compact"
          onLearnMore={onLearnMore}
        />
      </div>

      <div className="content">
        <div className="pricingVertical__card--summary">
          <div className="pricingVertical__card--summary-item">
            <MdInfoOutline size={20} />
            <span>
              <strong>{claimPerYear}</strong> {PRICING_CARD.CLAIM_PER_YEAR}
            </span>
          </div>
          <div className="pricingVertical__card--summary-item">
            <MdHandshake size={20} />
            <span>
              {PRICING_CARD.ANNUAL_LIMIT} <strong>{annualLimit}</strong>
            </span>
          </div>
        </div>

        <div className="pricingVertical__card--details">
          <div className="box">
            <OfferDetails
              title="Inclut dans cette offre :"
              icon={<MdCheck size={20} color="green" />}
              items={includedItems}
            />
          </div>
          <div className="box">
            <OfferDetails
              title="Exclu de cette offre :"
              icon={<MdClose size={20} color="red" />}
              items={excludedItems}
            />
          </div>
        </div>

        <div className="pricingVertical__card--action">
          <Button
            type="primary--underline"
            onClick={onLearnMore}
            className="btn--modify"
          >
            {PRICING_CARD.LEARN_MORE}
          </Button>
        </div>
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.string.isRequired,
  includedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  excludedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onLearnMore: PropTypes.func,
  claimPerYear: PropTypes.string,
  annualLimit: PropTypes.string,
};

export default PricingCard;
