import attachments from "./attachments";
import auth from "./auth";
import brands from "./brands";
import brokers from "./brokers";
import categoryWeight from "./category-weight";
import commissionStatements from "./commission-statements";
import commissions from "./commissions";
import contacts from "./contacts";
import contracts from "./contracts";
import coverageBenefits from "./coverage-benefits";
import coverageCategories from "./coverage-categories";
import coverages from "./coverages";
import dashboard from "./dashboard";
import distributions from "./distributions";
import flows from "./flows";
import generalCosts from "./general-cost";
import insurancePlanCoverages from "./insurance-plan-coverage";
import insurancePlans from "./insurance-plans";
import links from "./link";
import notes from "./notes";
import offers from "./offers";
import organizations from "./organizations";
import pricers from "./pricers";
import processStatuses from "./process-statuses";
import processes from "./processes";
import projects from "./projects";
import qualifications from "./qualifications";
import qualifiers from "./qualifiers";
import quotes from "./quotes";
import referentials from "./referentials";
import reminders from "./reminders";
import sources from "./sources";
import subscriptions from "./subscription";
import tracers from "./tracers";
import userActivity from "./user-activity";
import users from "./users";
import webhooks from "./webhooks";
import workflows from "./workflows";
import templates from "./templates";

const queries = {
  ...attachments,
  ...contacts,
  ...contracts,
  ...organizations,
  ...projects,
  ...auth,
  ...users,
  ...sources,
  ...tracers,
  ...flows,
  ...processes,
  ...insurancePlans,
  ...coverages,
  ...coverageBenefits,
  ...processStatuses,
  ...qualifiers,
  ...qualifications,
  ...webhooks,
  ...notes,
  ...reminders,
  ...quotes,
  ...generalCosts,
  ...subscriptions,
  ...commissions,
  ...brokers,
  ...workflows,
  ...pricers,
  ...commissionStatements,
  ...distributions,
  ...referentials,
  ...categoryWeight,
  ...coverageCategories,
  ...insurancePlanCoverages,
  ...offers,
  ...dashboard,
  ...links,
  ...userActivity,
  ...brands,
  ...templates,
};

export default queries;
