import cuid from "cuid";
import { get, isNumber, set } from "lodash";
import moment from "moment";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { CREATE_DISTRIBUTION } from "utils/api/graphql/mutations/distributions";
import {
  DELTA_END_TYPES,
  INITIAL_VALUES,
  INSURANCE_PLAN_PARAMS_RULES,
  PERIOD_UNITS,
  SCREENS,
} from "utils/constants";
import { removeEmptyValues } from "utils/helpers/array";
import View from "./View";

const DELTA_END_VALUES = {
  [DELTA_END_TYPES.END_OF_YEAR]: () => 0,
  [DELTA_END_TYPES.ADD_PERIOD]: (start, end, unit = PERIOD_UNITS.DAYS) => {
    if (!isNumber(start) || !isNumber(end)) return null;
    return moment()
      .add(start || 0, "days")
      .add(end, unit)
      .diff(moment().startOf("day"), "days");
  },
  DEFAULT: () => null,
};

const updateRulesWithDeltaValues = (rules) => {
  set(
    rules,
    INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
    calculateDeltaEndValue(
      rules,
      INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
      INSURANCE_PLAN_PARAMS_RULES.DELTA_END
    )
  );
  set(
    rules,
    INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
    calculateDeltaEndValue(
      rules,
      INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
      INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA
    )
  );
  return rules;
};

const calculateDeltaEndValue = (rules, startRuleKey, endRuleKey) => {
  const type = get(rules, `${endRuleKey}.type`) || "DEFAULT";
  return DELTA_END_VALUES[type](
    get(rules, startRuleKey),
    get(rules, `${endRuleKey}.value`, null),
    get(rules, `${endRuleKey}.unit`)
  );
};

const convertRulesToEntries = (rules) =>
  Object.entries(rules).map(([name, value]) => ({
    id: cuid(),
    name,
    value,
  }));

const CreateHealth = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const { user } = useContext(StoreContext);

  const [addDistribution] = useMutation(CREATE_DISTRIBUTION);
  const handleDistribution = (internal) => {
    const distribution = {
      [true]: () =>
        addDistribution({
          variables: {
            data: {
              id: cuid(),
              insurancePlan: { id },
              broker: { id: get(user, "organization.broker.id") },
            },
          },
        }),
      [false]: () => null,
    };
    return distribution[internal]();
  };
  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);
  const [addInsurancePlan] = useMutation(
    graphql.mutations.CREATE_INSURANCE_PLAN,
    {
      refetchQueries: [
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
      ],
      onCompleted: ({ addInsurancePlan }) => {
        handleDistribution(addInsurancePlan?.internal);
        navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);
      },
      onError: () => {},
    }
  );

  const onSubmit = (values) => {
    const {
      date,
      deltaEndType,
      deltaEndUnit,
      params,
      iconAttachment,
      icon,
      commissions,
      ...rest
    } = values;
    addInsurancePlan({
      variables: {
        data: {
          ...rest,
          id,
          identifier: cuid(),
          startDate: get(date, "0"),
          endDate: get(date, "1"),
          icon: iconAttachment,
          commissions: commissions.map((commission) => ({ id: commission })),
          ...(params?.length > 0 && {
            params: params.map(({ id, rules }) => ({
              id,
              rules: convertRulesToEntries(
                removeEmptyValues(updateRulesWithDeltaValues(rules))
              ),
            })),
          }),
        },
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default CreateHealth;
