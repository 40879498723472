import { Divider, Drawer } from "antd";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowRight, MdCheck, MdClose, MdInfoOutline } from "react-icons/md";
import { OfferDetails, PricingItem } from "shared/components/SmartphoneWidgets";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import {
  CURRENCY_SYMBOL,
  SUPPORTING_INFORMATION_CATEGORIES,
} from "utils/constants";
import { costToFrench } from "utils/helpers/values";

const Details = ({ onClose, open, data, ...rest }) => {
  const [drawerWidth, setDrawerWidth] = useState("520px");
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const PRICING_CARD = t("PRICING_CARD", { returnObjects: true });

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        setDrawerWidth("95%");
      } else if (windowWidth <= 1024) {
        setDrawerWidth("70%");
      } else {
        setDrawerWidth("520px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Drawer
      title={<></>}
      placement="right"
      className="contact__drawer"
      contentWrapperStyle={{
        width: drawerWidth,
      }}
      onClose={onClose}
      open={open}
      {...rest}
    >
      <PricingItem
        title={get(data, "insurancePlan.name")}
        description={get(data, "insurancePlan.description", "")}
        price={`${costToFrench(data?.cost, 0)} ${
          CURRENCY_SYMBOL[data?.currency] || data?.currency
        }`}
        monthlyPrice={`${costToFrench(data?.cost * 12, 0)} ${
          CURRENCY_SYMBOL[data?.currency] || data?.currency
        }`}
        variant="highlighted"
      />
      <Divider />

      <OfferDetails
        title={PRICING_CARD.DESCRIPTION}
        background="dark"
        icon={<MdArrowRight size={20} color={COLORS.C_GRAY} />}
        items={get(data, "insurancePlan.supportingInformation", [])
          .filter(
            ({ category }) =>
              category === SUPPORTING_INFORMATION_CATEGORIES.DESCRIPTION
          )
          .sort((a, b) => b.weight - a.weight)
          .map(({ value }) => ({
            value: get(value, "mainInfo", "-"),
          }))}
      />

      <Divider />

      <div className="infos--summary">
        {get(data, "insurancePlan.supportingInformation", [])
          .filter(
            ({ category }) =>
              category === SUPPORTING_INFORMATION_CATEGORIES.LIMIT
          )
          .sort((a, b) => b.weight - a.weight)
          .map(({ value }) => (
            <div className="infos--summary-item">
              <MdInfoOutline size={20} />
              <span>
                <strong
                  dangerouslySetInnerHTML={{
                    __html: get(value, "mainInfo", "-"),
                  }}
                />
              </span>
            </div>
          ))}
      </div>
      <Divider />

      <OfferDetails
        title={`${PRICING_CARD.INCLUDED} :`}
        icon={<MdCheck size={20} color={COLORS.C_GREEN} />}
        items={get(data, "insurancePlan.supportingInformation", [])
          .filter(
            ({ category }) =>
              category === SUPPORTING_INFORMATION_CATEGORIES.INCLUDED
          )
          .sort((a, b) => b.weight - a.weight)
          .map(({ value }) => ({ subtitle: get(value, "mainInfo", "-") }))}
      />
      <Divider />
      <OfferDetails
        title={`${PRICING_CARD.EXCLUDED} :`}
        icon={<MdClose size={20} color={COLORS.C_RED} />}
        items={get(data, "insurancePlan.supportingInformation", [])
          .filter(
            ({ category }) =>
              category === SUPPORTING_INFORMATION_CATEGORIES.EXCLUDED
          )
          .sort((a, b) => b.weight - a.weight)
          .map(({ value }) => ({
            subtitle: `${get(value, "mainInfo", "-")}`,
            value: value.extraDetail,
          }))}
      />

      <Divider />

      <OfferDetails
        title={`${PRICING_CARD.REIMBURSEMENT} :`}
        icon={<MdArrowRight fill size={20} color={COLORS.C_GRAY} />}
        items={get(data, "insurancePlan.supportingInformation", [])
          .filter(
            ({ category }) =>
              category === SUPPORTING_INFORMATION_CATEGORIES.REIMBURSEMENT
          )
          .sort((a, b) => b.weight - a.weight)
          .map(({ value }) => ({
            subtitle: `${get(value, "mainInfo", "-")}`,
            value: value.extraDetail,
          }))}
      />
    </Drawer>
  );
};
export default Details;
