import cuid from "cuid";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { TEMPLATES } from "utils/api/graphql/queries/templates";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Preview = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const { state } = useLocation(); 
  const dynamicTemplateId = state?.dynamicTemplateId;

  const { data, loading, error } = useQuery(graphql.queries.TEMPLATES, {
    variables: { where: { id: dynamicTemplateId } },
    skip: !dynamicTemplateId, 
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
 
  return <View data={data} />;;
};

export default Preview;
