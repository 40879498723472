import { Col, Row } from "antd";
import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { RiAddFill, RiDeleteBinFill } from "react-icons/ri";
import Button from "shared/components/Button";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Label from "shared/components/Label";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { SUPPORTING_INFORMATION_CATEGORIES } from "utils/constants";
import { bindArrayInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, data, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COVERAGE_BENEFIT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Formik
      initialValues={{
        id: data.id,
        supportingInformation: data.supportingInformation.map(
          ({ id, value, category }) => ({ id, value, category })
        ),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, setFieldValue, values, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "gray",
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
            },
          ]}
        />,
        <Row gutter={[16, 16]} key={"row"}>
          <Col span={24}>
            <Form title={FORM.GENERAL_INFO}>
              <Col lg={24}>
                <Label placeholder={FORM.ID} value={data.id} />
              </Col>
            </Form>
          </Col>
        </Row>,
        <Row gutter={[16, 16]} key={"supportingInformation"}>
          <Col span={24}>
            <Form title={FORM.SUPPORTING_INFORMATION.GENERAL_INFO}>
              <FieldArray
                name={"supportingInformation"}
                render={(arrayHelpers) => [
                  values.supportingInformation.map((_, i) => [
                    <Col lg={10} key={`type-${_.id}`}>
                      <Select
                        options={Object.entries(
                          SUPPORTING_INFORMATION_CATEGORIES
                        ).map(([key, value]) => ({
                          label: FORM.SUPPORTING_INFORMATION.CATEGORIES[key],
                          value,
                        }))}
                        placeholder={FORM.SUPPORTING_INFORMATION.CATEGORY}
                        label={FORM.SUPPORTING_INFORMATION.CATEGORY}
                        {...bindArrayInputProps({
                          parent: "supportingInformation",
                          index: i,
                          name: "category",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={10} key={`value-${_.id}`}>
                      <Input
                        placeholder={FORM.SUPPORTING_INFORMATION.VALUE}
                        label={FORM.SUPPORTING_INFORMATION.VALUE}
                        {...bindArrayInputProps({
                          parent: "supportingInformation",
                          index: i,
                          name: "value",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={1} key={`delete-${_.id}`}>
                      <div
                        className={"delete-icon"}
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      >
                        <RiDeleteBinFill
                          style={{ color: "#f00", fontSize: 22 }}
                        />
                      </div>
                    </Col>,
                  ]),
                  <Button
                    key="add-row"
                    onClick={() => {
                      arrayHelpers.push({ id: cuid() });
                    }}
                    bg={"#5462AA"}
                    icon={<RiAddFill style={{ color: "#fff" }} />}
                  />,
                ]}
              />
            </Form>
          </Col>
        </Row>,
      ]}
    </Formik>
  );
};

export default Detail;
