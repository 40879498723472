import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import ProductCard from "./Widgets/InsurancePlans/ProductCard";

const View = ({
  initialFields,
  onNext,
  take,
  onBack,
  onAdd,
  onRemove,
  selectedProducts,
  filter,
  onChangeCommission,
  onFilter,
  onLoadMore,
  loading,
  initialNeeds,
  actions,
  expired,
  products,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [needs, setNeeds] = useState(initialNeeds);
  const [fields, setFields] = useState(initialFields);

  const isSelected = (item) => selectedProducts.includes(get(item, "id"));
  return (
    <>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.CONTACT.TITLE}
        actions={[]}
      />
      <div className="template-product-list--container">
        <div className="grid--1">
          {Object.values(products)[0]?.map(({ main, insurancePlan }, index) => {
            const cost = { cost: 100 };
            if (main) return <div className="title">{insurancePlan.name}</div>;
            return (
              <ProductCard
                key={"product-" + index}
                image={get(insurancePlan, "icon")}
                name={get(insurancePlan, "name")}
                description={get(insurancePlan, "description")}
                costPerMonth={<>{`${get(cost, "cost")} `}</>}
                costAll={(+get(cost, "cost") * 12).toFixed(2)}
                state={isSelected(insurancePlan) ? "disabled" : "default"}
                onClick={() =>
                  isSelected(insurancePlan)
                    ? onRemove(insurancePlan)
                    : onAdd(insurancePlan)
                }
                translation={DETAIL.TEMPLATES.PRODUCTS.CARD}
                showCommission={false}
              />
            );
          })}
        </div>
        <div className="d-flex justify--between m-top-20">
          <Button onClick={onBack} type="secondary--link">
            <MdArrowBackIos size={16} />
            {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.PREVIOUS}
          </Button>

          <Button
            onClick={() =>
              onNext({
                payload: {
                  fields: {
                    ...fields,
                    selectedModules: selectedProducts,
                  },
                },
              })
            }
            type="primary"
          >
            {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.COMPARE}
            <MdArrowForwardIos size={16} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default View;
