import classNames from "classnames";
import Checkbox from "./Item";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";

const SelectPlan = ({ items = [], checked = [], onCheck }) => {
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const SELECT_PLAN = t("SELECT_PLAN", { returnObjects: true });

  return (
    <div className="select__plan">
      {items.map((item) => (
        <div
          key={item.id}
          className={classNames(`select__plan--item`, {
            "select__plan--selected": checked.includes(item.id),
          })}
          onClick={() => onCheck(item.id)}
        >
          <Checkbox isChecked={checked.includes(item.id)} />
          <div className="content">
            <h3 className="title">{item.label}</h3>
            <p className="description">{item.description}</p>
            <div className="price">
              <h4>{item.price}</h4>
              <span>{SELECT_PLAN.PER_MONTH} {item.monthlyPrice} {SELECT_PLAN.PER_YEAR}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectPlan;
