import * as yup from "yup";

const validation = (messages) => {
  const rangesSchema = yup.array().of(
    yup.object().shape({
      min: yup
        .number()
        .typeError(messages.REQUIRED)
        .required(messages.REQUIRED),
      max: yup
        .number()
        .nullable()
        .notRequired()
        .test(
          "is-greater-than-min",
          messages.INVALID_RANGE,
          function (value) {
            const { min } = this.parent;
            if (value !== null && value <= min) {
              return this.createError({
                path: this.path,
                message: messages.INVALID_RANGE,
              });
            }
            return true;
          }
        ),
      cost: yup
        .number()
        .typeError(messages.REQUIRED)
        .required(messages.REQUIRED),
    })
  );

  return yup.object().shape({
    name: yup
      .string()
      .typeError(messages.REQUIRED)
      .required(messages.REQUIRED),
    dates: yup
      .array()
      .typeError(messages.REQUIRED)
      .of(yup.date().typeError(messages.REQUIRED))
      .length(2, messages.REQUIRED)
      .required(messages.REQUIRED),
    insurancePlan: yup.object().shape({
      id: yup
        .string()
        .typeError(messages.REQUIRED)
        .required(messages.REQUIRED),
    }),
    prices: yup.object().shape({
      ranges: rangesSchema.required(messages.REQUIRED),
    }),
  });
};

export default validation;
