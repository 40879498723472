// hooks/useInsurancePlans.js
import { useMutation } from "@apollo/client";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { BUILD_GENERAL_COST } from "utils/api/graphql/mutations/costs";

const useInsurancePlans = ({ insurancePlan, getOffers, projectId }) => {
  const [selectedInsurancePlans, setSelectedInsurancePlans] = useState([]);
  const [buildCost] = useMutation(BUILD_GENERAL_COST);

  const fetchMainOffers = ({ offers }) => {
    const mainOffers = get(offers, "data", []);
    mainOffers.forEach(({ id }) => {
      fetchAdditionalOffers(id);
    });
  };

  const fetchAdditionalOffers = (offerId) => {
    getOffers({
      variables: {
        where: {
          id: offerId,
          offerInsurancePlans: { main: false },
        },
      },
      onCompleted: ({ offers }) => {
        const offerInsurancePlans = get(offers, "data", []).flatMap(
          ({ offerInsurancePlans }) =>
            omitDeep(offerInsurancePlans, "__typename")
        );

        offerInsurancePlans.forEach(({ insurancePlan }) => {
          buildCost({
            variables: {
              data: {
                project: { id: projectId },
                insurancePlan: { id: insurancePlan?.id },
              },
            },
            onCompleted: ({ buildGeneralCost }) => {
              setSelectedInsurancePlans((prev) => {
                if (prev.find(({ id }) => id === buildGeneralCost.id))
                  return prev;
                return [...prev, omitDeep(buildGeneralCost, "__typename")];
              });
            },
          });
        });
      },
    });
  };

  return { selectedInsurancePlans, fetchMainOffers, fetchAdditionalOffers };
};

export default useInsurancePlans;
