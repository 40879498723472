import cuid from "cuid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { TEMPLATES } from "utils/api/graphql/queries/templates";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.TEMPLATES.LIST.path);
  const [addTemplate] = useMutation(graphql.mutations.CREATE_TEMPLATE, {
    refetchQueries: [
      {
        query: TEMPLATES,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.TEMPLATES.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = (data) => {
    const id = cuid(); 
    return addTemplate({
      variables: {
        data: {
          ...data,
          id,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default Create;
