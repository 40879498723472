import Auth from "./Auth";
import NotFound from "./NotFound";
import Private from "./Private";
import Public from "./Public";
// import Templates from "./Templates";

const screens = ({ user, layouts }) => {
  return [
    ...Private({ user })
      .filter((x) => x)
      .map(
        ({ element, path, breadcrumb = [], applyLayout = true, ...rest }) => {
          const Layout = layouts[applyLayout].element;
          const header = layouts[applyLayout].header;
          const sider = layouts[applyLayout].sider;
          const drawers = layouts[applyLayout].drawers;
          const Widget = (
            <Layout
              header={header}
              sider={sider}
              drawers={drawers}
              breadcrumb={breadcrumb}
            >
              {element}
            </Layout>
          );
          return {
            element: Widget,
            path,
            breadcrumb,
            ...rest,
          };
        }
      ),
    ...Auth,
    ...Public,
    NotFound,
  ];
};

export default screens;
