import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import BankAccount from "./BankAccount";
import CommunityAttachment from "./CommunityAttachment";
import Id from "./Id";
import Kbis from "./Kbis";
import PensionFund from "./PensionFund";
import SocialSecurityCertificate from "./SocialSecurityCertificate";

const generateProofForm = ({
  isTNS,
  acceptTeletransmission,
  isRetired,
  isCommunityDocumentRequired,
}) => ({
  [SUBSCRIPTION_FORM_KEYS.PROOF.SOCIAL_SECURITY_CERTIFICATE]:
    acceptTeletransmission && SocialSecurityCertificate,
  [SUBSCRIPTION_FORM_KEYS.PROOF.ID]: Id,
  [SUBSCRIPTION_FORM_KEYS.PROOF.BANK_ACCOUNT]: BankAccount,
  [SUBSCRIPTION_FORM_KEYS.PROOF.KBIS]: isTNS && Kbis,
  [SUBSCRIPTION_FORM_KEYS.PROOF.PENSION_FUND]: isRetired && PensionFund,
  [SUBSCRIPTION_FORM_KEYS.PROOF.COMMUNITY_ATTACHMENT]:
    isCommunityDocumentRequired && CommunityAttachment,
});

export default generateProofForm;
