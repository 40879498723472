import * as yup from "yup";

const validation = (messages) => {
  return yup.object().shape({
    name: yup.string().typeError(messages.REQUIRED).required(messages.REQUIRED),
    dates: yup
      .array()
      .typeError(messages.REQUIRED)
      .of(yup.date().typeError(messages.REQUIRED))
      .length(2, messages.REQUIRED)
      .required(messages.REQUIRED),
    insurancePlan: yup.object().shape({
      id: yup.string().typeError(messages.REQUIRED).required(messages.REQUIRED),
    }),
    prices: yup.array().of(
      yup.object({
        departments: yup
          .object()
          .shape({
            values: yup
              .array()
              .of(yup.string().required(messages.REQUIRED))
              .min(1, messages.REQUIRED),
            excluded: yup.boolean().nullable(),
          })
          .required(messages.REQUIRED),
        socialRegime: yup
          .array()
          .typeError(messages.REQUIRED)
          .of(
            yup
              .string()
              .typeError(messages.REQUIRED)
              .required(messages.REQUIRED)
          ),
        beneficiaryType: yup
          .array()
          .typeError(messages.REQUIRED)
          .of(
            yup
              .string()
              .typeError(messages.REQUIRED)
              .required(messages.REQUIRED)
          ),
        // commission: yup
        //   .string()
        //   .typeError(messages.REQUIRED)
        //   .required(messages.REQUIRED),
        costs: yup
          .string()
          .test("is-json", messages.INVALID_JSON, function (value) {
            try {
              JSON.parse(value);
              return true;
            } catch (error) {
              return false;
            }
          }),
      })
    ),
  });
};

export default validation;
