import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import Layout from "./Layout";
import toolbox from "./Layout/toolbox";
import Card from "shared/components/Card";
import ActionStepper from "shared/components/ActionStepper";

const View = ({ data }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.TEMPLATE);
  const template = data?.templates?.data[0];
  return (
    <Layout>
      {template && (
        <>
          <Card type="custom" size="small" head title={template.name}>
            <div className="form-group d-flex flex--column gap-16 w-100">
              {template.form.map((field, index) => {
                const {
                  type,
                  label,
                  name,
                  entity,
                  accessor,
                  required,
                  defaultValue,
                  options,
                  values,
                  direction,
                  ...rest
                } = field;

                const fieldRenderer =
                  toolbox.input?.[type] ||
                  toolbox.select?.[type] ||
                  toolbox.radio?.[type] ||
                  toolbox.color?.[type.replace("color-", "")] ||
                  toolbox.date?.[type.replace("date-", "")] ||
                  toolbox.fallback;

                // if (!fieldRenderer) {
                //   console.error(`Error: Field type "${type}" not supported.`);
                //   return (
                //     <div key={index} className="item w-100">
                //       <p style={{ color: "red" }}>{`Error: Field type "${type}" not supported.`}</p>
                //     </div>
                //   );
                // }

                const { displayer } = fieldRenderer({
                  name,
                  label,
                  required,
                  defaultValue,
                  entity,
                  accessor,
                  options,
                  values,
                  direction,
                  ...rest,
                  mode: "bindInputProps",
                  formProps: {
                    values: {},
                    handleChange: () => { },
                    setFieldTouched: () => { },
                  },
                });
                return (
                  <div className="item w-100" key={index}>
                    {displayer}
                  </div>
                );
              })}
            </div>
          </Card>
          <ActionStepper
            onPrevious={() => { }}
            onNext={() => { }}
          />
        </>
      )}
    </Layout>
  );
};

export default View;
