import classNames from "classnames";
import Cost from "shared/components/Cost";
import Input from "shared/components/Input";
import { CURRENCY_SYMBOL } from "utils/constants";

const insurancePlan = ({
  logo,
  name,
  description,
  cost,
  currency = "DEFAULT",
  translation,
  checked,
  onCheck,
  justification,
  onJustify,
  checkDisabled,
  disabled,
}) => {
  return (
    <div className="insurance-card-container">
      <div
        className={classNames("insurance-card ", {
          "insurance-card--disabled": disabled,
        })}
      >
        <div className="insurance-card__checkbox">
          <Input
            type="checkbox"
            onChange={(e) => onCheck(e.target.checked)}
            checked={checked}
            disabled={(!checked && checkDisabled) || disabled}
          />
        </div>
        <div className="insurance-card__image">
          <img src={logo} alt="logo" />
        </div>
        <div className="insurance-card__item">
          <div className="insurance-card__item--name">{name}</div>
          <span className="insurance-card__item--description">
            {description}
          </span>
        </div>
        <div className="insurance-card__item">
          <div className="insurance-card__item">
            <div>
              <Cost
                cost={cost || "-"}
                currency={currency}
                period={`/ ${translation.PER_MONTH}`}
              />
            </div>
            <span className="insurance-card__item--total">
              {`${(+cost * 12).toFixed(0)} ${CURRENCY_SYMBOL[currency]} / ${
                translation.PER_YEAR
              } `}
              <span>{translation.ALL_COST}</span>
            </span>
          </div>
        </div>
        {/* <div className="insurance-card__item">
        <Input
          type="textarea"
          placeholder={translation.ARGUMENT}
          rows={2}
          value={justification}
          onChange={({ target: { value } }) => onJustify(value)}
          disabled={!checked}
        />
      </div> */}
      </div>
    </div>
  );
};

export default insurancePlan;
