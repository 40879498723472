import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { INSURANCE_PLAN_RISKS } from "utils/constants";
import CreateHealth from "./Health";
import CreateTerminal from "./Terminal";

const VIEW_BY_RISK = {
  [INSURANCE_PLAN_RISKS.HEALTH]: CreateHealth,
  [INSURANCE_PLAN_RISKS.TERMINAL]: CreateTerminal,
};

const Edit = (props) => {
  const { state } = useLocation();
  const View = useMemo(
    () => VIEW_BY_RISK[state?.risk || INSURANCE_PLAN_RISKS.HEALTH],
    [state?.risk]
  );

  return <View {...props} />;
};

export default Edit;
