import { useLazyQuery } from "@apollo/client";
import { find, get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import React, { useState } from "react";
import { useMutation, useQuery } from "shared/hooks/useApi";
import useSubscription from "shared/services/subscriptions";
import { names } from "shared/services/subscriptions/constants";
import graphql from "utils/api/graphql";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import { CREATE_QUALIFICATION } from "utils/api/graphql/mutations/qualifications";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import View from "./View";

const ContactCard = ({
  open,
  project,
  setOpen,
  contactId,
  tracer,
  tracers,
}) => {
  const [projects, setProjects] = useState({ count: 0, data: [] });
  const [loading, setLoading] = useState(true);
  const [isAssigning, setIsAssigning] = useState({});
  const [addQualification] = useMutation(CREATE_QUALIFICATION, {
    refetchQueries: [
      {
        query: graphql.queries.QUALIFIERS,
        awaitRefetchQueries: true,
        variables: {
          withDeleted: true,
          where: {
            qualifications: {
              contact: { id: contactId },
            },
          },
        },
      },
    ],
  });

  const [getContactProjects] = useLazyQuery(graphql.queries.PROJECTS, {
    fetchPolicy: "no-cache",
  });
  const onClose = () => {
    setOpen(false);
  };
  const { data: qualifiers } = useQuery(graphql.queries.QUALIFIERS);
  const { data: currentQualifier } = useQuery(graphql.queries.QUALIFIER, {
    fetchPolicy: "no-cache",
    variables: {
      withDeleted: true,
      where: {
        AND: [
          {
            qualifications: {
              last: true,
            },
          },
          {
            qualifications: {
              contact: { id: contactId },
            },
          },
        ],
      },
    },
  });
  const { data } = useQuery(graphql.queries.CONTACT, {
    variables: { where: { id: contactId } },
    onCompleted: ({ contact }) => {
      if (!contact || !contact.telecoms) {
        setProjects({ count: 0, data: [] });
        setLoading(false);
        return;
      }

      const email = get(
        find(contact.telecoms, { system: CONTACT_POINT_SYSTEM.EMAIL }),
        "value"
      );

      if (isEmpty(email)) {
        setProjects({ count: 0, data: [] });
        setLoading(false);
        return;
      }

      getContactProjects({
        variables: {
          where: {
            contact: { telecoms: { value: email } },
          },
        },
        onCompleted: ({ projects }) => {
          setLoading(false);
          setProjects(omitDeep(projects, "__typename"));
        },
      });
    },
  });
  useSubscription({ query: names.QUALIFIERS, variables: {} });

  const [updateProject] = useMutation(UPDATE_PROJECT, {});
  const [active, setActive] = useState("INFORMATION");
  const onAssign = ({ projectId, authorId }) => {
    setIsAssigning({ [projectId]: true });
    updateProject({
      variables: {
        where: { id: projectId },
        data: { author: { id: authorId } },
      },
      onCompleted: () => setTimeout(() => setIsAssigning({}), 1000),
    });
  };

  return (
    <View
      key={contactId}
      drawer={open}
      loading={loading}
      onClose={onClose}
      project={project}
      active={active}
      setActive={setActive}
      contact={omitDeep(get(data, "contact"), "__typename")}
      projects={projects}
      onAssign={onAssign}
      isAssigning={isAssigning}
      tracer={tracer}
      tracers={tracers}
      qualifiers={get(qualifiers, "qualifiers.data", [])}
      addQualification={addQualification}
      currentQualifier={currentQualifier}
    />
  );
};

export default React.memo(ContactCard);
